import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import ReactTooltip from "react-tooltip";

import { selectUser, selectToken, selectPartner } from "../actions/root";
import { groupConstrainter, roleConstrainter } from "../utils/clientConstraint";
import sysconfig from "../config";

function ProductSelection(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.activeUser);
  const token = useSelector((state) => state.activeToken);
  const [product, setProduct] = useState(user.general.product);
  const [fund, setFund] = useState(user.general.fund);
  const [leader, setLeader] = useState("");
  const [selfManaged, setSelfManaged] = useState(user.general.selfManaged);

  useEffect(() => {
    getLeader();
  }, []);

  const postChanges = (id, value) => {
    let data = { email: user.email };

    if (id === "selfManaged") {
      // ** DESCOMENTAR OU APAGAR:
      //data["selfManaged"] = value === "on";
    } else if (id === "product") data["product"] = value;
    else if (id === "funds") data["fund"] = value;

    axios({
      method: "patch",
      baseURL: sysconfig.EXECUTER_URI,
      url: "/user/general",
      headers: { "x-auth": token },
      data,
    })
      .then(function (response) {
        dispatch(selectUser(response.data));
      })
      .catch(function (error) {
        alert("Error: " + error.response.data.message);
      });
  };

  const handleProductChange = (changeEvent) => {
    postChanges(changeEvent.target.id, changeEvent.target.value);
  };

  const getLeader = () => {
    axios({
      method: "get",
      baseURL: sysconfig.EXECUTER_URI,
      url: "/user/getLeader?email=" + props.email,
      headers: { "x-auth": token },
    })
      .then(function (response) {
        if (response.status === 200) {
          setLeader(response.data);
        }
      })
      .catch(function (error) {
        console.log("Err getLeader", error);
      });
  };

  const prodSelection = () => {
    if (!leader) return <span />;
    //let leader = props.leader;
    let managed = <span />;
    let basic = <span />;
    if (leader && leader.general && leader.general.managed) {
      managed = (
        <option
          id="managed"
          value="MANAGED"
          className="custom-control-input"
          selected={user.general.product === "MANAGED"}
        >
          {leader.general.managed.label}
        </option>
      );
    }
    // if (leader && leader.general && leader.general.basic) {
    //   basic = (
    //     <option
    //       id="basic"
    //       value="BASIC"
    //       className="custom-control-input"
    //       selected={user.general.product === "BASIC"}
    //     >
    //       {leader.general.basic.label}
    //     </option>
    //   );
    // }
    // let none = (
    //   <option
    //     id="none"
    //     value="NONE"
    //     className="custom-control-input"
    //     selected={user.general.product === "NONE"}
    //   >
    //     None
    //   </option>
    // );
    let choices = <div />;
    // (
    //   <div className="col-md-4">
    //     <select
    //       className="custom-select custom-select-sm mt-1"
    //       id="product"
    //       onChange={handleProductChange}
    //       data-tip="Managed: Follow Managed strategies"
    //     >
    //       {managed}
    //     </select>
    //     <ReactTooltip place="top" type="dark" multiline="true" effect="float" />
    //   </div>
    // );
    // antes acima tinha {basic} {none}
    let selfManaged = <div />;
    // ** DESCOMENTAR?
    // if (user.general.product === "MANAGED" && !groupConstrainter(user)) {
    //   selfManaged = (
    //     <div className="col-md-4">
    //       <div className="custom-control custom-switch">
    //         <input
    //           type="checkbox"
    //           className="custom-control-input"
    //           id="selfManaged"
    //           checked={user.general.selfManaged}
    //           onChange={handleProductChange}
    //         />
    //         <label
    //           className="custom-control-label"
    //           for="selfManaged"
    //           data-tip="ON: Use my general configs for signals
    //       <br/>OFF: Use signal configs defined by the Leader"
    //         >
    //           Self managed
    //         </label>
    //       </div>
    //     </div>
    //   );
    // }
    let funds = getFunds();
    let selectedFund = fundSelection(funds);
    let result = (
      <div className="form-group">
        <div className="row">
          {choices}
          {selfManaged}
          {selectedFund}
        </div>
      </div>
    );

    return result;
  };

  const fundSelection = (funds) => {
    if (funds.length <= 1) return <div />;
    let listFunds = funds.map((elem) => {
      return (
        <option
          id={elem.name}
          value={elem.name}
          className="custom-control-input"
          selected={user.general.fund === elem.name}
        >
          {elem.name}
        </option>
      );
    });
    let help = <div />;
    // if (groupConstrainter(user)) {
    //   help = (
    //     <div className="col-md-2">
    //       <span className="mr-3">
    //         <a
    //           target="_blank"
    //           className="text-white"
    //           href="https://www.criptomaniacos.io/servico/6/robo-para-automatizar-sinais"
    //         >
    //           <img
    //             id="btntk"
    //             src="/images/book.png"
    //             height="25"
    //             width="25"
    //             alt="Help"
    //             data-tip="Learn more"
    //           />
    //         </a>
    //       </span>
    //     </div>
    //   );
    // }
    let divOuter = "col-md-8";
    let divInner = "col-md-10";
    if (user.general.product === "MANAGED" && !groupConstrainter(user)) {
      divOuter = "col-md-4";
      divInner = "col-md-12";
    }
    let choices = (
      <div className={divOuter}>
        <div className="row">
          <div className={divInner}>
            <select
              className="custom-select custom-select-sm mt-1"
              id="funds"
              onChange={handleProductChange}
            >
              {listFunds}
            </select>
          </div>
          {help}
        </div>
      </div>
    );
    return choices;
  };

  const getFunds = () => {
    if (!leader) return [];

    if (user.general.product === "BASIC") return leader.general.basic.funds;
    else if (user.general.product === "MANAGED")
      return leader.general.managed.funds;
    else return [];
  };

  if (roleConstrainter(user, "trader")) return <span />;
  let prodSelected = prodSelection();
  return <div>{prodSelected}</div>;
}

export default ProductSelection;
