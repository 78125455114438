import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import validator from "validator";

import SelectKeys from "../../components/selectKeys";
import sysconfig from "../../config";

function BotStrategyCliList(props) {
  const [strategies, setStrategies] = useState([]);
  const [exchange, setExchange] = useState("");
  const [subaccount, setSubAccount] = useState("");
  const [message, setMessage] = useState("");
  const [volume, setVolume] = useState();
  const [defineVolume, setDefineVolume] = useState(false);
  const [index, setIndex] = useState(-1);
  const user = useSelector(state => state.activeUser);
  const token = useSelector(state => state.activeToken);

  //equivalent to didMount
  useEffect(
    () => {
      getStrategies();
    },
    []
    //strategies
  );

  function pad(num, size = 2) {
    var s = "00000" + num;
    return s.substr(s.length - size);
  }

  const getStrategies = () => {
    axios({
      method: "get",
      baseURL: sysconfig.EXECUTER_URI,
      url: "/strategy/list",
      params: { email: user.email }
    })
      .then(function(response) {
        if (response.data) {
          setStrategies(response.data);
        }
      })
      .catch(function(error) {
        console.log("Err getStrategies:", error);
      });
  };

  const handlePlayClick = event => {
    let ind = Number(event.target.id);
    let strategy = strategies[ind];
    if (!volume || !validator.isNumeric(volume.toString())) return alert("Invalid volume %");
    if (!subaccount) return alert("Select a subaccount key");

    let data = {
      email: user.email,
      symbol: strategy.symbol,
      provider: strategy.username,
      strategy: strategy.name,
      partner: "aperium",
      volume,
      exchange,
      subaccount
    };
    axios({
      method: "post",
      baseURL: sysconfig.EXECUTER_URI,
      url: "/userstrategy/select",
      headers: { "x-auth": token },
      data
    })
      .then(function(response) {
        if (response.data) {
          setMessage(response.data);
        }
      })
      .catch(function(error) {
        if (error.response) setMessage(error.response.data);
        else setMessage(error.message);
      });
  };

  const onInputChange = event => {
    setVolume(event.target.value);
  };

  const getData = val => {
    //receive data from child component
    setExchange(val.exchange);
    setSubAccount(val.subaccount);
  };

  const handleSelectClick = event => {
    setMessage("");
    let ind = Number(event.target.id.slice(0, 2));
    if (index === ind) {
      setDefineVolume(false);
      setIndex(-1);
    } else if (ind >= 0) {
      setDefineVolume(true);
      setIndex(ind);
    }
  };

  const selectionComponents = (ind, symbol) => {
    return (
      <div className="row border">
        <div className="col-md-6">
          <SelectKeys user={user} symbol={symbol} sendData={getData} />
        </div>
        <div className="col-md-3">
          <input
            className="form-control form-control-sm"
            key={ind}
            id="volume"
            placeholder="% Entry"
            onChange={onInputChange}
          />
        </div>
        <div className="col-md-3">
          <button
            type="button"
            id={ind}
            className="btn btn-outline-secondary btn-sm"
            onClick={handlePlayClick}
          >
            Play
          </button>
        </div>
      </div>
    );
  };

  const list = () => {
    let listItems = [];
    let i = 0;
    for (let strategy of strategies) {
      let cpPhoto = <div />;
      let cpSelection = <div />;
      if (defineVolume && i === index) {
        cpSelection = selectionComponents(i, strategy.symbol);
        cpPhoto = (
          <div className="row">
            <div className="col-md-12">
              <img id={`img${i}`} src={`/images/strategies/${strategy.photo1}`} />
              <p />
            </div>
          </div>
        );
      }
      listItems.push(
        <div key={i} style={{ fontSize: "small" }}>
          <div className="row border">
            <div className="col-md-1">{strategy.username}</div>
            <div className="col-md-2">{`${strategy.symbol}: ${strategy.name}`}</div>
            <div className="col-md-4">{strategy.description}</div>
            <div className="col-md-1">
              <h6
                className="btn-link cursor-pointer"
                key={i}
                id={`${pad(i)}strategy`}
                value={strategy._id}
                onClick={handleSelectClick}
              >
                >
              </h6>
            </div>
            <div className="col-md-4">{cpSelection}</div>
          </div>
          {cpPhoto}
        </div>
      );
      i++;
    }
    return listItems;
  };

  const title = () => {
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <h5> Strategies Menu </h5>
          </div>
        </div>
        <div className="row" style={{ fontSize: "small" }}>
          <div className="col-md-1">Provider</div>
          <div className="col-md-2">Symbol / Name</div>
          <div className="col-md-4">Description</div>
          <div className="col-md-5" />
        </div>
      </div>
    );
  };

  return (
    <div>
      {title()} {list()} <label className="text-primary"> {message} </label>
    </div>
  );
}

export default BotStrategyCliList;
