import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
//import ReactJson from "react-json-view";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import sysconfig from "../config";
import UserSignals from "../containers/usersignals";

function UserData(props) {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [msg, setMsg] = useState("");
  const [email, setEmail] = useState("");
  const [userdata, setUserData] = useState("");
  const [logdata, setLogData] = useState("");
  const [logType, setLogType] = useState("Play");
  const user = useSelector(state => state.activeUser);
  const token = useSelector(state => state.activeToken);

  //equivalent to didMount
  useEffect(() => {
    //sigList();
  }, []);

  const getUser = () => {
    setMsg("");
    setUserData("");
    axios({
      method: "post",
      baseURL: sysconfig.EXECUTER_URI,
      url: "/user/getFollower",
      data: {
        username: user.username,
        email,
        startDate,
        endDate
      },
      headers: { "x-auth": token }
    })
      .then(function(response) {
        if (response.data) {
          setUserData(response.data);
        }
      })
      .catch(function(error) {
        setMsg(error.response.data.message);
        console.log("Err getUser:", error);
      });
  };

  const getLog = () => {
    setMsg("");
    setLogData("");
    axios({
      method: "post",
      baseURL: sysconfig.EXECUTER_URI,
      url: "/log/getUserLog",
      data: {
        username: user.username,
        email,
        logType: logType
      },
      headers: { "x-auth": token }
    })
      .then(function(response) {
        if (response.data) {
          setLogData(response.data);
        }
      })
      .catch(function(error) {
        setMsg(error.response.data.message);
        console.log("Err getLog:", error);
      });
  };

  const showFilters = () => {
    let listLogTypes = ["accInfoErr", "Play", "updateGeneral"].map(elem => {
      return (
        <option id={elem} value={elem} className="custom-control-input">
          {elem}
        </option>
      );
    });
    let details = (
      <div>
        <div className="row">
          <div className="col-md-12">
            <label>
              <b>User Data</b>
            </label>
          </div>
        </div>

        <div className="row">
          <div className="col-md-3">
            <input
              className="form-control"
              id="email"
              placeholder="email"
              value={email}
              onChange={onInputChange}
            />
            <br />
            <button
              type="button"
              className="btn btn-primary btn-sm cursor-pointer"
              id="generate"
              onClick={event => getUser(event)}
            >
              Load User
            </button>

            <p className="text-info"> {msg}</p>
          </div>
          <div className="col-md-3">
            <select
              className="custom-select custom-select-sm mt-1"
              id="funds"
              onChange={handleLogChange}
            >
              {listLogTypes}
            </select>
            <br />
            <br />
            <button
              type="button"
              className="btn btn-primary btn-sm cursor-pointer"
              id="generate"
              onClick={event => getLog(event)}
            >
              Logs
            </button>
          </div>
        </div>
      </div>
    );

    return details;
  };

  const handleLogChange = changeEvent => {
    setLogType(changeEvent.target.value);
  };

  const handleStartDateChange = changeEvent => {
    //date.toLocaleDateString("en-US")
    setStartDate(changeEvent);
  };

  const onInputChange = event => {
    setEmail(event.target.value);
  };

  const handleEndDateChange = changeEvent => {
    //date.toLocaleDateString("en-US")
    setEndDate(changeEvent);
  };

  let _user = <div/>
  // userdata ? (
  //   <div>
  //     User Detail
  //     <ReactJson src={userdata} name="User" displayDataTypes={false} collapsed={true} />
  //   </div>
  // ) : (
  //   <div />
  // );
  let _logs = <div/>
  // logdata ? (
  //   <div>
  //     <ReactJson src={logdata} name="Logs" displayDataTypes={false} collapsed={true} />
  //   </div>
  // ) : (
  //   <div />
  // );
  let _showFilters = showFilters();
  return (
    <div>
      {_showFilters} {_user} {_logs}
      <br />
      <br />
      <div className="col-md-6">
        <UserSignals email={email} />
      </div>
    </div>
  );
}

export default UserData;
