import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import _ from "lodash";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  VictoryChart,
  VictoryLine,
  VictoryTooltip,
  VictoryVoronoiContainer,
  VictoryTheme,
} from "victory";

//import SignalGroupExcel from "./sigGroupExcel";
import sysconfig from "../config";

function PerformDetail(props) {
  //const [excelRows, setExcelRows] = useState([]);
  const [chartGrossData, setChartGrossData] = useState([]);
  let iniDate = new Date();
  iniDate.setDate(iniDate.getDate() - 90);
  const [startDate, setStartDate] = useState(iniDate);
  const [endDate, setEndDate] = useState(new Date());
  const [partner, setPartner] = useState("TradersPool");
  const [signals, setSignals] = useState([]);
  const [dateType, setDateType] = useState("close");

  //equivalent to didMount
  useEffect(() => {
    sigList();
  }, []);

  const handleStartDateChange = (changeEvent) => {
    //date.toLocaleDateString("en-US")
    setStartDate(changeEvent);
  };

  const handleEndDateChange = (changeEvent) => {
    //date.toLocaleDateString("en-US")
    setEndDate(changeEvent);
  };

  const sigList = () => {
    axios({
      method: "post",
      baseURL: sysconfig.EXECUTER_URI,
      url: "/signal/listnoauth",
      data: {
        partner,
        product: "MANAGED",
        status: "CLOSED",
        orderSource: "signal",
        startDate: startDate,
        endDate: endDate,
        dateType,
      },
    })
      .then(function (response) {
        if (response.data) {
          setSignals(response.data);
          startChart(response.data);
        }
      })
      .catch(function (error) {
        console.log("Err sigList:", error);
      });
  };

  const filters = () => {
    return (
      <div className="row" style={{ fontSize: "small" }}>
        <div className="col-md-1" />
        <div className="col-md-3">
          <DatePicker
            id="startDate"
            selected={startDate}
            value={startDate}
            onChange={handleStartDateChange}
          />
        </div>
        <div className="col-md-3">
          <DatePicker
            id="endDate"
            selected={endDate}
            value={endDate}
            onChange={handleEndDateChange}
          />
        </div>
        <div className="col-md-1">
          <button
            type="button"
            className="btn btn-outline-primary btn-sm cursor-pointer"
            id="chart"
            onClick={btnChartClick}
          >
            Chart
          </button>
        </div>
      </div>
    );
  };

  const startChart = (data) => {
    //if (signals && signals.length > 0) data = signals;
    let grossData = [];
    //let resultExcel = [];
    for (let signal of data) {
      grossData.push(prepareChart(signal));
      //resultExcel.push(excelRow(signal));
    }
    setChartGrossData(grossData);
    lineChart();
  };

  const btnChartClick = () => {
    sigList();
  };

  const lineChart = () => {
    if (chartGrossData.length > 0) var grossData = chartGrossData;
    else return <div />;
    let toChart = [];
    let initialAmt = 1000;
    let currAmt = initialAmt;
    let ind = 0;
    for (let signal of grossData) {
      let amountOper = (currAmt * signal.entryVolume) / 100;
      let volStop = 100;
      if (signal.reach1) {
        currAmt =
          currAmt +
          amountOper *
            (Number(signal.t1Volume) / 100) *
            (Number(signal.reach1) / 100);
        volStop -= Number(signal.t1Volume);
        // console.log(
        //   `** T1 ${signal.entry} / ${amountOper} / ${signal.t1Volume} / ${signal.reach1}`,
        // );
        // console.log(` ${currAmt} / ${volStop} `);
      }
      if (signal.reach2) {
        currAmt =
          currAmt +
          amountOper *
            (Number(signal.t2Volume) / 100) *
            (Number(signal.reach2) / 100);
        volStop -= Number(signal.t2Volume);
      }
      if (signal.reach3) {
        currAmt =
          currAmt +
          amountOper *
            (Number(signal.t3Volume) / 100) *
            (Number(signal.reach3) / 100);
        volStop -= Number(signal.t3Volume);
      }
      if (signal.reachSL) {
        //if (Number(signal.stop) < Number(signal.entry))
        currAmt =
          currAmt +
          amountOper * (volStop / 100) * (Number(signal.reachSL) / 100);
        // console.log(
        //   `** SL ${signal.entry} / ${amountOper} / ${signal.reachSL}`,
        // );
        // console.log(` ${currAmt} / ${volStop} `);
      }
      toChart.push({
        x: ind.toFixed(0),
        y: currAmt,
        label: signal.symbol + " " + signal.closeDay + " " + signal.closeHour,
      });
      ind++;
    }
    return (
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-8">
          <VictoryChart
            width={700}
            height={480}
            theme={VictoryTheme.material}
            containerComponent={<VictoryVoronoiContainer responsive={false} />}
          >
            <VictoryLine
              labelComponent={<VictoryTooltip />}
              data={toChart}
              style={{
                data: { stroke: "#c43a31" },
                parent: { border: "1px solid #ccc" },
              }}
            />
          </VictoryChart>
        </div>
      </div>
    );
  };

  const calculate = (entry, target) => {
    if (Number(target) === 0) return 0;
    return _.round((100 * (Number(target) - Number(entry))) / Number(entry), 2);
  };

  const prepareChart = (signal) => {
    let tgt1Percent = calculate(
      signal.stopStart.entryEnd,
      signal.stopStart.takeProfit[0].target,
    );
    let tgt2Percent = calculate(
      signal.stopStart.entryEnd,
      signal.stopStart.takeProfit[1].target,
    );
    let tgt3Percent = calculate(
      signal.stopStart.entryEnd,
      signal.stopStart.takeProfit[2].target,
    );
    let tgtStop = calculate(
      signal.stopStart.entryEnd,
      signal.stopStart.stopLoss,
    );
    let result = {
      symbol: signal.symbol,
      entry: signal.stopStart.entryEnd,
      target1: signal.stopStart.takeProfit[0].target,
      reach1: signal.executed.profitTeleg[0] ? tgt1Percent : "",
      target2: signal.stopStart.takeProfit[1].target,
      reach2: signal.executed.profitTeleg[1] ? tgt2Percent : "",
      target3: signal.stopStart.takeProfit[2].target,
      reach3: signal.executed.profitTeleg[2] ? tgt3Percent : "",
      stop: signal.stopStart.stopLoss,
      reachSL: signal.executed.stopTeleg ? tgtStop : "",
      entryVolume: signal.stopStart.percentEntry,
      t1Volume: signal.stopStart.takeProfit[0].volume,
      t2Volume: signal.stopStart.takeProfit[1].volume,
      t3Volume: signal.stopStart.takeProfit[2].volume,
      closeDay: moment(signal.closedAt).format("DD/MM/YYYY"),
      closeHour: moment(signal.closedAt).format("kk:mm:ss"),
      createDay: moment(signal.createdAt).format("DD/MM/YYYY"),
      createHour: moment(signal.createdAt).format("kk:mm:ss"),
    };
    return result;
  };

  let _lineChart = <div />;
  if (chartGrossData.length > 0) _lineChart = lineChart();
  return (
    <div>
      {filters()} {_lineChart}
    </div>
  );
}

export default PerformDetail;
