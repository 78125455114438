import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import UserSignalDetailReport from "../components/userSignalDetailReport.js";
import { format } from "date-fns";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";

// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { roleConstrainter } from "../utils/clientConstraint";

import sysconfig from "../config";

function UserSignalsReport(props) {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showEndPicker, setShowEndPicker] = useState(false);
  const [showStartPicker, setShowStartPicker] = useState(false);
  const [msg, setMsg] = useState("");
  const [dateType, setDateType] = useState("close");
  const [usersignals, setUserSignals] = useState([]);
  const [showList, setShowList] = useState("hide");
  const [btnEnabled, setBtnEnabled] = useState(true);
  const [partner, setPartner] = useState("");
  const user = useSelector((state) => state.activeUser);
  const token = useSelector((state) => state.activeToken);

  //equivalent to didMount
  useEffect(() => {
    //sigList();
  }, []);

  const sigList = () => {
    let email = user.email;
    axios({
      method: "post",
      baseURL: sysconfig.EXECUTER_URI,
      url: "/usersignal/listrep",
      data: {
        email,
        clientEmail: partner,
        status: dateType === "close" ? "CLOSED" : "OPEN",
        product: "MANAGED",
        startDate: startDate,
        endDate: endDate,
      },
      headers: { "x-auth": token },
    })
      .then(function (response) {
        if (response.data) {
          setUserSignals(response.data);
          setMsg(response.data.length + " rows");
          setShowList("show");
        }
      })
      .catch(function (error) {
        console.log("Err sigList:", error);
      });
  };

  const getData = (val) => {
    //receive data from child component
    if (val === "hide") {
      setBtnEnabled(true);
      setShowList("hide");
      setMsg("");
    }
  };

  let partnerInput = <div />;
  if (roleConstrainter(user, "admin")) {
    partnerInput = (
      <input
        className="form-control"
        id="partner"
        rows="3"
        placeholder="client email"
        value={partner}
        onChange={(e) => setPartner(e.target.value)}
      />
    );
  }

  const handleShowStart = () => {
    if (showStartPicker)
      return (
        <DayPicker
          id="startDate"
          selected={startDate}
          value={startDate}
          onSelect={(date) => {
            setStartDate(date);
            setShowStartPicker(false);
          }}
          mode="single"
        />
      );
    else return <div />;
  };

  const handleShowEnd = () => {
    if (showEndPicker)
      return (
        <DayPicker
          id="endDate"
          selected={endDate}
          value={endDate}
          onSelect={(date) => {
            setEndDate(date);
            setShowEndPicker(false);
          }}
          mode="single"
        />
      );
    else return <div />;
  };

  const showFilters = () => {
    let details;
    if (user) {
      details = (
        <div>
          <div className="row">
            <div className="col-md-6">
              <label>
                <b>History</b>
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <label>
                <b>Start Date</b>
              </label>
            </div>
            <div className="col-md-3">
              <label>
                <b>End Date</b>
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <input
                className="form-control"
                id="showStart"
                placeholder="start date"
                value={startDate}
                onClick={(e) => setShowStartPicker(!showStartPicker)}
              />
              {handleShowStart()}
            </div>
            <div className="col-md-3">
              <input
                className="form-control"
                id="showEnd"
                placeholder="end date"
                value={endDate}
                onClick={(e) => setShowEndPicker(!showEndPicker)}
              />
              {handleShowEnd()}
            </div>
            <div className="col-md-3">
              {partnerInput}
              <span>
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="close"
                  id="dateType"
                  name="dateType"
                  checked={dateType === "close"}
                  onChange={(e) =>
                    setDateType(dateType === "close" ? "open" : "close")
                  }
                />
                <label className="form-check-label">Closed operations</label>
                <br />
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-1">
              <br />
              <button
                type="button"
                className="btn btn-primary btn-sm cursor-pointer"
                id="generate"
                disabled={!btnEnabled}
                onClick={(event) => sigList(event)}
              >
                Generate
              </button>

              <p className="text-info"> {msg}</p>
            </div>
          </div>
        </div>
      );
    }
    return details;
  };

  const handleStartDateChange = (changeEvent) => {
    //date.toLocaleDateString("en-US")
    setStartDate(changeEvent);
    setMsg("");
  };

  const handleEndDateChange = (changeEvent) => {
    //date.toLocaleDateString("en-US")
    setEndDate(changeEvent);
    setMsg("");
  };

  let _result =
    usersignals.length > 0 && showList !== "hide" ? (
      <UserSignalDetailReport
        usersignals={usersignals}
        user={user}
        sendData={getData}
        token={token}
      />
    ) : (
      <div />
    );
  let _showFilters = showFilters();
  return (
    <div>
      {_showFilters} {_result}
    </div>
  );
}

export default UserSignalsReport;
