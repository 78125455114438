import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import SignalGroupDetail from "../components/sigGroupDetail";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
//import Calendar from "react-calendar";
//import "react-calendar/dist/Calendar.css";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { roleConstrainter } from "../utils/clientConstraint";

import sysconfig from "../config";

function SignalGroup(props) {
  let iniDate = new Date();
  iniDate.setDate(iniDate.getDate() - 7);
  const [startDate, setStartDate] = useState(iniDate);
  const [endDate, setEndDate] = useState(new Date());
  const [showEndPicker, setShowEndPicker] = useState(false);
  const [showStartPicker, setShowStartPicker] = useState(false);
  const [msg, setMsg] = useState("");
  const [dateType, setDateType] = useState("open");
  const [signals, setSignals] = useState([]);
  const [showList, setShowList] = useState("hide");
  const [btnEnabled, setBtnEnabled] = useState(true);
  const [partner, setPartner] = useState("");
  const user = useSelector((state) => state.activeUser);
  const token = useSelector((state) => state.activeToken);

  //equivalent to didMount
  useEffect(() => {
    //sigList();
  }, []);

  const sigList = () => {
    let username = user.general.groupAccount
      ? user.general.groupAccount
      : user.username;
    axios({
      method: "post",
      baseURL: sysconfig.EXECUTER_URI,
      url: "/signal/list",
      data: {
        username,
        partner,
        product: "MANAGED",
        status: dateType === "close" ? "CLOSED" : "OPEN",
        orderSource: "signal",
        startDate: startDate,
        endDate: endDate,
        dateType,
      },
      headers: { "x-auth": token },
    })
      .then(function (response) {
        if (response.data) {
          setSignals(response.data);
          setMsg(response.data.length + " rows");
          if (response.data.length > 0) setBtnEnabled(false);
          setShowList("show");
        }
      })
      .catch(function (error) {
        console.log("Err sigList:", error);
      });
  };

  const getData = (val) => {
    //receive data from child component
    if (val === "hide") {
      setBtnEnabled(true);
      setShowList("hide");
      setMsg("");
    }
  };

  const handleShowStart = () => {
    if (showStartPicker)
      return (
        <DayPicker
          id="startDate"
          selected={startDate}
          value={startDate}
          onSelect={(date) => {
            setStartDate(date);
            setShowStartPicker(false);
          }}
          mode="single"
        />
      );
    else return <div />;
  };

  const handleShowEnd = () => {
    if (showEndPicker)
      return (
        <DayPicker
          id="endDate"
          selected={endDate}
          value={endDate}
          onSelect={(date) => {
            setEndDate(date);
            setShowEndPicker(false);
          }}
          mode="single"
        />
      );
    else return <div />;
  };

  let partnerInput = <div />;
  if (roleConstrainter(user, "admin")) {
    partnerInput = (
      <input
        className="form-control"
        id="partner"
        rows="3"
        placeholder="partner"
        value={partner}
        onChange={(e) => setPartner(e.target.value)}
      />
    );
  }

  const showStart = () => {
    return (
      <div className="col-md-3">
        <input
          className="form-control"
          id="showStart"
          placeholder="start date"
          value={startDate}
          onClick={(e) => setShowStartPicker(!showStartPicker)}
        />
        {handleShowStart()}
      </div>
    );
  };

  const showEnd = () => {
    return (
      <div className="col-md-3">
        <input
          className="form-control"
          id="showEnd"
          placeholder="end date"
          value={endDate}
          onClick={(e) => setShowEndPicker(!showEndPicker)}
        />
        {handleShowEnd()}
      </div>
    );
  };

  // const showStart = () => {
  //   if (btnEnabled)
  //     return (
  //       <Calendar
  //         id="startDate"
  //         selected={startDate}
  //         value={startDate}
  //         onChange={(date) => setStartDate(date)}
  //       />
  //     );
  //   else return <div />;
  // };

  // const showEnd = () => {
  //   if (btnEnabled)
  //     return (
  //       <Calendar
  //         id="endDate"
  //         selected={endDate}
  //         value={endDate}
  //         onChange={(date) => setEndDate(date)}
  //       />
  //     );
  //   else return <div />;
  // };

  const showFilters = () => {
    let details;
    if (user) {
      details = (
        <div>
          <div className="row">
            <div className="col-md-6">
              <label>
                <b>Partner Signals</b>
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <label>
                <b>Start Date</b>
              </label>
            </div>
            <div className="col-md-3">
              <label>
                <b>End Date</b>
              </label>
            </div>
          </div>
          <div className="row">
            {showStart()}
            {showEnd()}
            <div className="col-md-3">
              {partnerInput}
              <span>
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="close"
                  id="dateType"
                  name="dateType"
                  checked={dateType === "close"}
                  onChange={(e) =>
                    setDateType(dateType === "close" ? "open" : "close")
                  }
                />
                <label className="form-check-label">Closed signals</label>
                <br />
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-1">
              <br />
              <button
                type="button"
                className="btn btn-primary btn-sm cursor-pointer"
                id="generate"
                disabled={!btnEnabled}
                onClick={(event) => sigList(event)}
              >
                Generate
              </button>
              <p className="text-info"> {msg}</p>
            </div>
          </div>
        </div>
      );
    }
    return details;
  };

  let _result =
    signals.length > 0 && showList === "show" ? (
      <SignalGroupDetail signals={signals} user={user} sendData={getData} />
    ) : (
      <div />
    );
  let _showFilters = showFilters();
  return (
    <div>
      {_showFilters} {_result}
    </div>
  );
}

export default SignalGroup;

/*          <div className="col-md-3">
              <DatePicker
                id="startDate"
                selected={startDate}
                value={startDate}                
                onChange={(date) => setStartDate(date)}
              />
            </div>
            <div className="col-md-3">
              <DatePicker
                id="endDate"                
                selected={endDate}
                value={endDate}
                onChange={(date) => setEndDate(date)}
              />
            </div>
            */
