import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import RebalanceDetail from "../../components/portfolio/rebalanceDetail";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { roleConstrainter } from "../../utils/clientConstraint";

import sysconfig from "../../config";

function RebalanceSelect(props) {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [msg, setMsg] = useState("");
  const [dateType, setDateType] = useState("close");
  const [ptfs, setPtfs] = useState([]);
  const [showList, setShowList] = useState("hide");
  const [btnEnabled, setBtnEnabled] = useState(true);
  const [partner, setPartner] = useState("");
  const user = useSelector(state => state.activeUser);
  const token = useSelector(state => state.activeToken);

  //equivalent to didMount
  useEffect(() => {
    //sigList();
  }, []);
  const ptfList = props => {
    let username = user.general.groupAccount ? user.general.groupAccount : user.username;
    axios({
      method: "post",
      baseURL: sysconfig.EXECUTER_URI,
      url: "/portfolio/history",
      data: {
        username,
        partner,
        startDate: startDate,
        endDate: endDate
      },
      headers: { "x-auth": token }
    })
      .then(function(response) {
        if (response.data) {
          setPtfs(response.data);
          setMsg(response.data.length + " rows");
          setShowList("show");
        }
      })
      .catch(function(error) {
        console.log("Err ptfList:", error);
      });
  };

  const getData = val => {
    //receive data from child component
    if (val === "hide") {
      setBtnEnabled(true);
      setShowList("hide");
      setMsg("");
    }
  };

  let partnerInput = <div />;
  if (roleConstrainter(user, "admin")) {
    partnerInput = (
      <input
        className="form-control"
        id="partner"
        rows="3"
        placeholder="partner"
        value={partner}
        onChange={e => setPartner(e.target.value)}
      />
    );
  }

  const showFilters = () => {
    let details;
    if (props.user) {
      details = (
        <div>
          <div className="row">
            <div className="col-md-6">
              <label>
                <b>Portfolio Report</b>
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <label>
                <small>Start Date</small>
              </label>
            </div>
            <div className="col-md-3">
              <label>
                <small>End Date</small>
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <DatePicker
                id="startDate"
                selected={startDate}
                value={startDate}
                onChange={handleStartDateChange}
              />
            </div>
            <div className="col-md-3">
              <DatePicker
                id="endDate"
                selected={endDate}
                value={endDate}
                onChange={handleEndDateChange}
              />
            </div>
            <div className="col-md-3">{partnerInput}</div>
          </div>
          <div className="row">
            <div className="col-md-1">
              <br />
              <button
                type="button"
                className="btn btn-primary btn-sm cursor-pointer"
                id="generate"
                disabled={!btnEnabled}
                onClick={event => ptfList(event)}
              >
                Generate
              </button>

              <p className="text-info"> {msg}</p>
            </div>
          </div>
        </div>
      );
    }
    return details;
  };

  const handleStartDateChange = changeEvent => {
    //date.toLocaleDateString("en-US")
    setStartDate(changeEvent);
    setMsg("");
  };

  const handleEndDateChange = changeEvent => {
    //date.toLocaleDateString("en-US")
    setEndDate(changeEvent);
    setMsg("");
  };

  let _result =
    ptfs.length > 0 && showList !== "hide" ? (
      <RebalanceDetail ptfs={ptfs} user={props.user} sendData={getData} />
    ) : (
      <div />
    );
  let _showFilters = showFilters();
  return (
    <div>
      <div className="col-md-1" />
      <div className="col-md-11">
        {_showFilters} {_result}
      </div>
    </div>
  );
}

export default RebalanceSelect;
