import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
//import ReactJson from "react-json-view";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import sysconfig from "../config";

function UserSignals(props) {
  const [msg, setMsg] = useState("");
  const [signals, setSignals] = useState([]);
  const [symbol, setSymbol] = useState("");
  const user = useSelector((state) => state.activeUser);
  const token = useSelector((state) => state.activeToken);

  //equivalent to didMount
  useEffect(() => {
    //sigList();
  }, []);

  const getSignals = () => {
    setMsg("");
    setSignals("");
    axios({
      method: "post",
      baseURL: sysconfig.EXECUTER_URI,
      url: "/usersignal/list",
      data: {
        username: user.username,
        email: props.email,
        symbol,
      },
      headers: { "x-auth": token },
    })
      .then(function(response) {
        if (response.data) {
          setSignals(response.data);
        }
      })
      .catch(function(error) {
        setMsg(error.response.data.message);
        console.log("Err getSignals:", error);
      });
  };
  const showFilters = () => {
    let lines = (
      <div>
        <div className="row">
          <div className="col-md-6">
            <input
              className="form-control"
              id="symbol"
              placeholder="symbol"
              value={symbol}
              onChange={onInputChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <br />
            <button
              type="button"
              className="btn btn-primary btn-sm cursor-pointer"
              id="generate"
              onClick={(event) => getSignals(event)}
            >
              Signals
            </button>

            <p className="text-info"> {msg}</p>
          </div>
        </div>
      </div>
    );
    return lines;
  };

  const onInputChange = (event) => {
    setSymbol(event.target.value.toUpperCase());
  };

  let _filters = showFilters();

  let _signals = <div />;
  //   signals && signals.length > 0 ? (
  //     <div>
  //       <ReactJson src={signals} name="Signals" displayDataTypes={false} collapsed={true} />
  //     </div>
  //   ) : (
  //     <div />
  //   );

  return (
    <div>
      {_filters} {_signals}
    </div>
  );
}

export default UserSignals;
