import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import validator from "validator";

import sysconfig from "../config";

function SelectKeys(props) {
  const [exchange, setExchange] = useState("");
  const [subaccount, setSubAccount] = useState("");

  //equivalent to didMount
  useEffect(
    () => {
      listKeys();
    },
    []
    //strategies
  );

  const handleSelectionChange = event => {
    let selection = event.target.value.split("-");
    if (selection && selection.length === 2)
      props.sendData({ exchange: selection[0], subaccount: selection[1] });
  };

  const listKeys = () => {
    let monitorSymbolInd = props.user.monitor.findIndex(doc => (doc.symbol = props.symbol));
    let currExchange = "";
    let currSubAccount = "";
    if (monitorSymbolInd !== -1) {
      currExchange = props.user.monitor[monitorSymbolInd].externalExchange;
      currSubAccount = props.user.monitor[monitorSymbolInd].externalSubAccount;
    }
    let exchanges = props.user.exchanges;
    let i = 0;
    let listItems = [
      <option id="select" value="select" selected={currExchange === ""}>
        Select api key
      </option>
    ];
    for (let exchange of exchanges) {
      let exchangeName = exchange.exchange;
      for (let subaccount of exchange.subaccounts) {
        let subaccountName = subaccount.description;
        listItems.push(
          <option
            id={`${exchangeName}-${subaccountName}`}
            key={i}
            value={`${exchangeName}-${subaccountName}`}
            selected={exchangeName === currExchange && subaccountName === currSubAccount}
          >
            {`${exchangeName} - ${subaccountName}`}
          </option>
        );
        i++;
      }
    }
    let result = (
      <select className="form-control form-control-sm" id="apikey" onChange={handleSelectionChange}>
        {listItems}
      </select>
    );
    return result;
  };

  return <div>{listKeys()}</div>;
}

export default SelectKeys;
