import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import validator from "validator";
import _ from "lodash";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { roleConstrainter } from "../utils/clientConstraint";

import sysconfig from "../config";
import SignalUserSum from "../components/sigUserSum";

class SignalSummary extends Component {
  constructor(props) {
    super(props);
    this.handleBtnClick = this.handleBtnClick.bind(this);
    this.handleCheckChange = this.handleCheckChange.bind(this);
    this.handleStartChange = this.handleStartChange.bind(this);
    this.handleEndChange = this.handleEndChange.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.getData = this.getData.bind(this);
    let startDate = new Date();
    let endDate = new Date();
    //startDate = startDate.toLocaleDateString("en-US");
    this.state = {
      summary: [],
      startDate,
      emailList: "",
      partner: "",
      symbol: "",
      endDate,
      perPage: 50,
      tradelist: "hide",
      mode: "real",
      btnEnabled: true
    };
    // var _this = this;
    // axios({
    //   method: "get",
    //   baseURL: sysconfig.EXECUTER_URI,
    //   url: "/getsetting?exchange=" + this.props.user.exchange
    // })
    //   .then(function(response) {
    //     if (response.data) {
    //       _this.setState({ settings: response.data });
    //     }
    //   })
    //   .catch(function(error) {
    //     console.log(error);
    //   });
  }

  showFilters() {
    let test = this.state.mode === "test";
    let real = this.state.mode === "real";
    let btnLine = this.btns();
    let details;
    let emailList;
    let partner;
    if (roleConstrainter(this.props.user, "admin")) {
      partner = (
        <div className="col-md-3">
          <input
            className="form-control"
            id="partner"
            rows="3"
            placeholder="partner"
            value={this.state.partner}
            onChange={this.onInputChange}
          />
        </div>
      );
    }
    if (roleConstrainter(this.props.user, "trader") || roleConstrainter(this.props.user, "admin")) {
      emailList = (
        <div className="col-md-3">
          <textarea
            className="form-control"
            id="emailList"
            rows="3"
            placeholder="Comma separated client emails"
            value={this.state.emailList}
            onChange={this.onInputChange}
          />
        </div>
      );
    }
    if (this.props.user) {
      details = (
        <div>
          <div className="row">
            <div className="col-md-9">
              <label>
                <b>Signals Summary</b>
              </label>
            </div>{" "}
            {partner}
          </div>
          <div className="row">
            <div className="col-md-3">
              <label>
                <b>Start Date</b>
              </label>
            </div>
            <div className="col-md-3">
              <label>
                <b>End Date</b>
              </label>
            </div>
            <div className="col-md-1">
              <label>
                <b>Mode</b>
              </label>
            </div>
            <div className="col-md-2">
              <label>
                <b>Symbol</b>
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <DatePicker
                id="startDate"
                selected={this.state.startDate}
                value={this.state.startDate}
                onChange={this.handleStartChange}
              />
              <br />
              <br />
              {btnLine}
            </div>
            <div className="col-md-3">
              <DatePicker
                id="endDate"
                selected={this.state.endDate}
                value={this.state.endDate}
                onChange={this.handleEndChange}
              />
            </div>
            <div className="form-group col-md-1 p-1">
              <div className="form-check">
                <input
                  className="form-check-input "
                  type="radio"
                  id="checkTest"
                  value="test"
                  checked={test}
                  onChange={this.handleCheckChange}
                />
                <label className="form-check-label">test</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  id="checkReal"
                  value="real"
                  checked={real}
                  onChange={this.handleCheckChange}
                />
                <label className="form-check-label">real</label>
              </div>
            </div>
            <div className="col-md-2">
              <input
                className="form-control form-control-sm"
                id="symbol"
                placeholder="Symbol"
                value={this.state.symbol}
                onChange={this.onInputChange}
              />
            </div>
            {emailList}
          </div>
        </div>
      );
    }
    return details;
  }

  btns() {
    let btns = (
      <div>
        <button
          type="button"
          className="btn btn-primary btn-sm cursor-pointer"
          id="generate"
          disabled={!this.state.btnEnabled}
          onClick={event => this.handleBtnClick(event)}
        >
          Generate
        </button>

        <p className="text-info"> {this.state.msg}</p>
      </div>
    );
    return btns;
  }

  handleCheckChange(changeEvent) {
    this.setState({
      mode: changeEvent.target.value
    });
  }

  handleBtnClick(event) {
    try {
      let _this = this;
      axios({
        method: "post",
        baseURL: sysconfig.EXECUTER_URI,
        url: "/signal/summary",
        data: {
          lidername: this.props.user.username,
          admin: this.props.user.role === "admin",
          status: "FILLED",
          orderSource: "signal",
          symbol: this.state.symbol,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          emailList: this.state.emailList
            .replace(";", ",")
            .replace("/", ",")
            .replace(" ", ""),
          partner: this.state.partner,
          mode: this.state.mode
        },
        headers: { "x-auth": this.props.token }
      })
        .then(function(response) {
          if (response.data) {
            _this.setState({ summary: response.data, btnEnabled: false, tradelist: "show" });
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  }

  getData(val) {
    //receive data from child component
    if (val === "hide") {
      this.setState({ tradelist: "hide", btnEnabled: true });
    }
  }

  handleStartChange(changeEvent) {
    //date.toLocaleDateString("en-US")
    this.setState({
      startDate: changeEvent,
      msg: ""
    });
  }
  handleEndChange(changeEvent) {
    //date.toLocaleDateString("en-US")
    this.setState({
      endDate: changeEvent,
      msg: ""
    });
  }

  onInputChange(event) {
    let value = event.target.value;
    if (event.target.id === "symbol") value = event.target.value.toUpperCase();
    this.setState({
      [event.target.id]: value
    });
  }

  render() {
    let role = this.props.user ? this.props.user.role : false;
    let limit = this.state.perPage;
    let filter = this.showFilters();
    let panel = <div />;
    if (this.state.tradelist !== "hide") {
      let selectedFilters = {
        status: "FILLED",
        orderSource: "signal",
        symbol: this.state.symbol,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        mode: this.state.mode
      };
      panel = (
        <SignalUserSum
          sendData={this.getData}
          summary={this.state.summary}
          token={this.props.token}
          filters={selectedFilters}
        />
      );
    }
    return (
      <span>
        <div className="container">
          <div className="form-group">{filter}</div>
          {panel}
        </div>
        <br />
      </span>
    );
  }
}

function mapStateToProps(state) {
  //whatever is returned will show as props inside this container
  return {
    user: state.activeUser,
    token: state.activeToken
  };
}

//promote Login from a component to a container with added props activeToken
export default connect(mapStateToProps)(SignalSummary);
