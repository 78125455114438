import { combineReducers } from "redux";
import ActiveUser from "./reducer_active_user";
import ActiveToken from "./reducer_active_token";
import ActivePartner from "./reducer_active_partner";

const rootReducer = combineReducers({
  activeUser: ActiveUser,
  activeToken: ActiveToken,
  activePartner: ActivePartner
});

export default rootReducer ;