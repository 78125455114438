import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import sysconfig from "../../config";

function UserStrategies(props) {
  const [message, setMessage] = useState("");
  const user = useSelector(state => state.activeUser);
  const token = useSelector(state => state.activeToken);

  useEffect(() => {
    let i = 0;
    for (let item of user.monitor) {
      if (
        item.strategy &&
        item.strategy !== "StrategyOne" &&
        item.externalDelegate &&
        item.externalDelegate !== "none"
      )
        i++;
    }
    if (i === 0) setMessage("Currently you have no selected strategy");
  }, []);

  const handleUnfollowClick = event => {
    let symbol = event.target.id;
    let data = {
      email: user.email,
      symbol
    };
    axios({
      method: "post",
      baseURL: sysconfig.EXECUTER_URI,
      url: "/userstrategy/unfollow",
      headers: { "x-auth": token },
      data
    })
      .then(function(response) {
        if (response.data) {
          setMessage(response.data);
        }
      })
      .catch(function(error) {
        if (error.response) setMessage(error.response.data);
        else setMessage(error.message);
      });
  };

  const list = () => {
    let listItems = [];
    let i = 0;
    for (let item of user.monitor) {
      if (
        item.strategy &&
        item.strategy !== "StrategyOne" &&
        item.externalDelegate &&
        item.externalDelegate !== "none"
      )
        listItems.push(
          <div key={i} style={{ fontSize: "small" }}>
            <div className="row">
              <div className="col-md-1">{item.symbol}</div>
              <div className="col-md-1">{item.externalDelegate}</div>
              <div className="col-md-2">{item.strategy}</div>
              <div className="col-md-1">
                <span
                  className="btn-link cursor-pointer"
                  key={i}
                  id={item.symbol}
                  onClick={handleUnfollowClick}
                >
                  Unfollow
                </span>
              </div>
            </div>
          </div>
        );
      i++;
    }
    return listItems;
  };

  const title = () => {
    return (
      <div>
        <div className="row">
          <div className="col-md-3">
            <h5> My Strategies </h5>
          </div>
        </div>
        <div className="row">
          <div className="col-md-1">Symbol</div>
          <div className="col-md-1">Provider</div>
          <div className="col-md-2">Strategy</div>
        </div>
      </div>
    );
  };
  let _list = list();
  let _title = title();
  return (
    <div>
      {_title} {_list}
      <label className="text-primary"> {message} </label>
    </div>
  );
}

export default UserStrategies;
