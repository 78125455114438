import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import axios from "axios";

import sysconfig from "../config";
import BalanceShow from "../components/balanceshow";
import { roleConstrainter } from "../utils/clientConstraint";

class FollowersBalance extends Component {
  constructor(props) {
    super(props);
    this.handleBtnClick = this.handleBtnClick.bind(this);
    this.handleCheckChange = this.handleCheckChange.bind(this);
    this.handleMktChange = this.handleMktChange.bind(this);
    this.handleDetailClick = this.handleDetailClick.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.getData = this.getData.bind(this);
    this.state = {
      emailList: "",
      partner: "",
      btnName: "Generate",
      displaybalance: "hide",
      status: "activeOn",
      market: "spot"
    };
  }

  getData(val) {
    //receive data from child component
    if (val === "hide-balance") {
      this.setState({ displaybalance: "hide" });
    }
  }

  handleBtnClick() {
    this.setState({ btnName: "wait..." });
    this.balanceSum();
  }

  handleDetailClick(email) {
    this.setState({ email, displaybalance: "show", showReload: true });
    this.getOneBalance(email);
  }

  onInputChange(event) {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleCheckChange(changeEvent) {
    if (changeEvent.target.id === "checkOn") {
      this.setState({
        status: "activeOn"
      });
    }
    if (changeEvent.target.id === "checkOff") {
      this.setState({
        status: "activeOff"
      });
    }
  }

  handleMktChange(changeEvent) {
    this.setState({
      market: changeEvent.target.value
    });
  }

  balanceSum() {
    var _this = this;
    axios({
      method: "post",
      baseURL: sysconfig.EXECUTER_URI,
      url: "/balance/sum",
      data: {
        lidername: this.props.user.username,
        admin: this.props.user.role === "admin",
        email: this.props.user.email,
        emailList: this.state.emailList
          .replace(";", ",")
          .replace("/", ",")
          .replace(" ", ""),
        partner: this.state.partner,
        market: this.state.market
      }
    })
      .then(function(response) {
        if (response.status === 200) {
          _this.setState({ balances: response.data, btnName: "Generate" });
        }
      })
      .catch(function(error) {
        console.log(error);
        _this.setState({ btnName: "Generate" });
        alert("Error: " + error.response.data.message);
      });
  }

  getOneBalance(email) {
    var _this = this;
    axios({
      method: "post",
      baseURL: sysconfig.EXECUTER_URI,
      url: "/balance/get",
      data: {
        email,
        market: this.state.market,
        exchange: this.state.exchange ? this.state.market : "binance",
        subaccount: this.state.subaccount ? this.state.subaccount : "main"
      },
      headers: { "x-auth": this.props.token }
    })
      .then(function(response) {
        if (response.status === 200) {
          _this.setState({ currBalance: response.data, showReload: false });
          // _this.state.currBalance = response.data;
          // _this.state.showReload = false;
        }
      })
      .catch(function(error) {
        console.log(error);
        alert("Error: " + error.response.data.message);
      });
  }

  btns() {
    if (
      !roleConstrainter(this.props.user, "trader") &&
      !roleConstrainter(this.props.user, "admin")
    ) {
      return <div />;
    }
    let partner;
    if (roleConstrainter(this.props.user, "admin") && !this.props.onlyTotal) {
      partner = (
        <div className="col-md-3">
          <input
            className="form-control"
            id="partner"
            rows="3"
            placeholder="partner"
            value={this.state.partner}
            onChange={this.onInputChange}
          />
        </div>
      );
    }
    let emailList;
    if (!this.props.onlyTotal) {
      emailList = (
        <div className="col-md-3">
          <textarea
            className="form-control"
            id="emailList"
            rows="3"
            placeholder="Comma separated client emails"
            value={this.state.emailList}
            onChange={this.onInputChange}
          />
        </div>
      );
    }

    let btnGenerate = (
      <button
        type="button"
        className="btn btn-secondary btn-sm cursor-pointer"
        id="followers"
        onClick={event => this.handleBtnClick()}
      >
        {this.state.btnName}
      </button>
    );
    let btnLayout = (
      <div className="col-md-3" align="left">
        {" "}
        {btnGenerate}{" "}
      </div>
    );
    if (this.props.onlyTotal) {
      btnLayout = (
        <div>
          <br />
          <br /> {btnGenerate}
        </div>
      );
    }
    let details;
    if (this.props.user) {
      details = (
        <div>
          <div className="row">
            <div className="col-md-6">
              <label>
                <b>Volumes Report</b>
              </label>
            </div>
            {partner}
          </div>
          <div className="row" style={{ fontSize: "small" }}>
            <div className="form-group col-md-3">
              <span className="border border-secondary p-2">
                <div className="form-check form-check-inline">
                  <label className="mr-2"> Status </label>
                  <input
                    className="form-check-input mb-2"
                    type="radio"
                    id="checkOn"
                    value="on"
                    checked={this.state.status === "activeOn"}
                    onChange={this.handleCheckChange}
                  />
                  <label className="form-check-label mb-2 mr-2"> ON </label>
                  <input
                    className="form-check-input mb-2"
                    type="radio"
                    id="checkOff"
                    value="off"
                    checked={this.state.status === "activeOff"}
                    onChange={this.handleCheckChange}
                  />
                  <label className="form-check-label mb-2">OFF</label>
                </div>
              </span>
            </div>
            <div className="col-md-3">
              <span className="border border-secondary p-2">
                <div className="form-group form-group-sm form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="checkSpot"
                    value="spot"
                    checked={this.state.market === "spot"}
                    onChange={this.handleMktChange}
                  />
                  <label className="form-check-label">Spot</label>
                </div>
                <div className="form-group form-group-sm form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    handleMktChange
                    id="checkFutures"
                    value="futures"
                    checked={this.state.market === "futures"}
                    onChange={this.handleMktChange}
                  />
                  <label className="form-check-label">Futures</label>
                </div>
              </span>
            </div>
            {emailList}
            {btnLayout}
          </div>
        </div>
      );
    }
    return details;
  }

  title() {
    if (!this.state.balances) return <div />;
    return (
      <div className="font-weight-bold">
        <div className="row" align="right" style={{ fontSize: "small" }}>
          <div className="col-md-2" align="center">
            User
          </div>
          <div className="col-md-1"> Status</div>
          <div className="col-md-1"> BTC</div>
          <div className="col-md-1"> USD</div>
        </div>
      </div>
    );
  }

  panel() {
    let detail = <div />;
    let totLine = <div />;
    let colFormat = this.props.onlyTotal ? "col-md-2" : "col-md-1";
    if (this.state.balances) {
      detail = [];
      let totBTC = 0;
      let totUSD = 0;
      this.state.balances.filter(doc => doc.status === this.state.status).map(elem => {
        totBTC += elem.BTC;
        totUSD += elem.USDT;
        detail.push(
          <div className="row" align="right" style={{ fontSize: "small" }}>
            <div className="col-md-2" align="center">
              {elem.user}
            </div>
            <div className="col-md-1"> {elem.status}</div>
            <div className="col-md-1"> {_.round(elem.BTC, 5)}</div>
            <div className="col-md-1"> {_.round(elem.USDT, 2)}</div>
            <div className="col-md-1">
              <span
                className="btn-link cursor-pointer"
                onClick={event => this.handleDetailClick(elem.user)}
              >
                ->
              </span>
            </div>
          </div>
        );
      });
      totLine = (
        <div className="row" align="right" style={{ fontSize: "small" }}>
          <div className="col-md-2" align="center">
            Total
          </div>
          <div className="col-md-1"> </div>
          <div className={colFormat}> {_.round(totBTC, 5)}</div>
          <div className={colFormat}> {_.round(totUSD, 2)}</div>
        </div>
      );
    }
    return [detail, totLine];
  }

  render() {
    if (this.props.user.role !== "trader" && this.props.user.role !== "admin") {
      return <div />;
    }
    let btns = this.btns();
    if (this.props.onlyTotal) {
      let [panel, totLine] = this.panel();
      return (
        <div>
          {btns}
          <div className="row" align="right" style={{ fontSize: "small" }}>
            <div className="col-md-3" />
            <div className="col-md-2"> {this.state.balances ? "BTC" : ""}</div>
            <div className="col-md-2"> {this.state.balances ? "USD" : ""}</div>
          </div>
          {totLine}
        </div>
      );
    }
    let title = this.title();
    let [panel, totLine] = this.panel();
    let balanceShow = <div />;
    if (this.state.email && this.state.displaybalance === "show") {
      balanceShow = <BalanceShow sendData={this.getData} currBalance={this.state.currBalance} />;
    }
    return (
      <div>
        {btns}
        {title}
        {panel} {totLine}
        <div>{balanceShow}</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  //whatever is returned will show as props inside this container
  return {
    user: state.activeUser,
    token: state.activeToken
  };
}

//promote Login from a component to a container with added props activeToken
export default connect(mapStateToProps)(FollowersBalance);
