import React, { Component } from "react";
import axios from "axios";
import validator from "validator";

import sysconfig from "../config";
import Menu from "./menu";
import Footer from "./footer";

class Signup extends Component {
  constructor(props) {
    super(props);
    this.handleRegisterClick = this.handleRegisterClick.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.state = {
      username: "",
      email: "",
      email2: "",
      password: "",
      verification: "",
      exchange: "binance",
      btnSubmit: "Submit",
      isBtnDisabled: false,
      msg: ""
    };
  }

  onInputChange(event) {
    let value = event.target.value.trim();
    let lastChar = value.slice(-1);
    if (/^[a-zA-Z0-9@._-\s]*$/.test(lastChar)) {
      this.setState({
        [event.target.id]: event.target.value.trim()
      });
    }
  }

  handleRegisterClick(event) {
    try {
      if (!validator.isEmail(this.state.email)) {
        throw new Error("Invalid email");
      }
      if (this.state.username.length < 3) {
        throw new Error("Username must be at least 3 characters");
      }
      if (this.state.email !== this.state.email2) {
        throw new Error("Email confirmation doesn't match");
      }
      if (this.state.username.trim().toLowerCase() === "none") {
        throw new Error("Invalid username");
      }
      if (this.state.password.length < 8) {
        throw new Error("Password must be at least 8 digits");
      }
      if (this.state.password !== this.state.verification) {
        throw new Error("Password verification do not match");
      }
      this.setState({
        isBtnDisabled: true,
        btnSubmit: "Wait...",
        email: "",
        email2: "",
        password: "",
        verification: ""
      });
      let username = this.state.username
        .replace(".", "")
        .replace("%", "")
        .replace("'", "")
        .replace("&", "")
        .replace("$", "")
        .replace("@", "")
        .replace(" ", "_")
        .replace(/[/\\"]/g, "");
      var _this = this;
      axios({
        method: "post",
        baseURL: sysconfig.EXECUTER_URI,
        url: "/user/fromguru",
        data: {
          api_token: sysconfig.CLIENT_KEY,
          last_transaction: {
            contact: {
              name: username,
              email: this.state.email
            },
            invoice: {
              period_end: "2100-01-01"
            }
          },
          product: {
            marketplace_id: "10",
            name: "Free"
          },
          status: "approved",
          exchange: this.state.exchange
        }
      })
        .then(function(response) {
          _this.setState({
            btnSubmit: "Submit",
            isBtnDisabled: true,
            msg: "Account created! Check your email or reset password if necessary."
          });
        })
        .catch(function(error) {
          if (error.response) alert("Error: " + error.response.data);
          else alert("Error: " + error.message);
          _this.setState({
            btnSubmit: "Submit",
            isBtnDisabled: false,
            msg: ""
          });
        });
    } catch (e) {
      console.log(e);
      alert("Error: " + e.message);
    }
  }
  // api: sysconfig.CLIENT_KEY,
  // email: this.state.email,
  // password: this.state.password,
  // username: this.state.username
  //   .replace(".", "")
  //   .replace("%", "")
  //   .replace("'", "")
  //   .replace("&", "")
  //   .replace("$", "")
  //   .replace("@", "")
  //   .replace(" ", "_")
  //   .replace(/[/\\"]/g, ""),
  // exchange: this.state.exchange
  handleOptionChange(changeEvent) {
    this.setState({
      exchange: changeEvent.target.value
    });
  }

  render() {
    return (
      <div>
        <Menu logged={false} />
        <p />
        <form>
          <div className="container w-75 form-group">
            <div className="form-group">
              <label>Select your Exchange</label>
              <select className="form-control" id="exchange">
                <option value="binance" checked onChange={this.handleOptionChange}>
                  BINANCE
                </option>
              </select>
            </div>
            <div className="form-group">
              <label>Username</label>
              <input
                className="form-control"
                id="username"
                placeholder="Enter username"
                value={this.state.username}
                onChange={this.onInputChange}
              />
            </div>
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Enter email"
                value={this.state.email}
                onChange={this.onInputChange}
              />
            </div>
            <div className="form-group">
              <label>Email confirmation</label>
              <input
                type="email"
                className="form-control"
                id="email2"
                placeholder="Enter email"
                value={this.state.email2}
                onChange={this.onInputChange}
              />
            </div>
            <div className="form-group">
              <label>Password </label>
              <small className="text-danger ml-2">
                (do NOT use the same password of your Exchange)
              </small>
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder="Enter password"
                value={this.state.password}
                onChange={this.onInputChange}
              />
            </div>
            <div className="form-group">
              <label>Password Verificatiion</label>
              <input
                type="password"
                className="form-control"
                id="verification"
                placeholder="Enter password"
                value={this.state.verification}
                onChange={this.onInputChange}
              />
            </div>
            <button
              type="button"
              className="btn btn-primary mr-4"
              disabled={this.state.isBtnDisabled}
              onClick={event => this.handleRegisterClick()}
            >
              {this.state.btnSubmit}
            </button>
            <div className="text-danger mt-2">{this.state.msg}</div>
          </div>
        </form>
        <Footer />
      </div>
    );
  }
}

export default Signup;
