import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  VictoryChart,
  VictoryLine,
  VictoryTooltip,
  VictoryVoronoiContainer,
  VictoryTheme,
} from "victory";
import sysconfig from "../config";

import { selectUser, selectToken, selectPartner } from "../actions/root";

function BalanceHistory(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.activeUser);
  const token = useSelector((state) => state.activeToken);
  let iniDate = new Date();
  iniDate.setDate(iniDate.getDate() - 90);
  const [startDate, setStartDate] = useState(iniDate);
  const [endDate, setEndDate] = useState(new Date());
  const [market, setMarket] = useState("SPOT");
  const [balances, setBalances] = useState([]);
  const [basecoin, setBasecoin] = useState("USD");

  useEffect(() => {
    getBalances();
  }, []);

  const handleStartDateChange = (changeEvent) => {
    //date.toLocaleDateString("en-US")
    setStartDate(changeEvent);
  };

  const handleEndDateChange = (changeEvent) => {
    //date.toLocaleDateString("en-US")
    setEndDate(changeEvent);
  };

  const handleBasecoinChange = (e) => {
    setBasecoin(e.target.value);
  };

  const handleMarketChange = (e) => {
    setMarket(e.target.value);
  };

  const getBalances = () => {
    let data = {
      email: user.email,
      startDate,
      endDate,
      market,
      level: "SUM",
    };
    axios({
      method: "post",
      baseURL: sysconfig.EXECUTER_URI,
      url: "/balance/list",
      headers: { "x-auth": token },
      data,
    })
      .then(function (response) {
        if (response.status === 200) {
          let items = [];
          for (let doc of response.data) {
            items.push({
              createdAt: moment(doc.createdAt).format("YYMMDD"),
              USD: doc.balance.USD,
              BTC: doc.balance.BTC,
            });
          }
          setBalances(items);
        }
      })
      .catch(function (error) {
        console.log("Err getBalances", error);
      });
  };

  const dateFilters = () => {
    return (
      <div className="row">
        <div className="col-md-3">
          <DatePicker
            id="startDate"
            selected={startDate}
            value={startDate}
            onChange={handleStartDateChange}
          />
        </div>
        <div className="col-md-1" />
        <div className="col-md-3">
          <DatePicker
            id="endDate"
            selected={endDate}
            value={endDate}
            onChange={handleEndDateChange}
          />
        </div>
      </div>
    );
  };

  const lineChart = () => {
    let toChart = balances.map((doc, i) => {
      const container = {};
      container.x = i.toFixed(0);
      container.y = basecoin === "USD" ? doc.USD : doc.BTC;
      container.label = doc.createdAt;
      return container;
    });
    //console.log("CHART", toChart);
    return (
      <VictoryChart
        width={700}
        height={500}
        theme={VictoryTheme.material}
        containerComponent={<VictoryVoronoiContainer responsive={false} />}
      >
        <VictoryLine
          labelComponent={<VictoryTooltip />}
          data={toChart}
          style={{
            data: { stroke: "#c43a31" },
            parent: { border: "1px solid #ccc" },
          }}
        />
      </VictoryChart>
    );
  };

  const marketSelection = () => {
    let choices = (
      <div>
        <div className="form-group form-group-sm form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            id="checkSpot"
            value="spot"
            checked={market === "SPOT"}
            onChange={handleMarketChange}
          />
          <label className="form-check-label">SPOT</label>
        </div>
      </div>
    );
    return choices;
  };

  const baseCoin = () => {
    let choices = (
      <div>
        <div className="form-group form-group-sm form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            id="btc"
            value="BTC"
            checked={basecoin === "BTC"}
            onChange={handleBasecoinChange}
          />
          <label className="form-check-label">BTC</label>
        </div>
        <div className="form-group form-group-sm form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            id="usd"
            value="USD"
            checked={basecoin === "USD"}
            onChange={handleBasecoinChange}
          />
          <label className="form-check-label">USD</label>
        </div>
      </div>
    );
    return choices;
  };

  const renderer = () => {
    let _lineChart = lineChart();
    let result = (
      <div className="row">
        {" "}
        <div className="col-md-8">{_lineChart}</div>
        <div className="col-md-4">
          {dateFilters()} <br />
          {marketSelection()}
          {baseCoin()}
          <button
            type="button"
            className="btn btn-primary btn-sm cursor-pointer"
            id="generate"
            onClick={() => getBalances()}
          >
            Reload
          </button>
        </div>
      </div>
    );
    return result;
  };
  //let _list = list();
  let _renderer = renderer();
  return _renderer;
}

export default BalanceHistory;

// const list = () => {
//   let items = [];
//   for (let doc of balances) {
//     items.push(
//       <div className="row">
//         <div className="col-md-2">
//           {moment(doc.createdAt).format("YYMMDD")}
//         </div>
//         <div className="col-md-2">{doc.USD}</div>
//       </div>,
//     );
//   }

//   return <div className="form-group"> {items} </div>;
// };
