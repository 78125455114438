import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
//import { init } from "react-tawkto";
import ReactTooltip from "react-tooltip";

import { selectUser, selectToken } from "../actions/root";
import { roleConstrainter } from "../utils/clientConstraint";
//import ProductSelection from "./productSelection";
import sysconfig from "../config";

class User extends Component {
  constructor(props) {
    super(props);
    this.handleResendClick = this.handleResendClick.bind(this);
    this.handleBtnClick = this.handleBtnClick.bind(this);
    this.state = {
      msg: "",
      activeBtn: true,
    };
    //if (this.props.source === "some-talkto-client")
    //  init(sysconfig.TALK_CRIPTOMANIACOS, this.callback());
  }

  callback() {
    console.log("Talk.to callback");
  }

  loadUser() {
    var _this = this;
    axios({
      method: "post",
      baseURL: sysconfig.EXECUTER_URI,
      url: "/user/getme",
      headers: { "x-auth": this.props.token },
    })
      .then(function (response) {
        if (response.status === 200) {
          if (!_this.props.email) {
            _this.props.selectUser(response.data);
          } else {
            if (_this.props.email === response.data.email) {
              _this.props.selectUser(response.data);
            } else {
              _this.props.selectToken(null);
            }
          }
        }
      })
      .catch(function (error) {
        console.log("Err LoadUser", error);
        _this.props.selectToken(null);
      });
  }

  getLeader() {
    var _this = this;
    axios({
      method: "get",
      baseURL: sysconfig.EXECUTER_URI,
      url: "/user/getLeader?email=" + this.props.user.email,
      headers: { "x-auth": _this.props.token },
    })
      .then(function (response) {
        if (response.status === 200) {
          _this.setState({
            leader: response.data,
          });
        }
      })
      .catch(function (error) {
        console.log("Err getLeader", error);
      });
  }

  componentDidUpdate() {
    //this.loadUser();
    let user = this.props.user;
    if (
      user &&
      user.general.delegate !== "none" &&
      !this.state.leader &&
      !roleConstrainter(user, "trader")
    ) {
      this.getLeader();
    }
    if (user && roleConstrainter(user, "trader") && this.state.activeBtn)
      this.setState({ activeBtn: false });
  }
  componentDidMount() {
    this.loadUser();
    this.interval = setInterval(() => {
      this.loadUser();
    }, 30000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  showUser() {
    if (
      !this.props.user ||
      !this.props.user.comercial ||
      !this.props.user.validtil
    ) {
      return <div> waiting api user results</div>;
    } else {
      let user = this.props.user;
      let validate = <div />;
      if (user.status === "registered") {
        validate = (
          <div>
            <button
              type="button"
              className="btn btn-danger btn-sm ml-2"
              onClick={(event) => this.handleResendClick()}
            >
              Resend validation email
            </button>
            <br />
          </div>
        );
      }
      let validity = moment(user.validtil).format("YYYY-MM-DD");
      let status = "";
      if (this.props.fromMenu === "SPOT") status = user.status;
      if (this.props.fromMenu === "FUTURES") status = user.futures.status;
      if (status.toUpperCase().includes("ON")) status = "status: ON";
      if (status.toUpperCase().includes("OFF")) status = "status: OFF";
      // let prdSelection = this.props.showSelection ? (
      //   <ProductSelection leader={this.state.leader} />
      // ) : (
      //   <div />
      // );
      return (
        <div className="container-fluid">
          <small>
            <div className="row bg-secondary text-white">
              <div className="col-md-2"> {user.username.substr(0, 24)}</div>
              <div className="col-md-3"> {user.email}</div>
              <div className="col-md-2">Validity: {validity}</div>
              <div className="col-md-2">
                {status}
                {validate}
              </div>
            </div>
            <ReactTooltip place="top" type="info" effect="float" />
          </small>
        </div>
      );
    }
  }

  nextSteps() {
    return (
      <div>
        Select menu Security on your left. Hover help (?) for instructions.
      </div>
    );
  }

  setActions(enabled, verifyMsg) {
    let msgIn = verifyMsg === "OK" || verifyMsg === "" ? "" : verifyMsg;
    let msg;
    if (msgIn !== "") {
      msg = (
        <div className="text-info" align="center">
          {msgIn}
        </div>
      );
      if (msgIn === "No Security API Keys configured.") {
        let nextSteps = this.nextSteps();
        msg = (
          <div className="text-info" align="center">
            {msgIn} {nextSteps}
          </div>
        );
      }
    }
    let btn = <div />;
    //   if (!this.props.showOnOff) btn = <div />;
    //   else if (this.props.user && this.props.user.status === "activeOn") {
    //     btn = (
    //       <div className="row" key="1">
    //         <div className="col-md-12" align="center">
    //           <br />
    //           <button
    //             type="button"
    //             id="stop"
    //             className="btn btn-sm btn-danger"
    //             align="center"
    //             disabled={!enabled && !this.state.activeBtn}
    //             onClick={(event) => this.handleBtnClick("stop")}
    //           >
    //             Turn OFF
    //           </button>
    //         </div>
    //       </div>
    //     );
    //   } else {
    //     btn = (
    //       <div className="row" key="2">
    //         <div className="col-md-12" align="center">
    //           <br />
    //           <button
    //             type="button"
    //             id="start"
    //             className="btn btn-sm btn-primary"
    //             align="center"
    //             disabled={!enabled && !this.state.activeBtn}
    //             onClick={(event) => this.handleBtnClick("start")}
    //           >
    //             Turn ON
    //           </button>
    //         </div>
    //       </div>
    //     );

    return [btn, msg];
  }

  handleBtnClick(action) {
    this.setState({ activeBtn: false });
    //let action = e.target.id === "start" ? "start" : "stop";
    try {
      var _this = this;
      axios({
        method: "post",
        baseURL: sysconfig.EXECUTER_URI,
        url: "/user/startstop",
        data: { email: this.props.user.email, action },
        headers: { "x-auth": this.props.token },
      })
        .then(function (response) {
          if (response.status === 200) {
            _this.props.selectUser(response.data);
            _this.setState({ activeBtn: true });
          }
        })
        .catch((err) => {
          _this.setState({
            msg: `Status: ${err.response.status} Error: ${err.response.data.message}`,
            activeBtn: true,
          });
          alert(
            `Status: ${err.response.status} Error: ${err.response.data.message}`
          );
        });
    } catch (e) {
      alert("Error: " + e);
    }
  }

  renderNoValidated(user) {
    return (
      <div className="container-fluid">
        <hr />
        {user}
        <br />
        <p className="text-center text-info">
          <b>{this.state.msg}</b>
        </p>
      </div>
    );
  }

  verifyUser() {
    let msg = "Waiting load user";
    if (this.props.user && this.props.user.email) {
      msg = "OK";
      if (roleConstrainter(this.props.user, "trader")) msg = "";
      if (this.props.user.monitor.length === 0) msg = "No Tokens configured";
      if (
        !roleConstrainter(this.props.user, "trader") &&
        (this.props.user.tk.length < 20 || this.props.user.sk.length < 20)
      )
        msg = "No Security API Keys configured.";
      if (this.props.user.status === "registered")
        msg = "User email not validated";
      if (this.props.user.validtil < new Date()) msg = "Plan validity expired";
    }
    return msg;
  }

  render() {
    let verifyMsg = this.verifyUser();
    let displayuser = this.showUser();
    if (this.props.user && this.props.user.status === "registered") {
      return this.renderNoValidated(displayuser);
    }
    const actions = this.setActions(verifyMsg === "OK", verifyMsg);
    //Even with noShow this component is used to keep token redux state for other components
    if (this.props.noShow) return <div />;
    else
      return (
        <div>
          {displayuser}
          {actions}
        </div>
      );
  }

  handleResendClick(event) {
    try {
      var _this = this;
      axios({
        method: "post",
        baseURL: sysconfig.EXECUTER_URI,
        url: "/user/resend",
        data: { userid: this.props.user._id },
        headers: { "x-auth": this.props.token },
      })
        .then(function (response) {
          if (response.status === 200) {
            _this.setState({ msg: "Please check your email" });
          }
        })
        .catch(function (error) {
          console.log(error);
          alert("Error: " + error.message);
        });
    } catch (e) {
      console.log(e);
    }
  }
}

function mapStateToProps(state) {
  //whatever is returned will show as props inside this container
  return {
    token: state.activeToken,
    user: state.activeUser,
  };
}

//anything returned from this function will become props on this container
function mapDispatchToProps(dispatch) {
  //whenever selectToken is called, the result will be passed to all reducers
  return bindActionCreators(
    { selectUser: selectUser, selectToken: selectToken },
    dispatch
  );
}

//promote Login from a component to a container with added props activeToken
export default connect(mapStateToProps, mapDispatchToProps)(User);
