import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Menu from "../components/menu";
import Footer from "../components/footer";
import FuturesList from "../containers/futuresList";
import FuturesConfig from "../containers/futuresConfig";
import FuturesSignalScreen from "./futuresSignalScreen";
import { roleConstrainter } from "../utils/clientConstraint";
import User from "./user";
import sysconfig from "../config";

function FuturesMenu(props) {
  const [config, setConfig] = useState(false);
  const [trades, setTrades] = useState(false);
  const [intro, setIntro] = useState(false);
  const [newsig, setNewSig] = useState(false);
  const user = useSelector((state) => state.activeUser);
  const token = useSelector((state) => state.activeToken);
  const partner = useSelector((state) => state.partner);

  const sideMenu = () => {
    let cpNew = <div />;
    let cpTrades = <div />;
    let cpConfig = <div />;

    if (roleConstrainter(user, "trader"))
      cpNew = (
        <div className="row" style={{ fontSize: "small" }}>
          <div className="col-md-12 row-bordered">
            <span
              className="btn-link cursor-pointer text-secondary"
              onClick={() => {
                setIntro(false);
                setNewSig(true);
                setConfig(false);
                setTrades(false);
              }}
            >
              Create
            </span>
          </div>
        </div>
      );

    cpTrades = (
      <div className="row" style={{ fontSize: "small" }}>
        <div className="col-md-12 row-bordered">
          <span
            className="btn-link cursor-pointer text-secondary"
            onClick={() => {
              setIntro(false);
              setNewSig(false);
              setConfig(false);
              setTrades(true);
            }}
          >
            Trade Report
          </span>
        </div>
      </div>
    );

    if (!roleConstrainter(user, "trader"))
      cpConfig = (
        <div className="row" style={{ fontSize: "small" }}>
          <div className="col-md-12 row-bordered">
            <span
              className="btn-link cursor-pointer text-secondary"
              onClick={() => {
                setIntro(false);
                setNewSig(false);
                setConfig(true);
                setTrades(false);
              }}
            >
              Settings
            </span>
          </div>
        </div>
      );

    let result = (
      <div className="col-md-1">
        <p className="mt-5">
          <span
            className="btn-link cursor-pointer text-dark"
            onClick={() => {
              setConfig(false);
              setNewSig(false);
              setTrades(false);
              setIntro(true);
            }}
          >
            FUTURES SIGNALS
          </span>
        </p>
        {cpNew}
        {cpTrades}
        {cpConfig}
      </div>
    );

    return result;
  };

  const introduction = () => {
    if (!intro) return <div />;
    let following = "You are not following a futures signals leader.";
    if (user.futures.delegate !== "none")
      following = `You are following ${user.futures.delegate} futures signals`;
    return (
      <div className="row">
        <div className="col-md-1" />
        <div className="col-md-11">
          <p className="text-secondary font-italic">
            {following} <br />
            At this space you are able to follow your partner leader operations.{" "}
            <br />
            Configure your preferences on Settings and check the operations on
            Trade Report. <br />
          </p>
        </div>
      </div>
    );
  };

  const render = () => {
    let role = user ? user.role : false;
    let sidemenu = sideMenu();
    let showIntroduction = introduction();
    let showSettings = config ? <FuturesConfig /> : <div />;
    let showSignalScreen = newsig ? <FuturesSignalScreen /> : <div />;

    let showTrades = trades ? (
      <FuturesList product="futures-signals" />
    ) : (
      <div />
    );

    let toRender = <div />;
    if (user && user.status !== "registered") {
      toRender = (
        <div className="row">
          {sidemenu}
          <div className="col-md-11">
            <br />
            {showIntroduction} {showSignalScreen} {showTrades} {showSettings}
          </div>
        </div>
      );
    }
    return (
      <span>
        <Menu logged={role} source={partner} user={user} />
        <div className="container-fluid">
          <User
            email={user.email}
            source=""
            showSelection={false}
            fromMenu="FUTURES"
          />
          {toRender}
        </div>
        <br />
        <br />
        <Footer />
      </span>
    );
  };
  if (!token) {
    window.location.replace(sysconfig.HOME_URL);
    return null;
  }
  return render();
}

//promote Login from a component to a container with added props activeToken
export default FuturesMenu;
