const groupConstrainter = function(user) {
  let result = false;
  if (user.general.delegate === "Criptomaniacos") result = true;
  return result;
};

const portfolioConstrainter = function(user) {
  let result = false;
  if (user.configPortf && user.configPortf.delegate === "Criptomaniacos") result = true;
  return result;
};

const roleConstrainter = function(user, role) {
  return user.role === role;
};

const groupAccountConstrainter = function(user) {
  return user.general.groupAccount === "Criptomaniacos";
};

exports.groupConstrainter = groupConstrainter;
exports.roleConstrainter = roleConstrainter;
exports.groupAccountConstrainter = groupAccountConstrainter;
exports.portfolioConstrainter = portfolioConstrainter;
