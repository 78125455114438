import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import validator from "validator";
import _ from "lodash";
import ReactTooltip from "react-tooltip";

import sysconfig from "../config";

function SignalScreen(props) {
  const [formData, setFormData] = useState({
    target1: "",
    target2: "",
    target3: "",
    percTarget1: "",
    percTarget2: "",
    percTarget3: "",
    percentEntry: "",
    entryBegin: "",
    entryEnd: "",
    stopLoss: "",
  });
  const [currPrice, setPrice] = useState("");
  const [symbol, setSymbol] = useState("");
  const [raiseStop, setRaiseStop] = useState("manual");
  const [btnSave, setBtnSave] = useState("Save");
  const [allowSave, setAllowSave] = useState(false);
  const [msg, setMsg] = useState("");
  const [tgt1Diff, setTgt1Diff] = useState("");
  const [tgt2Diff, setTgt2Diff] = useState("");
  const [tgt3Diff, setTgt3Diff] = useState("");
  const [slDiff, setSlDiff] = useState("");
  const user = useSelector((state) => state.activeUser);
  const token = useSelector((state) => state.activeToken);

  //equivalent to didMount
  useEffect(() => {
    //getSymbolPrice();
  }, []);

  const onInputChange = ({ target: { id, value } }) => {
    console.log("inputChange", id, value);
    let diff1 =
      id === "target1" ? calculate(formData.entryEnd, Number(value)) : tgt1Diff;
    let diff2 =
      id === "target2" ? calculate(formData.entryEnd, Number(value)) : tgt2Diff;
    let diff3 =
      id === "target3" ? calculate(formData.entryEnd, Number(value)) : tgt3Diff;
    let diffsl =
      id === "stopLoss" ? calculate(formData.entryEnd, Number(value)) : slDiff;
    if (id === "entryEnd") {
      let newEntry = Number(value);
      diff1 = calculate(newEntry, Number(formData.target1));
      diff2 = calculate(newEntry, Number(formData.target2));
      diff3 = calculate(newEntry, Number(formData.target3));
      diffsl = calculate(newEntry, Number(formData.stopLoss));
    }
    setFormData({ ...formData, [id]: value });
    setTgt1Diff(diff1);
    setTgt2Diff(diff2);
    setTgt3Diff(diff3);
    setSlDiff(diffsl);
  };

  const onSymbolChange = (e) => {
    setSymbol(e.target.value.toUpperCase());
    setPrice("");
  };

  const handleClearNumClick = () => {
    setFormData({
      ...formData,
      target1: "",
      target2: "",
      target3: "",
      percTarget1: "",
      percTarget2: "",
      percTarget3: "",
      percentEntry: "",
      entryBegin: "",
      entryEnd: "",
      stopLoss: "",
    });
  };

  const handleCheckChange = (event) => {
    if (event.target.id === "raiseStop") setRaiseStop(event.target.value);
  };

  const onAllowSave = () => {
    setFormData({
      entryBegin: !formData.entryBegin ? 0 : formData.entryBegin,
      entryEnd: !formData.entryEnd ? 0 : formData.entryEnd,
      percentEntry: !formData.percentEntry ? 0 : formData.percentEntry,
      target1: !formData.target1 ? 0 : formData.target1,
      target2: !formData.target2 ? 0 : formData.target2,
      target3: !formData.target3 ? 0 : formData.target3,
      percTarget1: !formData.percTarget1 ? 0 : formData.percTarget1,
      percTarget2: !formData.percTarget2 ? 0 : formData.percTarget2,
      percTarget3: !formData.percTarget3 ? 0 : formData.percTarget3,
      stopLoss: !formData.stopLoss ? 0 : formData.stopLoss,
    });
    setMsg("");
    setAllowSave(!allowSave);
  };

  const calculate = (entry, target) => {
    if (!target) return 0;
    if (Number(target) === 0) return 0;
    return (Number(target) - Number(entry)) / Number(entry);
  };

  const getSymbolPrice = () => {
    let url = `/usersymbol/symbolPrice?exchange=${user.exchange}&symbol=${symbol}`;
    axios({
      method: "get",
      baseURL: sysconfig.EXECUTER_URI,
      url,
      headers: { "x-auth": token },
    })
      .then(function (response) {
        if (response.data) {
          let entryB = _.round(
            Number(response.data.price) * (1 - 0.1 / 100),
            8,
          );
          let entryE = _.round(
            Number(response.data.price) * (1 + 0.4 / 100),
            8,
          );
          if (entryB.toString().includes("e")) {
            entryB = entryB.toFixed(8);
            entryE = entryE.toFixed(8);
          }
          setFormData({ ...formData, entryBegin: entryB, entryEnd: entryE });
          setPrice(_.round(Number(response.data.price), 4));
          setMsg("");
        }
      })
      .catch(function (error) {
        console.log("Err getSymbolPrice:", error);
      });
  };
  const setData = (action) => {
    let data = {
      action,
      userkey: user.userkey,
      loggedUser: user.username,
      email: user.email,
      product: "MANAGED",
      groupAccount: user.general.groupAccount,
      symbol: symbol,
      leverage: 1,
      position: "LONG",
      percentEntry: Number(formData.percentEntry),
      entryBegin: Number(formData.entryBegin),
      entryEnd: Number(formData.entryEnd),
      target1: Number(formData.target1),
      volume1: Number(formData.percTarget1),
      target2: formData.target2 ? Number(formData.target2) : 0,
      volume2: formData.percTarget2 ? Number(formData.percTarget2) : 0,
      target3: formData.target3 ? Number(formData.target3) : 0,
      volume3: formData.percTarget3 ? Number(formData.percTarget3) : 0,
      stopLoss: Number(formData.stopLoss),
      raiseStop: raiseStop,
    };
    return data;
  };

  const handleChangeClick = (action) => {
    setMsg("");
    setBtnSave("Saving ...");
    let validated = validate();
    if (validated !== "OK") {
      setBtnSave("Save");
      return alert(validated);
    }
    let data = setData("NEW");
    axios({
      method: "post",
      baseURL: sysconfig.EXECUTER_URI,
      url: "/signal/manager",
      data,
      headers: { "x-auth": token },
    })
      .then(function (response) {
        if (response.status === 200) {
          setMsg(`Saved`);
          setBtnSave("Save");
          setAllowSave(!allowSave);
        }
      })
      .catch(function (error) {
        alert("Err Signal: " + error.response.data.message);
        setAllowSave(!allowSave);
        setBtnSave("Save");
        setMsg(`Error ${error.response.data.message}`);
      });
  };

  const validate = () => {
    let result = "OK";
    for (const [key, value] of Object.entries(formData)) {
      if (!validator.isNumeric(value.toString())) return "Invalid Number field";
    }
    if (Number(formData.entryBegin) > Number(formData.entryEnd)) {
      return "Entry begin value must be smaller than end";
    }
    if (Number(formData.target1) < Number(formData.entryEnd)) {
      return "Target value must be greater than entry";
    }
    if (
      Number(formData.target2) !== 0 &&
      Number(formData.target2) <= Number(formData.target1)
    ) {
      return "Target 2 value must be greater than Target 1";
    }
    if (
      Number(formData.target3) !== 0 &&
      Number(formData.target3) <= Number(formData.target2)
    ) {
      return "Target 3 value must be greater than Target 2";
    }
    if (
      Number(formData.percTarget1) +
        Number(formData.percTarget2) +
        Number(formData.percTarget3) !==
      100
    ) {
      return "Volumes must sum 100%";
    }
    if (Number(formData.percTarget1) === 0)
      return "Exit volume 1 must be greater than zero";
    if (
      Number(formData.percTarget2) === 0 &&
      Number(formData.percTarget3) > 0
    ) {
      return "Volume 2 must be greater than zero if there is a volume 3";
    }
    if (Number(formData.stopLoss === 0)) return "Stop can not be zero";

    return result;
  };

  const entryRows = () => {
    return (
      <div>
        <div className="row">
          <div className="col-md-7">
            <br></br>
            <small
              className="font-weight-bold"
              data-tip="Trigger market buy order when price goes between this values."
            >
              Entry Range{" "}
            </small>
            <span
              className="btn-link cursor-pointer"
              onClick={handleClearNumClick}
            >
              <small> ( Clear )</small>
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <input
              type="text"
              className="form-control form-control-sm"
              id="entryBegin"
              placeholder="range begin"
              value={formData.entryBegin}
              onChange={onInputChange}
            />
          </div>
          <div className="col-md-2">
            <input
              type="text"
              className="form-control form-control-sm"
              id="entryEnd"
              placeholder="range end"
              value={formData.entryEnd}
              onChange={onInputChange}
            />
          </div>
          <div className="col-md-3">
            <input
              type="text"
              className="form-control form-control-sm"
              id="percentEntry"
              placeholder="entry percentage"
              value={formData.percentEntry}
              onChange={onInputChange}
            />
          </div>
        </div>
      </div>
    );
  };

  const profitRows = () => {
    return (
      <div>
        <div className="row">
          <div className="col-md-4">
            <br />
            <small
              className="font-weight-bold"
              data-tip="Trigger buy order when price goes above this value."
            >
              Take Profit #1
            </small>{" "}
            <input
              type="text"
              className="form-control form-control-sm"
              id="target1"
              placeholder="target 1 value"
              value={formData.target1}
              onChange={onInputChange}
            />
          </div>
          <div className="col-md-3">
            <br />
            <small
              className="font-weight-bold"
              data-tip="Percentage of amount bought to exit on target 1."
            >
              Volume (%)
            </small>{" "}
            <input
              type="text"
              className="form-control form-control-sm"
              id="percTarget1"
              placeholder="% of the bought volume"
              value={formData.percTarget1}
              onChange={onInputChange}
            />
          </div>
          <div className="col-md-2">
            <br />
            <br />
            <small>{(tgt1Diff * 100).toFixed(2)}%</small>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <br />
            <small className="font-weight-bold">Take Profit #2</small>{" "}
            <input
              type="text"
              className="form-control form-control-sm"
              id="target2"
              placeholder="target 2 value"
              value={formData.target2}
              onChange={onInputChange}
            />
          </div>
          <div className="col-md-3">
            <br />
            <small className="font-weight-bold">Volume (%)</small>{" "}
            <input
              type="text"
              className="form-control form-control-sm"
              id="percTarget2"
              placeholder="% of the bought volume"
              value={formData.percTarget2}
              onChange={onInputChange}
            />
          </div>
          <div className="col-md-2">
            <br />
            <br />
            <small>{(tgt2Diff * 100).toFixed(2)}%</small>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <br />
            <small className="font-weight-bold">Take Profit #3</small>{" "}
            <input
              type="text"
              className="form-control form-control-sm"
              id="target3"
              placeholder="target 3 value"
              value={formData.target3}
              onChange={onInputChange}
            />
          </div>
          <div className="col-md-3">
            <br />
            <small className="font-weight-bold">Volume (%)</small>{" "}
            <input
              type="text"
              className="form-control form-control-sm"
              id="percTarget3"
              placeholder="% of the bought volume"
              value={formData.percTarget3}
              onChange={onInputChange}
            />
          </div>
          <div className="col-md-2">
            <br />
            <br />
            <small>{(tgt3Diff * 100).toFixed(2)}%</small>
          </div>
        </div>
      </div>
    );
  };

  const stopRows = () => {
    return (
      <div>
        <div className="row">
          <div className="col-md-4" align="left">
            <br />
            <small
              className="font-weight-bold"
              data-tip="Trigger market buy order when price goes below this value."
            >
              Stop Loss
            </small>
            <input
              type="text"
              className="form-control form-control-sm"
              id="stopLoss"
              placeholder="stop loss value"
              value={formData.stopLoss}
              onChange={onInputChange}
            />
          </div>
          <div className="col-md-3" align="left">
            <br />
            <small
              className="font-weight-bold"
              data-tip="Trigger market buy order when price goes below this value."
            >
              Stop Mode
            </small>
            <select
              className="custom-select custom-select-sm mt-1"
              id="raiseStop"
              onChange={handleCheckChange}
            >
              <option
                id="manual"
                value="manual"
                className="custom-control-input"
                selected={raiseStop === "manual"}
              >
                Manual
              </option>
              <option
                id="phased"
                value="phased"
                className="custom-control-input"
                selected={raiseStop === "phased"}
              >
                Phased
              </option>
              <option
                id="phased2"
                value="phased2"
                className="custom-control-input"
                selected={raiseStop === "phased2"}
              >
                Phased II
              </option>
            </select>
          </div>
          <div className="col-md-2">
            <br />
            <br />
            <small>{(slDiff * 100).toFixed(2)}%</small>
          </div>
        </div>
      </div>
    );
  };

  const formFiller = () => {
    let _entryRows = entryRows();
    let _profitRows = profitRows();
    let _stopRows = stopRows();
    return (
      <div className="col-md-10" align="left">
        <div className="row">
          <div className="col-md-4">
            <br />
            <small className="font-weight-bold">Symbol</small>{" "}
            <input
              type="text"
              className="form-control form-control-sm"
              id="symbol"
              placeholder="Symbol"
              value={symbol}
              onChange={onSymbolChange}
            />
          </div>
          <div className="col-md-3">
            <br />
            <small className="font-weight-bold">Price </small>
            <span className="btn-link cursor-pointer" onClick={getSymbolPrice}>
              <small>( Load )</small>
            </span>
            <br></br>
            <label>{currPrice}</label>
          </div>
        </div>
        {_entryRows}
        {_profitRows}
        {_stopRows}

        <div className="row">
          <div className="col-md-4" align="left">
            <br></br>
            <button
              type="button"
              className="btn btn-primary btn-sm cursor-pointer"
              id="save"
              disabled={!allowSave}
              onClick={handleChangeClick}
            >
              {btnSave}
            </button>
            <p>{msg}</p>
          </div>
          <div className="col-md-3" align="left">
            <br></br>
            <span className="col-md-2">
              <input
                className="form-check-input"
                type="checkbox"
                value="allowSave"
                id="allowSave"
                name="forcedStop"
                checked={allowSave}
                onChange={onAllowSave}
              />
              <label className="form-check-label">Confirm to Save</label>
              <br />
            </span>
          </div>
        </div>
      </div>
    );
  };

  let _formFiller = formFiller();

  return _formFiller;
}

export default SignalScreen;
