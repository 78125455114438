import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { selectUser } from "../actions/root";
import Menu from "../components/menu";
import Footer from "../components/footer";
//import Plans from "./plans";
import User from "./user";
import Security from "./security";
import BalanceReport from "./balancereport";
import BalanceHistory from "./balanceHistory";
import { groupConstrainter, roleConstrainter } from "../utils/clientConstraint";

class AccountMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msg: "",
      mhist: false,
      msecure: false,
      mbal: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user !== prevState.user) {
      return { user: nextProps.user };
    } else return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.msecure && prevProps.user !== this.props.user) {
      let msecure = false;
      if (
        this.props.user &&
        this.props.user.tk === "" &&
        !roleConstrainter(this.props.user, "trader") &&
        groupConstrainter(this.props.user)
      ) {
        msecure = true;
      }
      this.setState({ msecure });
    }
  }

  sideMenu() {
    return (
      <div className="col-md-1">
        <p className="mt-2">
          <font>ACCOUNT</font>
        </p>
        <div className="row" style={{ fontSize: "small" }}>
          <div className="col-md-12 row-bordered">
            <span
              className="btn-link cursor-pointer text-secondary"
              onClick={() =>
                this.setState({
                  mhist: false,
                  msecure: true,
                  mbal: false,
                })
              }
            >
              Security
            </span>
          </div>
        </div>
        <div className="row" style={{ fontSize: "small" }}>
          <div className="col-md-12 row-bordered">
            <span
              className="btn-link cursor-pointer text-secondary"
              onClick={() =>
                this.setState({
                  mhist: false,
                  msecure: false,
                  mbal: true,
                })
              }
            >
              Balance
            </span>
          </div>
        </div>
        <div className="row" style={{ fontSize: "small" }}>
          <div className="col-md-12 row-bordered">
            <span
              className="btn-link cursor-pointer text-secondary"
              onClick={() =>
                this.setState({
                  mhist: true,
                  msecure: false,
                  mbal: false,
                })
              }
            >
              History
            </span>
          </div>
        </div>
      </div>
    );
  }

  render() {
    let role = false;
    let groupAccount = "";
    let liderConstraint1 = false;
    if (this.props.user && this.props.user.general.delegate)
      liderConstraint1 = groupConstrainter(this.props.user);
    if (this.props.user) {
      role = this.props.user.role;
      if (this.props.user.general.groupAccount)
        groupAccount = this.props.user.general.groupAccount;
    }
    let showhist = <div />;
    showhist =
      this.state.mhist && !roleConstrainter(this.props.user, "trader") ? (
        <BalanceHistory />
      ) : (
        <div />
      );
    let showsecure = this.state.msecure ? <Security /> : <div />;
    let showbalance =
      this.state.mbal && !groupAccount ? <BalanceReport /> : <div />;
    let sidemenu = this.sideMenu();
    let toRender = <div />;
    if (this.props.user && this.props.user.status !== "registered") {
      toRender = (
        <div className="row">
          {sidemenu}
          <div className="col-md-11">
            {showsecure}
            {showbalance}
            {showhist}
          </div>
        </div>
      );
    }
    return (
      <span>
        <Menu
          logged={role}
          source={this.props.partner}
          user={this.props.user}
        />
        <div className="container-fluid">
          <User
            email={this.props.email}
            source={this.props.partner}
            showOnOff={false}
          />
          <hr />
          {toRender}
        </div>
        <br />
        <br />
        <Footer />
      </span>
    );
  }
}

function mapStateToProps(state) {
  //whatever is returned will show as props inside this container
  return {
    token: state.activeToken,
    user: state.activeUser,
    partner: state.activePartner,
  };
}

//anything returned from this function will become props on this container
function mapDispatchToProps(dispatch) {
  //whenever selectToken is called, the result will be passed to all reducers
  return bindActionCreators({ selectUser: selectUser }, dispatch);
}

//promote Login from a component to a container with added props activeToken
export default connect(mapStateToProps, mapDispatchToProps)(AccountMenu);
