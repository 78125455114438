import React from "react";
import { Link } from "react-router-dom";
import {
  groupAccountConstrainter,
  groupConstrainter,
  roleConstrainter,
} from "../utils/clientConstraint";

const Menu = (props) => {
  let image;
  let partnerLink = <span />;
  let support = <span />;
  if (props.source === "criptomaniacos") {
    image = (
      <img id="logo" src="/images/criptomaniacos.svg" height="75" width="250" />
    );
  }
  if (props.user && props.user.general.delegate === "Criptomaniacos") {
    support = (
      <a
        target="_blank"
        className="text-white-50 mr-3"
        href="https://www.criptomaniacos.io/postagem/223/fale-com-o-suporte-da-criptomaniacos"
      >
        Support
      </a>
    );
  }
  if (props.source === "dayoftrade") {
    image = (
      <img
        id="logo"
        src="/images/dayoftradelogo1.png"
        height="75"
        width="150"
      />
    );
  }
  var display;
  if (props.logged) {
    let automation;
    //if (!groupConstrainter(props.user))
    automation = (
      <Link className="text-white-50 mr-3" to={"/automation"}>
        IntelliBots
      </Link>
    );
    let selectedMenus = (
      <span>
        <Link className="text-white-50 mr-3" to="/signals">
          Spot
        </Link>
        <Link className="text-white-50 mr-3" to="/futuresMenu">
          Futures
        </Link>
        <Link className="text-white-50 mr-3" to="/portfolio">
          Portfolio
        </Link>
        {automation}
      </span>
    );
    if (
      props.user &&
      (roleConstrainter(props.user, "trader") ||
        roleConstrainter(props.user, "admin"))
    ) {
      selectedMenus = (
        <span>
          <Link className="text-white-50 mr-3" to="/signals">
            Spot
          </Link>
          <Link className="text-white-50 mr-3" to="/futuresMenu">
            Futures
          </Link>
          <Link className="text-white-50 mr-3" to="/portfolio">
            Portfolio
          </Link>
          <Link className="text-white-50 mr-3" to={"/automation"}>
            IntelliBots
          </Link>
          <Link className="text-white-50 mr-3" to="/reports">
            Reports
          </Link>
        </span>
      );
      if (groupAccountConstrainter(props.user)) {
        selectedMenus = (
          <span>
            <Link className="text-white-50 mr-3" to="/signals">
              Spot
            </Link>
            <Link className="text-white-50 mr-3" to="/futuresMenu">
              Futures
            </Link>
            <Link className="text-white-50 mr-3" to="/portfolio">
              Portfolio
            </Link>
            <Link className="text-white-50 mr-3" to={"/automation"}>
              IntelliBots
            </Link>
          </span>
        );
      }
    }

    display = (
      <div className="p-2 mb-2 menu-div">
        <div className="row">
          <div className="col-md-2" align="right">
            {image}
          </div>
          <div className="col-md-10">
            <div className="text-right">
              <Link className="text-white-50 mr-3" to="/">
                Account
              </Link>
              {selectedMenus}
              {support}
              <Link className="text-white-50 mr-3" to={"/signout"}>
                Sign-out
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    display = (
      <div className="p-2 mb-2 menu-div">
        <div className="row">
          <div className="col-md-2">{image}</div>
          <div className="col-md-10">
            <div className="text-right">
              <br />
              <Link className="text-white-50 mr-3" to="/">
                Login
              </Link>
              {partnerLink}
            </div>
          </div>
        </div>
      </div>
    );
  }
  // <Link className="text-white-50 mr-3" to={"/signup"}>
  //   Sign-up
  // </Link>
  return display;
};

export default Menu;
