import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import axios from "axios";

import sysconfig from "../config";
import { roleConstrainter } from "../utils/clientConstraint";

class TradeOrder extends Component {
  constructor(props) {
    super(props);
    this.onInputChange = this.onInputChange.bind(this);
    this.onSymbolChange = this.onSymbolChange.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.handleCheckChange = this.handleCheckChange.bind(this);
    this.onQtyClick = this.onQtyClick.bind(this);
    this.getBalance = this.getBalance.bind(this);
    this.state = {
      orderType: "Market",
      direction: "Buy",
      tradeMarket: "spot",
      btnclass: "btn btn-success btn-sm cursor-pointer mt-1",
      msg: "",
      partner: "",
      symbol: "",
      btnDisable: true,
    };
  }

  getSymbolPrice(exchange, symbol) {
    let url = `/usersymbol/symbolPrice?exchange=${exchange}&symbol=${symbol}`;
    var _this = this;
    axios({
      method: "get",
      baseURL: sysconfig.EXECUTER_URI,
      url,
      headers: { "x-auth": this.props.token },
    })
      .then(function (response) {
        if (response.data) {
          _this.setState({
            currPrice: Number(response.data.price),
            price: Number(response.data.price),
            msg: "",
          });
        }
      })
      .catch(function (error) {
        console.log("Err getSymbolPrice:", error);
      });
  }

  render() {
    let role = this.props.user ? this.props.user.role : false;
    let orderButton = this.orderButton();
    let orderQty = this.orderQty();
    let selector = this.selector();
    let marketSelector = this.marketSelector();
    let symbolSelector = this.symbolSelector();
    let balance = this.showBalance();
    let orderValue = this.orderValue();
    let inputFollower = this.inputFollower();

    return (
      <div
        className="bg-white text-black"
        align="left"
        style={{ fontSize: "small" }}
      >
        {balance}
        <div>{symbolSelector}</div>
        <div className="row">
          <div className="col-md-2">{marketSelector}</div>
          <div className="col-md-3">
            {selector}
            <p className="text-danger">
              <span className="ml-1">{this.state.msg}</span>
            </p>
          </div>
          <div className="col-md-3">{orderValue}</div>
          <div className="col-md-3">{orderQty}</div>
        </div>
        <div className="row">
          <div className="col-md-8"> {inputFollower}</div>
          <div className="col-md-3"> {orderButton} </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    // this.refresh(this.state.tradeMarket.toUpperCase());
    // if (this.props.user && this.props.symbol && this.state.btnDisable) {
    //   this.setState({ btnDisable: false });
    // }
  }

  refresh(tradeMarket) {
    this.getBalance(tradeMarket);
    this.getSymbolPrice(this.props.user.exchange, this.state.symbol);
  }

  inputFollower() {
    let follower = <div />;
    if (
      roleConstrainter(this.props.user, "trader") ||
      roleConstrainter(this.props.user, "admin")
    ) {
      follower = (
        <div className="col-md-8" align="center">
          <br />
          <input
            type="text"
            className="form-control form-control-sm"
            id="follower"
            placeholder="Email of follower to apply"
            value={this.state.follower}
            onChange={this.onInputChange}
          />
        </div>
      );
    }
    return follower;
  }

  showBalance() {
    if (this.state.symbol && this.state.tradeMarket && this.state.token) {
      let partner = <span />;
      if (roleConstrainter(this.props.user, "admin")) {
        partner = (
          <input
            className="form-control form-control-sm"
            id="partner"
            placeholder="partner username"
            value={this.state.partner}
            onChange={this.onInputChange}
          />
        );
      }
      let token = Number(this.state.token.free);
      if (this.state.tradeMarket === "futures")
        token = token * Number(this.state.token.leverage);
      let market = Number(this.state.market.free).toFixed(4);
      if (this.state.market.asset !== "BTC") {
        //then it is a USD market
        market = Number(this.state.market.free).toFixed(2);
      }
      // let index = this.props.user.monitor.findIndex(
      //   (doc) => doc.symbol === this.props.symbol,
      // );

      return (
        <div className="row">
          <div className="col-md-5">
            <span className="ml-1">
              Balance: Token {this.state.token.asset}:{" "}
              {Number(token).toFixed(8)}
              {partner}
            </span>
          </div>
          <div className="col-md-3">
            Market {this.state.market.asset}: {market}{" "}
          </div>
          <div className="col-md-2" align="center">
            <img
              id="btntk"
              src="/images/reload.png"
              height="25"
              width="25"
              alt="toggle view"
              className="cursor-pointer"
              onClick={(event) =>
                this.refresh(this.state.tradeMarket.toUpperCase())
              }
            />
          </div>
        </div>
      );
    }
  }

  orderQty() {
    let msg = "";
    let qtyPlaceholder;
    if (this.state.token) {
      msg = `Buy ${this.state.token.asset} with ${this.state.market.asset}`;
      qtyPlaceholder = `Quantity of ${this.state.token.asset}`;
      if (this.state.direction === "Sell") {
        msg = `Sell ${this.state.token.asset} to ${this.state.market.asset}`;
        qtyPlaceholder = `Quantity of ${this.state.token.asset}`;
      }
    }
    return (
      <div className="row">
        <div className="col-md-12 mt-1" align="center">
          <small> {msg} </small>
          <input
            type="text"
            className="form-control form-control-sm"
            id="quantity"
            placeholder={qtyPlaceholder}
            value={this.state.quantity}
            onChange={this.onInputChange}
          />
          <small>
            <span
              className="btn-link cursor-pointer"
              onClick={(event) => this.onQtyClick(25)}
            >
              25%{" "}
            </span>
            <span
              className="btn-link cursor-pointer"
              onClick={(event) => this.onQtyClick(50)}
            >
              50%{" "}
            </span>
            <span
              className="btn-link cursor-pointer"
              onClick={(event) => this.onQtyClick(75)}
            >
              75%{" "}
            </span>
            <span
              className="btn-link cursor-pointer"
              onClick={(event) => this.onQtyClick(100)}
            >
              100%{" "}
            </span>
          </small>
        </div>
      </div>
    );
  }

  orderValue() {
    let pricePlaceholder;
    let limit = <div />;
    if (this.state.token) {
      pricePlaceholder = `Trigger price in ${this.state.market.asset}`;
      if (this.state.direction === "Sell") {
        pricePlaceholder = `Trigger price in ${this.state.token.asset}`;
      }
      if (this.state.orderType === "Market") {
        return (
          <div className="col-md-12 mt-1">
            <small>
              {" "}
              Aproximate price <br />
              {this.state.price}{" "}
            </small>
          </div>
        );
      }
      limit = (
        <div className="row">
          <div className="col-md-12 mt-1" align="center">
            <small> {pricePlaceholder} </small>
            <input
              type="text"
              className="form-control form-control-sm"
              id="price"
              placeholder={pricePlaceholder}
              value={this.state.price}
              onChange={this.onInputChange}
            />
          </div>
        </div>
      );
    }
    return limit;
  }

  orderButton() {
    return (
      <div className="row">
        <div className="col-md-12" align="center">
          <br />
          <button
            type="button"
            className={this.state.btnclass}
            id="order"
            disabled={this.state.btnDisable}
            onClick={(event) => this.handleOrderClick()}
          >
            {this.state.orderType} {this.state.direction}
          </button>
        </div>
      </div>
    );
  }

  symbolSelector() {
    return (
      <div className="row">
        <div className="col-md-2">
          <input
            type="text"
            className="form-control form-control-sm"
            id="symbol"
            placeholder="Symbol to buy/sell"
            value={this.state.symbol}
            onChange={this.onSymbolChange}
          />
        </div>
        <div className="col-md-1">
          <button
            type="button"
            className="btn btn-outline-primary btn-sm cursor-pointer"
            id="balance"
            onClick={(event) =>
              this.refresh(this.state.tradeMarket.toUpperCase())
            }
          >
            Balance
          </button>
        </div>
      </div>
    );
  }

  marketSelector() {
    return (
      <div className="row mt-1 ml-2">
        <div className="col-md-12" align="left">
          <input
            className="form-check-input"
            type="radio"
            id="checkSpot"
            value="spot"
            checked={this.state.tradeMarket !== "futures"}
            onChange={this.handleCheckChange}
          />
          <label className="form-check-label">Spot</label>
          <br />
          <input
            className="form-check-input"
            type="radio"
            id="checkFutures"
            value="futures"
            disabled
            checked={this.state.tradeMarket === "futures"}
            onChange={this.handleCheckChange}
          />
          <label className="form-check-label">Futures</label>
        </div>{" "}
      </div>
    );
  }

  selector() {
    return (
      <div className="row">
        <div className="col-md-6 mt-1" align="center">
          <div className="form-check ml-1" align="left">
            <input
              className="form-check-input"
              name="selector"
              id="Market"
              type="radio"
              value="Market"
              checked={this.state.orderType === "Market"}
              onChange={this.handleOptionChange}
            />
            <label className="form-check-label">Market</label>
            <br />
            <input
              className="form-check-input"
              name="selector"
              id="Limit"
              type="radio"
              value="Limit"
              disabled={this.state.tradeMarket === "futures"}
              checked={this.state.orderType === "Limit"}
              onChange={this.handleOptionChange}
            />
            <label className="form-check-label">Limit</label>
          </div>
        </div>
        <div className="col-md-6 mt-1" align="center">
          <div className="form-check" align="left">
            <input
              className="form-check-input"
              name="direction"
              id="Buy"
              type="radio"
              value="Buy"
              checked={this.state.direction === "Buy"}
              onChange={this.handleOptionChange}
            />
            <label className="form-check-label">Buy</label>
            <br />
            <input
              className="form-check-input"
              name="direction"
              id="Sell"
              type="radio"
              value="Sell"
              checked={this.state.direction === "Sell"}
              onChange={this.handleOptionChange}
            />
            <label className="form-check-label">Sell</label>
          </div>
        </div>
      </div>
    );
  }

  onQtyClick(value) {
    let qty;
    let direction = this.state.direction;
    if (direction === "Buy") {
      if (this.state.tradeMarket === "futures") {
        if (Number(this.state.token.free) === 0)
          qty =
            ((Number(this.state.market.free) / Number(this.state.price)) *
              value) /
            100;
        else
          qty = Math.abs(
            (Number(this.state.token.free) *
              Number(this.state.token.leverage) *
              value) /
              100,
          );
      } else {
        qty = (Number(this.state.market.free) * value) / 100;
        qty = qty / Number(this.state.price);
      }
      this.setState({ quantity: qty.toFixed(8), qtysell: 0 });
    }
    if (direction === "Sell") {
      if (this.state.tradeMarket === "futures") {
        if (Number(this.state.token.free) === 0)
          qty =
            ((Number(this.state.market.free) / Number(this.state.price)) *
              value) /
            100;
        else
          qty = Math.abs(
            (Number(this.state.token.free) *
              Number(this.state.token.leverage) *
              value) /
              100,
          );
      } else qty = (Number(this.state.market.free) * value) / 100;

      this.setState({ quantity: qty.toFixed(8), qtybuy: 0 });
    }
  }

  handleCheckChange(changeEvent) {
    let tradeMarket = changeEvent.target.value;
    let orderType = this.state.orderType;
    if (this.props && this.state.symbol.slice(-3) === "BTC")
      tradeMarket = "spot";
    if (tradeMarket === "futures") orderType = "Market";
    this.setState({
      tradeMarket,
      orderType,
    });
    this.refresh(tradeMarket);
  }

  handleOptionChange(changeEvent) {
    if (["Limit", "Market"].indexOf(changeEvent.target.id) !== -1) {
      this.setState({ orderType: changeEvent.target.value, quantity: "" });
    }
    let direction = changeEvent.target.id;
    if (direction === "Buy") {
      this.setState({
        direction,
        btnclass: "btn btn-success btn-sm cursor-pointer mt-1",
        quantity: "",
      });
    }
    if (direction === "Sell") {
      this.setState({
        direction,
        btnclass: "btn btn-danger btn-sm cursor-pointer mt-1",
        quantity: "",
      });
    }
  }

  getBalance(tradeMarket) {
    try {
      let email = this.state.follower
        ? this.state.follower
        : this.props.user.email;
      let data = {
        email,
        symbol: this.state.symbol,
        tradeMarket: tradeMarket.toUpperCase(),
      };
      var _this = this;
      axios({
        method: "post",
        baseURL: sysconfig.EXECUTER_URI,
        url: "/balance/getSymbol",
        data,
        headers: { "x-auth": this.props.token },
      })
        .then(function (response) {
          if (response.status === 200) {
            _this.setState({
              token: response.data.token,
              market: response.data.market,
              btnDisable: false,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
          alert("Error: " + error.response.data.message);
        });
    } catch (e) {
      console.log(e);
    }
  }

  validate() {
    let msg = "OK";
    if (this.state.direction === "Sell") {
      if (this.state.orderType === "Limit") {
        if (Number(this.state.price) < Number(this.state.currPrice)) {
          msg = "Sell Limit price must be higher than current price";
        }
      }
    }
    if (this.state.direction === "Buy") {
      if (this.state.orderType === "Limit") {
        if (Number(this.state.price) > Number(this.state.currPrice)) {
          msg = "Buy Limit price must be lower than current price";
        }
      }
    }
    return msg;
  }

  handleOrderClick(order) {
    try {
      let validated = this.validate();
      if (validated !== "OK") return alert(validated);
      this.setState({ msg: "", btnDisable: true });
      let price = Number(this.state.price);
      let email = this.state.follower
        ? this.state.follower
        : this.props.user.email;
      let data = {
        exchange: this.props.user.exchange,
        lider: roleConstrainter(this.props.user, "trader")
          ? this.props.user.email
          : "",
        email,
        partner: this.state.partner,
        symbol: this.state.symbol,
        order: this.state.direction.toLowerCase(),
        type: this.state.orderType.toLowerCase(),
        tradeMarket: this.state.tradeMarket.toUpperCase(),
        quantity: Number(this.state.quantity),
        price,
      };
      let msg = `${this.state.orderType} ${this.state.direction} order created`;
      if (
        roleConstrainter(this.props.user, "trader") &&
        this.state.orderType === "Market" &&
        email === this.props.user.email
      ) {
        msg += " and sent to all followers";
      }
      var _this = this;
      axios({
        method: "post",
        baseURL: sysconfig.PARSER_URI,
        url: "/user/terminalorder",
        data,
        headers: { "x-auth": this.props.token },
      })
        .then(function (response) {
          if (response.status === 200) {
            _this.getBalance(_this.state.tradeMarket.toUpperCase());
            _this.setState({ msg, price: "", quantity: "", btnDisable: false });
          }
        })
        .catch(function (error) {
          _this.setState({
            msg: `Error ${error.response.data}`,
            price: "",
            quantity: "",
            btnDisable: false,
          });
          alert(`Error ${error.response.status}: ${error.response.statusText}`);
        });
    } catch (e) {
      console.log(e);
    }
  }

  onSymbolChange = (event) => {
    this.setState({
      symbol: event.target.value.toUpperCase().trim(),
    });
  };

  onInputChange(event) {
    this.setState({
      [event.target.id]: event.target.value.trim().replace(",", "."),
    });
  }
}

function mapStateToProps(state) {
  //whatever is returned will show as props inside this container
  return {
    user: state.activeUser,
    token: state.activeToken,
  };
}

//promote Login from a component to a container with added props activeToken
export default connect(mapStateToProps)(TradeOrder);
