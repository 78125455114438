import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import _ from "lodash";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { roleConstrainter } from "../utils/clientConstraint";
import sysconfig from "../config";
import FuturesListDetail from "./futuresListDetail";

function FuturesList(props) {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [trades, setTrades] = useState([]);
  const [btnEnabled, setBtnEnabled] = useState(true);
  const [tradeList, setTradeList] = useState("hide");
  const [emailList, setEmailList] = useState("");
  const [msg, setMsg] = useState("");
  const user = useSelector((state) => state.activeUser);
  const token = useSelector((state) => state.activeToken);

  //equivalent to didMount
  useEffect(() => {
    if (
      user &&
      user.role === "user" &&
      (!user.futures || user.futures.status !== "ON")
    ) {
      setMsg("contact@aperium.io to enable Futures");
      setBtnEnabled(false);
    }
  }, []);

  const handleEmailChange = (event) => {
    setEmailList(event.target.value);
  };

  let _emailList = <div />;
  if (roleConstrainter(user, "trader") || roleConstrainter(user, "admin")) {
    _emailList = (
      <div className="col-md-3">
        <textarea
          className="form-control"
          id="emailList"
          rows="3"
          placeholder="Comma separated client emails"
          value={emailList}
          onChange={handleEmailChange}
        />
      </div>
    );
  }

  const showFilters = () => {
    let details;
    if (user) {
      details = (
        <div>
          <div className="row">
            <div className="col-md-6">
              <label>
                <b>Futures Orders</b>
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <label>
                <b>Start Date</b>
              </label>
            </div>
            <div className="col-md-3">
              <label>
                <b>End Date</b>
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <DatePicker
                id="startDate"
                selected={startDate}
                value={startDate}
                onChange={handleStartDateChange}
              />
            </div>
            <div className="col-md-3">
              <DatePicker
                id="endDate"
                selected={endDate}
                value={endDate}
                onChange={handleEndDateChange}
              />
            </div>
            {_emailList}
          </div>
          <br />
          {btns()}
        </div>
      );
    }
    return details;
  };

  const btns = () => {
    let btns = (
      <div className="col-md-4">
        <button
          type="button"
          className="btn btn-primary btn-sm cursor-pointer"
          id="generate"
          disabled={!btnEnabled}
          onClick={handleBtnClick}
        >
          Generate
        </button>

        <p className="text-info"> {msg}</p>
      </div>
    );
    return btns;
  };

  const listOrders = () => {
    let result = <div />;
    if (tradeList === "show") {
      result = <FuturesListDetail sendData={getData} trades={trades} />;
    }
    return result;
  };

  const handleBtnClick = (event) => {
    try {
      axios({
        method: "post",
        baseURL: sysconfig.EXECUTER_URI,
        url: "/futures/traderOrders",
        data: {
          username: user.username,
          orderSource: "futsignal",
          emailList: emailList
            .replace(";", ",")
            .replace("/", ",")
            .replace(" ", ""),
          startDate,
          endDate,
        },
        headers: { "x-auth": token },
      })
        .then(function (response) {
          if (response.data) {
            setTrades(response.data);
            setBtnEnabled(false);
            setTradeList("show");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleStartDateChange = (changeEvent) => {
    //date.toLocaleDateString("en-US")
    setStartDate(changeEvent);
  };

  const handleEndDateChange = (changeEvent) => {
    //date.toLocaleDateString("en-US")
    setEndDate(changeEvent);
  };

  const getData = (val) => {
    //receive data from child component
    if (val === "hide") {
      setTradeList("hide");
      setBtnEnabled(true);
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12"> {showFilters()}</div>
      </div>
      <div className="row">
        <div className="col-md-12"> {listOrders()}</div>
      </div>
    </div>
  );
}

export default FuturesList;
