import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import validator from "validator";
import _ from "lodash";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import sysconfig from "../config";
import SignalTradesDetail from "../components/signalTradesDetail";

class SignalTrades extends Component {
  constructor(props) {
    super(props);
    this.handleBtnClick = this.handleBtnClick.bind(this);
    this.handleCheckChange = this.handleCheckChange.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.getData = this.getData.bind(this);
    let startDate = new Date();
    let endDate = new Date();
    //startDate = startDate.toLocaleDateString("en-US");
    this.state = {
      trades: [],
      startDate,
      endDate,
      perPage: 50,
      tradelist: "show",
      mode: "real",
      btnEnabled: true,
    };
    // var _this = this;
    // axios({
    //   method: "get",
    //   baseURL: sysconfig.EXECUTER_URI,
    //   url: "/getsetting?exchange=" + this.props.user.exchange
    // })
    //   .then(function(response) {
    //     if (response.data) {
    //       _this.setState({ settings: response.data });
    //     }
    //   })
    //   .catch(function(error) {
    //     console.log(error);
    //   });
  }

  showFilters() {
    let test = this.state.mode === "test";
    let real = this.state.mode === "real";
    let btnLine = this.btns();
    let details;
    if (this.props.user) {
      details = (
        <div>
          <div className="row">
            <div className="col-md-6">
              <label>
                <b>Trade Report</b>
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <label>
                <b>Start Date</b>
              </label>
            </div>
            <div className="col-md-3">
              <label>
                <b>End Date</b>
              </label>
            </div>
            <div className="col-md-2">
              <label>
                <b>Max rows</b>
              </label>
            </div>
            <div className="col-md-2">
              <label>
                <b>Mode</b>
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <DatePicker
                id="startDate"
                selected={this.state.startDate}
                value={this.state.startDate}
                onChange={this.handleStartDateChange}
              />
            </div>
            <div className="col-md-3">
              <DatePicker
                id="endDate"
                selected={this.state.endDate}
                value={this.state.endDate}
                onChange={this.handleEndDateChange}
              />
            </div>
            <div className="col-md-2">
              <input
                className="form-control"
                id="perPage"
                placeholder="number of rows"
                value={this.state.perPage}
                onChange={this.onInputChange}
              />
            </div>
            <div className="form-group col-md-2 p-1">
              <span className="border border-secondary p-2">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="checkTest"
                    value="test"
                    checked={test}
                    onChange={this.handleCheckChange}
                  />
                  <label className="form-check-label">test</label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="checkReal"
                    value="real"
                    checked={real}
                    onChange={this.handleCheckChange}
                  />
                  <label className="form-check-label">real</label>
                </div>
              </span>
            </div>
            {btnLine}
          </div>
        </div>
      );
    }
    return details;
  }

  btns() {
    let btns = (
      <div className="col-md-1">
        <button
          type="button"
          className="btn btn-primary btn-sm cursor-pointer"
          id="generate"
          disabled={!this.state.btnEnabled}
          onClick={(event) => this.handleBtnClick(event)}
        >
          Generate
        </button>

        <p className="text-info"> {this.state.msg}</p>
      </div>
    );
    return btns;
  }

  handleCheckChange(changeEvent) {
    this.setState({
      mode: changeEvent.target.value,
    });
  }

  handleBtnClick(event) {
    try {
      let _this = this;
      let product = [];
      if (this.props.product) product = this.props.product.split(",");
      axios({
        method: "post",
        baseURL: sysconfig.EXECUTER_URI,
        url: "/signal/tradelist",
        data: {
          email: this.props.user.email,
          product,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          mode: this.state.mode,
        },
        headers: { "x-auth": this.props.token },
      })
        .then(function (response) {
          if (response.data) {
            _this.setState({
              trades: response.data,
              btnEnabled: false,
              tradelist: "show",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  }

  getData(val) {
    //receive data from child component
    if (val === "hide") {
      this.setState({ tradelist: "hide", btnEnabled: true });
    }
  }

  render() {
    let role = this.props.user ? this.props.user.role : false;
    //let limit = this.state.perPage; Not being used
    let filter = this.showFilters();
    let panel = <div />;
    if (this.state.tradelist !== "hide") {
      panel = (
        <SignalTradesDetail
          sendData={this.getData}
          trades={this.state.trades}
        />
      );
    }
    return (
      <span>
        <div className="container">
          <div className="form-group">{filter}</div>
          {panel}
        </div>
        <br />
      </span>
    );
  }

  handleStartDateChange(changeEvent) {
    //date.toLocaleDateString("en-US")
    this.setState({
      startDate: changeEvent,
      msg: "",
    });
  }

  handleEndDateChange(changeEvent) {
    //date.toLocaleDateString("en-US")
    this.setState({
      endDate: changeEvent,
      msg: "",
    });
  }

  onInputChange(event) {
    if (validator.isInt(event.target.value)) {
      this.setState({
        [event.target.id]: event.target.value.trim(),
      });
    }
  }
}

function mapStateToProps(state) {
  //whatever is returned will show as props inside this container
  return {
    user: state.activeUser,
    token: state.activeToken,
  };
}

//promote Login from a component to a container with added props activeToken
export default connect(mapStateToProps)(SignalTrades);
