import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import _ from "lodash";
import UserSignalOrders from "../components/userSignalOrders";
import ReactTooltip from "react-tooltip";

import sysconfig from "../config";

function UserSignalDetailReport(props) {
  const [userSigDetail, setUserSigDetail] = useState("hide");
  const [userSigId, setUserSigId] = useState("");
  const [trades, setTrades] = useState([]);

  //equivalent to didMount
  useEffect(() => {
    //sigList();
  }, []);

  const terminateSignal = (id) => {
    axios({
      method: "post",
      baseURL: sysconfig.EXECUTER_URI,
      url: "/usersignal/terminate",
      data: {
        userSignalId: id,
      },
    })
      .then(function (response) {
        if (response.data) {
          alert(response.data);
        }
      })
      .catch(function (error) {
        console.log("Err terminate:", error);
        alert(error.response.data.message);
      });
  };

  const title = () => {
    return (
      <div>
        <div className="row">
          <div className="col-md-12" align="right">
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={(event) => props.sendData("hide")}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div className="row" style={{ fontSize: "small" }}>
          <div className="col-md-1">Symbol</div>
          <div className="col-md-1">Buy</div>
          <div className="col-md-1">T1</div>
          <div className="col-md-1">T2</div>
          <div className="col-md-1">T3</div>
          <div className="col-md-1">SL</div>
          <div className="col-md-2">Date</div>
        </div>
      </div>
    );
  };

  const cancel = (usersig) => {
    if (usersig.stopStart.status === "OPEN")
      return (
        <div className="col-md-1" align="left">
          <img
            id="btncancel"
            src="/images/negative.gif"
            height="15"
            width="15"
            alt="cancel operation"
            className="cursor-pointer"
            data-tip="Terminate operation"
            onClick={() => terminateSignal(usersig._id)}
          />
        </div>
      );
    else return <div />;
  };

  const list = () => {
    let result = [];
    for (let usersig of props.usersignals) {
      let stopStart = JSON.parse(JSON.stringify(usersig.stopStart));
      let executed = JSON.parse(JSON.stringify(usersig.stopStart.executed));
      let entry = executed.entryId ? "text-primary" : "text-dark";
      let t1 = executed.profitVolume[0] > 0 ? "text-primary" : "text-dark";
      let t2 = executed.profitVolume[1] > 0 ? "text-primary" : "text-dark";
      let t3 = executed.profitVolume[2] > 0 ? "text-primary" : "text-dark";
      let sl = executed.stopVolume > 0 ? "text-danger" : "text-dark";
      if (Number(stopStart.stopLoss) > Number(stopStart.entryEnd))
        sl = "text-primary";
      let toRound = 6;
      if (stopStart.entryEnd > 1) toRound = 3;
      result.push(
        <div>
          <div className="row" key={usersig._id} style={{ fontSize: "small" }}>
            <div className="col-md-1">{usersig.symbol}</div>
            <div className="col-md-1">
              <font className={entry}>
                {stopStart.entryEnd.toFixed(toRound)}
              </font>
            </div>
            <div className="col-md-1">
              <font className={t1}>
                {stopStart.takeProfit[0].target.toFixed(toRound)}
              </font>
            </div>
            <div className="col-md-1">
              <font className={t2}>
                {stopStart.takeProfit[1].target.toFixed(toRound)}
              </font>
            </div>
            <div className="col-md-1">
              <font className={t3}>
                {stopStart.takeProfit[2].target.toFixed(toRound)}
              </font>
            </div>
            <div className="col-md-1">
              <font className={sl}>{stopStart.stopLoss.toFixed(toRound)}</font>
            </div>
            <div className="col-md-2">
              {moment(usersig.createdAt).format("YY-MM-DD hh:mm")}
            </div>
            <div className="col-md-1" align="left">
              <img
                id="btnorders"
                src="/images/eye1.png"
                height="18"
                width="18"
                alt="detail"
                className="cursor-pointer"
                data-tip="Orders"
                onClick={() => selectOrders(usersig._id)}
              />
            </div>
            {cancel(usersig)}
          </div>
          {showOrders(usersig._id)}
        </div>,
      );
    }

    return result;
  };

  const selectOrders = (id) => {
    setUserSigId(id);
    setUserSigDetail("show");
  };

  const showOrders = (selectedId) => {
    let result = <div />;
    if (userSigId === selectedId) {
      // getOrders(userSigId);
      if (userSigDetail === "show") {
        result = (
          <UserSignalOrders
            sendData={getData}
            token={props.token}
            userSigId={userSigId}
          />
        );
      }
    }
    return result;
  };

  const getData = (val) => {
    //receive data from child component
    if (val === "hide") {
      setUserSigDetail("hide");
      setUserSigId("");
    }
  };

  const calculate = (entry, target) => {
    if (Number(target) === 0) return 0;
    return _.round((100 * (Number(target) - Number(entry))) / Number(entry), 2);
  };

  let _title = title();
  let _list = list();

  return (
    <div>
      {_title} {_list}
      <ReactTooltip place="top" type="info" multiline="true" effect="float" />
    </div>
  );
}

export default UserSignalDetailReport;
