import React, { Component } from "react";
import _ from "lodash";
import moment from "moment";

//import SignalTradesExcel from "./signalTradesExcel";

class SignalTradesDetail extends Component {
  // Excluded Modal Window at branch 055-TView-Futures

  constructor(props) {
    super(props);
    this.handleCloseClick = this.handleCloseClick.bind(this);
    this.handleExcelClick = this.handleExcelClick.bind(this);
    this.handleCheckChange = this.handleCheckChange.bind(this);
    this.state = {
      msg: "",
      updated: false,
      market: "both"
    };
  }

  handleCloseClick(changeEvent) {
    this.props.sendData("hide"); //send to parent
  }

  componentDidUpdate() {
    if (this.props.trades[0] && !this.state.updated) {
      let base = this.props.trades[0].symbol.slice(-4) === "USDT" ? "USDT" : "BTC";
      let len = this.props.trades[0].symbol.length;
      let token = this.props.trades[0].symbol.substr(0, len - base.length);
      this.setState({ token, base, updated: true });
    }
  }

  componentDidMount() {
    if (this.props.trades[0] && !this.state.updated) {
      let base = this.props.trades[0].symbol.slice(-3);
      if (["PAX", "BTC"].indexOf(base) === -1) {
        base = this.props.trades[0].symbol.slice(-4); //USDT, USDC, TUSD
      }
      let len = this.props.trades[0].symbol.length;
      let token = this.props.trades[0].symbol.substr(0, len - base.length);
      this.setState({ token, base, updated: true });
    }
  }

  title() {
    let market = this.state.market ? this.state.market : "both";
    return (
      <div className="font-weight-bold">
        <div className="row">
          <div className="col-md-6" align="left">
            <div className="form-group form-group-sm">
              <span className="border border-secondary p-2">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="checkSpot"
                    value="spot"
                    checked={market === "spot"}
                    onChange={this.handleCheckChange}
                  />
                  <label className="form-check-label">Spot</label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="checkFutures"
                    value="futures"
                    checked={market === "futures"}
                    onChange={this.handleCheckChange}
                  />
                  <label className="form-check-label">Futures</label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="checkBoth"
                    value="both"
                    checked={market === "both"}
                    onChange={this.handleCheckChange}
                  />
                  <label className="form-check-label">Both</label>
                </div>
              </span>
            </div>
          </div>
          <div className="col-md-6" align="right">
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={event => this.handleCloseClick(event)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div className="row" align="right" style={{ fontSize: "small" }}>
          <div className="col-md-1"> Symbol</div>
          <div className="col-md-1" align="center">
            Operation
          </div>
          <div className="col-md-1"> Price</div>
          <div className="col-md-1"> Quantity</div>
          <div className="col-md-1"> Market value</div>
          <div className="col-md-1"> Price % variation</div>
          <div className="col-md-2"> Volume variation</div>
          <div className="col-md-2"> Date</div>
          <div className="col-md-2"> Source</div>
        </div>
      </div>
    );
  }

  handleCheckChange(changeEvent) {
    this.setState({
      market: changeEvent.target.value
    });
  }

  List() {
    let listItems = [];
    let excelTrades = [];
    let ind = 0;
    let lastPosInd = 0;
    let prevPos = "flat";
    let prevSymbol = "";
    let totBTC = 0;
    let totUSD = 0;

    let trades =
      this.state.market === "both"
        ? this.props.trades
        : this.props.trades.filter(doc => doc.market === this.state.market.toUpperCase());
    for (let element of trades) {
      let base = element.symbol.slice(-3) === "BTC" ? "BTC" : "USDT";
      // let len = element.symbol.length;
      // let token = element.symbol.substr(0, len - base.length);
      if (prevSymbol !== element.symbol) {
        prevSymbol = element.symbol;
        listItems.push(
          <div className="row">
            <div className="col-md-12">
              {" "}
              <hr />
            </div>
          </div>
        );
      }
      if (element.position.toLowerCase() === "long") {
        lastPosInd = ind;
        prevPos = "long";
      }
      if (element.position.toLowerCase() === "short") {
        lastPosInd = ind;
        prevPos = "short";
      }

      let tokenAmount;
      let baseAmount;
      tokenAmount = element.quantity;
      baseAmount = element.quantity * element.price;
      let fee = 0.075;
      // if (element.log && element.log.orderResponse && element.log.orderResponse.fills[0]) {
      //   fee = element.log.orderResponse.fills[0].commissionAsset === "BNB" ? 0.075 : 0.1;
      // }
      let diff;
      let percent;
      let volume;
      let color = "text-primary";
      let previousPrice;
      if (element.position.toLowerCase() === "flat") {
        previousPrice = Number(trades[lastPosInd].price);
        if (ind === 0 || trades[lastPosInd].symbol !== element.symbol) {
          previousPrice = 0;
        }
        if (prevPos === "long") diff = element.price - previousPrice;
        if (prevPos === "short") diff = previousPrice - element.price;
        color = diff >= 0 ? "text-primary" : "text-danger";
        percent = previousPrice === 0 ? 0.0 : diff * 100 / previousPrice - fee;
        volume = previousPrice === 0 ? 0.0 : diff * element.quantity;

        if (base === "BTC") {
          totBTC += volume;
        } else {
          totUSD += volume;
        }
        if (!isNaN(percent)) {
          percent = _.round(percent, 2);
          volume = _.round(volume, 8);
        } else {
          percent = 0;
          volume = 0;
        }
      }
      if (element.product === "portfolio") {
        percent = "";
        volume = "";
      }
      let orderSource = element.orderSource.replace("signals", "").replace("takeProfit", "target");
      let position =
        element.position && element.market !== "SPOT" ? element.position : element.side; //.substring(0, 1)
      listItems.push(
        <div align="right" style={{ fontSize: "small" }} key={ind}>
          <div className="row">
            <div className="col-md-1"> {element.symbol}</div>
            <div className="col-md-1" align="center">
              {position}
            </div>
            <div className="col-md-1"> {_.round(element.price, 8)}</div>
            <div className="col-md-1"> {_.round(tokenAmount, 8)}</div>
            <div className="col-md-1"> {_.round(baseAmount, 8)}</div>
            <div className="col-md-1">
              <label className={color}>{percent}</label>
            </div>
            <div className="col-md-2">
              <label className={color}>{volume}</label>
            </div>
            <div className="col-md-2">{moment(element.createdAt).format("YY-MM-DD kk:mm")}</div>
            <div className="col-md-2">
              {orderSource} {element.delegate.substr(0, 9)}
            </div>
          </div>
        </div>
      );
      excelTrades.push({
        symbol: element.symbol,
        side: element.side,
        position: element.position,
        orderType: element.orderType,
        status: element.status,
        price: _.round(element.price, 8),
        quantity: _.round(tokenAmount, 8),
        baseAmount: _.round(baseAmount, 8),
        priceVariation: percent,
        volumeVariation: volume,
        createdAt: moment(element.createdAt).format("YY-MM-DD kk:mm"),
        orderSource
      });
      ind += 1;
    }
    let totLine = (
      <div align="right" style={{ fontSize: "small" }}>
        <div className="row">
          <div className="col-md-4"> </div>
          <div className="col-md-2">
            <label>Sum USD: </label>
            <br />
            <label>Sum BTC: </label>
          </div>
          <div className="col-md-2">
            <label>{_.round(totUSD, 2)}</label>
            <br />
            <label>{_.round(totBTC, 8)}</label>
          </div>
          <div className="col-md-2">
            <br />
            <span
              className="btn-link btn-success cursor-pointer"
              onClick={event => this.handleExcelClick(excelTrades)}
            >
              Excel export
            </span>
          </div>
        </div>
      </div>
    );

    return [listItems, totLine];
  }

  handleExcelClick(excelTrades) {
    this.setState({ excelTrades });
  }

  render() {
    if (!this.state || !this.state.token) {
      return <div />;
    }
    let title = this.title();
    let [detail, totLine] = this.List();
    let excel = <div />;
    // if (this.state.excelTrades) {
    //   excel = <SignalTradesExcel trades={this.state.excelTrades} />;
    // }
    return (
      <div>
        <div className="presentation-div">
          {title}
          {detail}
          {totLine}
          <div className="row">
            <div className="col-md-7" />
            <div className="col-md-2" align="right">
              {excel}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SignalTradesDetail;
