import React, { Component } from "react";
import _ from "lodash";
import moment from "moment";

import sysconfig from "../config";

class BalanceShow extends Component {
  constructor(props) {
    super(props);
    this.handleCloseClick = this.handleCloseClick.bind(this);
  }

  handleCloseClick(changeEvent) {
    this.props.sendData("hide-balance"); //send to parent
  }

  showBalance() {
    const listBalance = () => {
      if (this.props.currBalance) {
        return this.props.currBalance.map(element => {
          return (
            <div className="row">
              <div className="col-md-2">{element.asset}</div>
              <div className="col-md-2">{Number(element.amount).toFixed(8)}</div>
              <div className="col-md-2">{Number(element.locked).toFixed(8)}</div>
              <div className="col-md-2">{Number(element.USD).toFixed(2)}</div>
              <div className="col-md-2">{Number(element.BTC).toFixed(8)}</div>
            </div>
          );
        });
      }
    };
    let details = <div />;
    if (this.props.currBalance && this.props.currBalance.length > 0) {
      var lista = listBalance();
      let title = this.title();
      details = (
        <div>
          {title}
          {lista}
        </div>
      );
    }
    return details;
  }

  title() {
    return (
      <span>
        <div className="row">
          <div className="col-md-6">
            {" "}
            <b>Current Balance</b>
          </div>
          <div className="col-md-4" align="right">
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={event => this.handleCloseClick(event)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <label>
              <b>Asset</b>
            </label>
          </div>
          <div className="col-md-2">
            <label>
              <b>Amount</b>
            </label>
          </div>
          <div className="col-md-2">
            <label>
              <b>Locked</b>
            </label>
          </div>
          <div className="col-md-2">
            <label>
              <b>USD Value</b>
            </label>
          </div>
          <div className="col-md-2">
            <label>
              <b>BTC Value</b>
            </label>
          </div>
        </div>
      </span>
    );
  }

  render() {
    if (!this.props.currBalance || this.props.currBalance.length === 0) {
      return <div />;
    }
    let showBalance = this.showBalance();
    return <div>{showBalance}</div>;
  }
}

export default BalanceShow;
