import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import ReactTooltip from "react-tooltip";
import validator from "validator";

import { selectUser, selectToken, selectPartner } from "../actions/root";
import sysconfig from "../config";

function FuturesConfig(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.activeUser);
  const token = useSelector((state) => state.activeToken);
  const [status, setStatus] = useState(
    user.futures.status === "ON" ? true : false
  );
  const [delegate, setDelegate] = useState(user.futures.delegate);
  const [btnSubmit, setBtnSubmit] = useState("Save");
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [msg, setMsg] = useState("");

  const handleSaveClick = (event) => {
    try {
      let msg = validate();
      if (msg !== "OK") return alert("Error: " + msg);
      setIsBtnDisabled(true);
      setBtnSubmit("Wait...");
      let data = {
        email: user.email,
        status: status,
      };

      axios({
        method: "patch",
        baseURL: sysconfig.EXECUTER_URI,
        url: "/futures/usersettings",
        headers: { "x-auth": token },
        data,
      })
        .then(function (response) {
          setBtnSubmit("Save");
          setIsBtnDisabled(false);
          setMsg("General account data saved!");
        })
        .catch(function (error) {
          alert("Error: " + error.response.data.message);
          setBtnSubmit("Save");
          setIsBtnDisabled(false);
          setMsg("");
        });
    } catch (e) {
      console.log(e);
      alert("Error: " + e.message);
    }
  };

  const handleStatusChange = (event) => {
    setStatus(!status);
  };

  const panel = () => {
    let _delegateLine = <span />;
    _delegateLine = (
      <div className="form-group">
        <label>Leader to Follow</label>
        <input
          className="form-control"
          id="delegate"
          disabled
          value={delegate}
        />
      </div>
    );

    let _status = (
      <div className="custom-control custom-switch">
        <input
          type="checkbox"
          className="custom-control-input"
          id="status"
          checked={status}
          onChange={handleStatusChange}
        />
        <label className="custom-control-label" for="status">
          Turn Bot ON/OFF
        </label>
      </div>
    );

    return (
      <div>
        <div className="w-50">
          <center>
            <h5>General Settings</h5>
          </center>
          {_delegateLine}
          {_status}
        </div>
        <div className="form-group">
          <br />
          <button
            type="button"
            className="btn btn-primary"
            onClick={(event) => handleSaveClick()}
          >
            {btnSubmit}
          </button>
        </div>
        <div className="text-info mt-2">{msg}</div>
      </div>
    );
  };

  const validate = () => {
    let result = "OK";

    return result;
  };

  let _panel = panel();
  return (
    <div className="row">
      <div className="col-md-2" />
      <div className="col-md-10">
        {_panel}
        <ReactTooltip place="top" type="info" multiline="true" effect="float" />
      </div>
    </div>
  );
}

export default FuturesConfig;
