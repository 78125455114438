import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import _ from "lodash";

import sysconfig from "../config";
// import FuturesUsersOrders from "../components/futuresUsersOrders";

function FuturesListDetail(props) {
  const user = useSelector((state) => state.activeUser);
  const token = useSelector((state) => state.activeToken);
  const [userDetail, setUserDetail] = useState("hide");
  const [orderId, setOrderId] = useState("");

  const title = () => {
    return (
      <div className="font-weight-bold">
        <div className="row">
          <div className="col-md-6" align="left">
            {" "}
            <span className="ml-2">
              <strong>Detail</strong>
            </span>
          </div>
          <div className="col-md-6" align="right">
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={(event) => props.sendData("hide")}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div className="row" align="right" style={{ fontSize: "small" }}>
          <div className="col-md-1"> Symbol</div>
          <div className="col-md-2" align="center">
            Position / Side
          </div>
          <div className="col-md-2"> Type</div>
          <div className="col-md-1"> Status</div>
          <div className="col-md-1"> Price</div>
          <div className="col-md-1"> Quantity</div>
          <div className="col-md-2"> Date</div>
        </div>
      </div>
    );
  };
  // <div className="col-md-1"> Price % variation</div>
  // <div className="col-md-2"> Volume variation</div>

  const List = () => {
    let listItems = [];
    let totPercent = 0;
    let ind = 0;
    let lastBuyInd = 0;
    let prevSymbol = "";
    let totUSD = 0;
    for (let element of props.trades) {
      let market = "USDT";
      if (prevSymbol !== element.symbol) {
        prevSymbol = element.symbol;
        listItems.push(
          <div className="row">
            <div className="col-md-12">
              {" "}
              <hr />
            </div>
          </div>
        );
      }
      if (element.side.toLowerCase() === "buy") lastBuyInd = ind;

      let tokenAmount;
      let marketAmount;
      tokenAmount = element.quantity;
      marketAmount = element.quantity * element.price;
      let diff;
      let percent;
      let volume;
      let color = "text-primary";
      let previousPrice;
      if (element.side.toLowerCase() === "sell") {
        previousPrice = Number(props.trades[lastBuyInd].price);
        if (ind === 0 || props.trades[lastBuyInd].symbol !== element.symbol) {
          previousPrice = 0;
        }
        diff = element.price - previousPrice;
        color = diff >= 0 ? "text-primary" : "text-danger";
        percent = previousPrice === 0 ? 0.0 : (diff * 100) / previousPrice;
        volume = previousPrice === 0 ? 0.0 : diff * element.quantity;
        totUSD += volume;

        if (!isNaN(percent)) {
          percent = _.round(percent, 2);
          volume = _.round(volume, 8);
          totPercent += percent;
        } else {
          percent = 0;
          volume = 0;
        }
      }
      listItems.push(
        <div align="right" style={{ fontSize: "small" }}>
          <div className="row">
            <div className="col-md-1"> {element.symbol}</div>
            <div className="col-md-2" align="center">
              {element.position} / {element.side}
            </div>
            <div className="col-md-2">
              {" "}
              {element.orderType.replace("_MARKET", "")}
            </div>
            <div className="col-md-1"> {element.status}</div>
            <div className="col-md-1"> {_.round(element.price, 8)}</div>
            <div className="col-md-1"> {_.round(tokenAmount, 8)}</div>
            <div className="col-md-2">
              {moment(element.updatedAt).format("YY-MM-DD kk:mm")}
            </div>
          </div>
          {showUsersOrders(element.symbol, element.exchangeOrderId)}
        </div>
      );
      ind += 1;
    }

    // <div className="col-md-1"> {_.round(marketAmount, 8)}</div>
    // <div className="col-md-1">
    //   <label className={color}>{percent}</label>
    // </div>
    // <div className="col-md-2">
    //   <label className={color}>{volume}</label>
    // </div>
    //   <div className="col-md-1">
    //   <span
    //     className="btn-link cursor-pointer"
    //     onClick={() => selectUsers(element.exchangeOrderId)}
    //   >
    //     ->
    //   </span>
    // </div>

    let totLine = <div />;
    // (
    //   <div align="right" style={{ fontSize: "small" }}>
    //     <div className="row">
    //       <div className="col-md-4"> </div>
    //       <div className="col-md-2">
    //         <label>Sum USD: </label>
    //       </div>
    //       <div className="col-md-2">
    //         <label>{_.round(totUSD, 2)}</label>
    //       </div>
    //     </div>
    //   </div>
    // );

    return [listItems, totLine];
  };

  const selectUsers = (exchangeOrderId) => {
    setOrderId(exchangeOrderId);
    setUserDetail("show");
  };

  const showUsersOrders = (symbol, exchangeOrderId) => {
    let result = <div />;
    // if (userDetail === "show" && orderId === exchangeOrderId) {
    //   result = (
    //     <FuturesUsersOrders
    //       sendData={getData}
    //       symbol={symbol}
    //       orderId={orderId}
    //     />
    //   );
    // }
    return result;
  };

  const getData = (val) => {
    //receive data from child component
    if (val === "hide") {
      setUserDetail("hide");
    }
  };

  let [detail, totLine] = List();
  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          {" "}
          {title()} {detail}
          {totLine}
        </div>
      </div>
    </div>
  );
}

export default FuturesListDetail;
