import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactTooltip from "react-tooltip";
import validator from "validator";

import { groupConstrainter } from "../../utils/clientConstraint";
import sysconfig from "../../config";

function RebalanceTrigger(props) {
  const [trigger, setTrigger] = useState(props.trigger);
  const [deviation, setDeviation] = useState(props.deviation);
  const [frame, setFrame] = useState(props.frame);
  const [period, setPeriod] = useState(props.period);
  const [btnSubmit, setBtnSubmit] = useState("Save");
  const [msg, setMsg] = useState("");

  const onInputChange = event => {
    //send to parent
    switch (event.target.id) {
      case "deviation":
        setDeviation(event.target.value);
        props.sendData({ deviation: event.target.value });
        break;
      case "period":
        setPeriod(event.target.value);
        props.sendData({ period: event.target.value });
        break;
    }
  };

  const handleTriggerChange = event => {
    setTrigger(event.target.value);
    props.sendData({ trigger: event.target.value }); //send to parent
  };

  const handleFrameChange = event => {
    setFrame(event.target.value);
    props.sendData({ frame: event.target.value }); //send to parent
  };

  const cpTrigger = () => {
    let result = (
      <div class="form-group">
        <small data-tip="How to rebalance your assets">Rebalance Trigger</small>
        <select
          className="form-control form-control-sm w-75"
          id="trigger"
          value={trigger}
          onChange={handleTriggerChange}
        >
          <option id="manual" value="MANUAL" selected={trigger === "MANUAL"}>
            Manual
          </option>
          <option id="periodic" value="PERIODIC" selected={trigger === "PERIODIC"}>
            Periodic
          </option>
          <option id="threshold" value="THRESHOLD" selected={trigger === "THRESHOLD"}>
            Threshold
          </option>
        </select>
      </div>
    );
    return result;
  };

  const cpThreshold = () => {
    if (trigger !== "THRESHOLD") return <div />;
    let result = (
      <div className="form-group">
        <small data-tip="Set asset variation limit (in %) to trigger a rebalance">
          Max Variation (%)
        </small>
        <input
          className="form-control form-control-sm w-25"
          id="deviation"
          placeholder="Maximum variation allowed (%)"
          value={deviation}
          onChange={onInputChange}
        />
      </div>
    );
    return result;
  };

  const cpPeriodic = () => {
    if (trigger !== "PERIODIC") return <div />;
    let result = (
      <div className="form-group">
        <div className="row">
          <div className="col-md-3">
            <small data-tip="Number of time frames">Every</small>
            <input
              className="form-control form-control-sm"
              id="period"
              placeholder="Number of time frames"
              value={period}
              onChange={onInputChange}
            />
          </div>
          <div className="col-md-3">
            <small data-tip="Time-frame to rebalance your assets">Time-Frame</small>
            <select
              className="form-control form-control-sm"
              id="frame"
              value={frame}
              onChange={handleFrameChange}
            >
              <option id="hours" value="hours" selected={frame === "hours"}>
                Hours
              </option>
              <option id="days" value="days" selected={trigger === "days"}>
                Days
              </option>
            </select>
          </div>
        </div>
      </div>
    );
    return result;
  };

  const panel = () => {
    let _trigger = cpTrigger();
    let _threshold = cpThreshold();
    let _periodic = cpPeriodic();

    return (
      <div>
        {_trigger} {_periodic} {_threshold}
      </div>
    );
  };

  let _panel = panel();
  return (
    <div>
      {_panel}
      <ReactTooltip place="top" type="info" multiline="true" effect="float" />
    </div>
  );
}

export default RebalanceTrigger;
