import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import ReactTooltip from "react-tooltip";
import validator from "validator";

import { selectUser, selectToken } from "../actions/root";
import sysconfig from "../config";
import { groupConstrainter } from "../utils/clientConstraint";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

function Security(props) {
  const dispatch = useDispatch();
  const user = useSelector(state => state.activeUser);
  const token = useSelector(state => state.activeToken);
  const [tk, setTk] = useState("");
  const [sk, setSk] = useState("");
  const [passphrase, setPassphrase] = useState("");
  const [btnKeys, setBtnKeys] = useState("Save Keys");
  const [tktype, setTkType] = useState("password");
  const [sktype, setSkType] = useState("password");
  const [passphrasetype, setPassphraseType] = useState("password");
  const [btnpw, setBtnPw] = useState("Change");
  const [oldpassword, setOldPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [retypepassword, setRetypePassword] = useState("");
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [exchange, setExchange] = useState("");
  const [subaccounts, setSubAccounts] = useState([]);
  const [subaccount, setSubAccount] = useState("");
  const [newsub, setNewSub] = useState("");
  const [msg, setMsg] = useState("");

  useEffect(() => {
    if (user && user.exchanges && user.exchanges.length > 0) {
      setExchange(user.exchanges[0].exchange);
      setSubAccounts(subAccountDescriptions(user.exchanges[0].subaccounts));
    } else {
      setExchange("binance");
      setSubAccounts(["main"]);
      setSubAccount("main");
    }
    if (groupConstrainter(user)) setSubAccount("main");
  }, []);

  const subAccountDescriptions = subaccounts => {
    if (!subaccounts || subaccounts.length === 0) return ["main"];
    else return subaccounts.map(doc => doc.description);
  };

  const onInputChange = event => {
    if (event.target.id === "tk") setTk(event.target.value.trim());
    if (event.target.id === "sk") setSk(event.target.value.trim());
    if (event.target.id === "passphrase") setPassphrase(event.target.value.trim());
    if (event.target.id === "oldpassword") setOldPassword(event.target.value.trim());
    if (event.target.id === "newpassword") setNewPassword(event.target.value.trim());
    if (event.target.id === "retypepassword") setRetypePassword(event.target.value.trim());
    if (event.target.id === "newsub") setNewSub(event.target.value.trim());
  };

  const handleViewClick = event => {
    if (event.target.id === "btntk") {
      tktype === "password" ? setTkType("text") : setTkType("password");
    }
    if (event.target.id === "btnsk") {
      sktype === "password" ? setSkType("text") : setSkType("password");
    }
    if (event.target.id === "btnps") {
      passphrasetype === "password" ? setPassphraseType("text") : setPassphraseType("password");
    }
  };

  const handleAddSubAccount = () => {
    let foundmain = subaccounts.find(doc => doc === "main");
    if (exchange === "binance" && newsub) {
      setNewSub("");
      return alert("Binance only accepts a main subaccount");
    }
    if (!foundmain || !foundmain.tk) {
      setNewSub("");
      return alert("Fill main subaccount keys before adding new one");
    }
    if (!newsub) return alert("Please name the subaccount");
    let found = subaccounts.find(doc => doc === newsub);
    if (found) return alert("Each subaccount must have a unique name");
    subaccounts.push(newsub);
    setSubAccounts(subaccounts);
    setSubAccount(newsub);
    setNewSub("");
  };

  const getPw = () => {
    if (user) {
      return (
        <div className="container w-75 form-group">
          <strong>Change Password</strong>
          <div className="form-group">
            <label>Old Password</label>
            <div className="row">
              <div className="col-md-10">
                <input
                  type="password"
                  className="form-control"
                  id="oldpassword"
                  placeholder="Enter password"
                  value={oldpassword}
                  onChange={onInputChange}
                />{" "}
              </div>
            </div>
          </div>
          <div className="form-group">
            <label>New Password</label>
            <div className="row">
              <div className="col-md-10">
                <input
                  type="password"
                  className="form-control"
                  id="newpassword"
                  placeholder="Enter password"
                  value={newpassword}
                  onChange={onInputChange}
                />
              </div>{" "}
            </div>{" "}
          </div>
          <div className="form-group">
            <label>Repeat new Password</label>
            <div className="row">
              <div className="col-md-10">
                <input
                  type="password"
                  className="form-control"
                  id="retypepassword"
                  placeholder="Enter password"
                  value={retypepassword}
                  onChange={onInputChange}
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-md-10">
                <button
                  type="button"
                  className="btn btn-primary mr-4"
                  disabled={isBtnDisabled}
                  onClick={handleChangePwClick}
                >
                  {btnpw}
                </button>
                <p className="text-info"> {msg}</p>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div> waiting api user data</div>;
    }
  };

  const selectExchange = () => {
    if (groupConstrainter(user)) return <div />;
    let result = <div />;
    result = (
      <div className="col-md-5">
        <small>Exchange</small>
        <select
          className="form-control form-control-sm"
          id="exchange"
          value={exchange}
          onChange={handleExchangeChange}
        >
          <option id="none" value="" selected={exchange === ""} />
          <option id="binance" value="binance" selected={exchange === "binance"}>
            BINANCE
          </option>
          <option id="bitget" value="bitget" selected={exchange === "bitget"}>
            BITGET
          </option>
          <option id="ftx" value="ftx" selected={exchange === "ftx"}>
            FTX
          </option>
        </select>
      </div>
    );
    return result;
  };

  const getKeys = () => {
    let cpPassphrase = <div />;
    if (!exchange || !subaccount) return <div />;
    if (exchange === "bitget") {
      cpPassphrase = (
        <div>
          <small>Passphrase</small>
          <div className="row">
            <div className="col-md-10">
              <input
                type={passphrasetype}
                className="form-control"
                id="passphrase"
                placeholder="passphrase"
                value={passphrase}
                onChange={onInputChange}
              />
            </div>
            <div className="col-md-2">
              <img
                id="btnps"
                src="/images/eye1.png"
                height="32"
                width="45"
                alt="toggle view"
                className="cursor-pointer"
                onClick={handleViewClick}
              />
            </div>
          </div>
        </div>
      );
    }
    if (user) {
      return (
        <div className="col-md-6">
          <br />
          <img
            id="btntk"
            src="/images/help-icon.png"
            height="25"
            width="25"
            alt="Help"
            data-tip="Log into your exchange account and go to API Management.
            <br/> Name and Create new API Key. <br/>
            This will show you a new keypair, with api key and secret key.
            <br/> Live restrictions as: i) Enable Trade; ii) Disable Withdraw.
              <br />
            Copy your api and secret keys, and paste them at this Aperium page, accordingly.
              <br />
            Click Save Keys and you are all set. Now Aperium Trader may trade on your behalf!!"
          />
          <small>API Key</small>
          <div className="row">
            <div className="col-md-10">
              <input
                type={tktype}
                className="form-control"
                id="tk"
                placeholder="api key"
                value={tk}
                onChange={onInputChange}
              />
            </div>
            <div className="col-md-2">
              <img
                id="btntk"
                src="/images/eye1.png"
                height="32"
                width="45"
                alt="toggle view"
                className="cursor-pointer"
                onClick={handleViewClick}
              />
            </div>
          </div>
          <small>Secret Key</small>
          <div className="row">
            <div className="col-md-10">
              <input
                type={sktype}
                className="form-control"
                id="sk"
                placeholder="secret key"
                value={sk}
                onChange={onInputChange}
              />
            </div>
            <div className="col-md-2">
              <img
                id="btnsk"
                src="/images/eye1.png"
                height="32"
                width="45"
                alt="toggle view"
                className="cursor-pointer"
                onClick={handleViewClick}
              />
            </div>
          </div>
          {cpPassphrase}
          <div className="row">
            <div className="col-md-3">
              <button
                type="button"
                className="btn btn-primary btn-sm mr-3 mt-3"
                disabled={isBtnDisabled}
                onClick={handleBtnSaveClick}
              >
                <small>{btnKeys}</small>
              </button>
            </div>
            <div className="col-md-3">
              <button
                type="button"
                className="btn btn-danger btn-sm mr-3 mt-3"
                onClick={handleBtnDelClick}
              >
                <small>Delete</small>
              </button>
            </div>
          </div>
          <ReactTooltip place="top" type="info" multiline="true" effect="float" />
        </div>
      );
    } else {
      return <div> waiting api user data</div>;
    }
  };

  const listSubAccounts = () => {
    if (groupConstrainter(user)) return <div />;
    let result;
    if (!exchange || !subaccounts || subaccounts.length === 0) result = <div />;
    else {
      let _addSubAcc = addSubAcc();
      result = [
        <div className="row">
          <div className="col-md-4" align="center" style={{ fontSize: "small" }}>
            <br />
            Select a subaccount or add new:
          </div>
          <div className="col-md-8">
            <br />
            {_addSubAcc}
          </div>
        </div>
      ];
      let i = 0;
      for (let doc of subaccounts) {
        result.push(
          <div className="col-md-12">
            <div className="form-check">
              <input
                className="form-check-input"
                key={i}
                type="radio"
                value={doc}
                id={doc}
                name={doc}
                onChange={() => setSubAccount(doc)}
                checked={subaccount === doc}
              />
              <label className="form-check-label" for={doc}>
                <small>{doc}</small>
              </label>
            </div>
          </div>
        );
        i++;
      }
    }
    return result;
  };

  const addSubAcc = () => {
    let result = (
      <div className="row form-group">
        <div className="col-md-9">
          <input
            className="form-control form-control-sm"
            id="newsub"
            placeholder="New subaccount"
            value={newsub}
            onChange={onInputChange}
          />
        </div>
        <div className="col-md-3">
          <button
            type="button"
            className="btn btn-outline-secondary btn-sm"
            onClick={handleAddSubAccount}
          >
            Add
          </button>
        </div>
      </div>
    );
    return result;
  };

  const handleExchangeChange = event => {
    setExchange(event.target.value);
    let ind = user.exchanges.findIndex(doc => doc.exchange === event.target.value);
    if (ind !== -1) setSubAccounts(subAccountDescriptions(user.exchanges[ind].subaccounts));
    else setSubAccounts(subAccountDescriptions());
  };

  const handleBtnSaveClick = () => {
    try {
      if (exchange === "ftx") throw new Error("FTX currently unavailable");
      if (!subaccount) throw new Error("Select a subaccount or Main");
      if (tk.length < 20 || sk.length < 20) {
        throw new Error("Invalid key length");
      }
      setBtnKeys("Wait...");
      axios({
        method: "POST",
        baseURL: sysconfig.EXECUTER_URI,
        url: "/user/tradekeys",
        headers: { "x-auth": token },
        data: {
          email: user.email,
          exchange,
          subaccount,
          tk,
          sk,
          passphrase
        }
      })
        .then(function(response) {
          if (response.status === 200) {
            selectUser(response.data);
            setBtnKeys("Save Keys");
            alert("Keys Saved");
          }
        })
        .catch(error => {
          setBtnKeys("Save Keys");
          alert("Error: " + error.response.data.message);
        });
    } catch (e) {
      alert("Error: " + e.message);
    }
  };

  const handleBtnDelClick = () => {
    try {
      if (!subaccount) throw new Error("Select a subaccount to delete");

      axios({
        method: "POST",
        baseURL: sysconfig.EXECUTER_URI,
        url: "/user/delkeys",
        headers: { "x-auth": token },
        data: {
          email: user.email,
          exchange,
          subaccount
        }
      })
        .then(function(response) {
          if (response.status === 200) {
            selectUser(response.data);
            let ind = user.exchanges.findIndex(doc => doc.exchange === exchange);
            setSubAccounts(subAccountDescriptions(user.exchanges[ind].subaccounts));
            setSubAccount("");
            alert("Keys Deleted");
          }
        })
        .catch(error => {
          alert("Error: " + error.response.data.message);
        });
    } catch (e) {
      alert("Error: " + e.message);
    }
  };

  const handleChangePwClick = () => {
    try {
      if (newpassword.length < 8) {
        throw new Error("Password must be at least 8 digits");
      }
      if (newpassword !== retypepassword) {
        throw new Error("Passwords mistype");
      }
      setIsBtnDisabled(true);
      setBtnPw("Wait...");
      setOldPassword("");
      setNewPassword("");
      setRetypePassword("");
      setMsg("");
      axios({
        method: "post",
        baseURL: sysconfig.EXECUTER_URI,
        url: "/user/changepw",
        headers: { "x-auth": token },
        data: {
          email: user.email,
          oldpassword,
          newpassword
        }
      })
        .then(function(response) {
          setIsBtnDisabled(false);
          setBtnPw("Change");
          setMsg("Password changed");
        })
        .catch(error => {
          alert("Error: Invalid password." + error.response.data.message);
          setIsBtnDisabled(false);
          setBtnPw("Change");
        });
    } catch (e) {
      alert("Error: " + e.message);
      console.log(e);
    }
  };

  const _keys = getKeys();
  const _pwd = getPw();
  const _listSubaccs = listSubAccounts();
  const _exchange = selectExchange();
  return (
    <Tabs>
      <TabList>
        <Tab>
          <small>API Keys</small>
        </Tab>
        <Tab>
          <small>Password</small>
        </Tab>
      </TabList>

      <TabPanel>
        <div>
          <div className="row">{_exchange}</div>
          <div className="row">{_listSubaccs}</div>
          <div className="row">{_keys}</div>
        </div>
      </TabPanel>
      <TabPanel>
        <div className="row">{_pwd}</div>
      </TabPanel>
      <ReactTooltip place="top" type="info" multiline="true" effect="float" />
    </Tabs>
  );
}

//promote Login from a component to a container with added props activeToken
export default Security;
