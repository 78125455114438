import React, { Component } from "react";
import _ from "lodash";
import moment from "moment";
import axios from "axios";

import sysconfig from "../config";

class DustConvert extends Component {
  constructor(props) {
    super(props);
    this.handleDustClick = this.handleDustClick.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.state = {
      minUsd: 10,
      msg: "",
      btnEnabled: true,
    };
  }

  filter() {
    return (
      <div className="row" align="right">
        <div className="col-md-5" align="right">
          <button
            type="button"
            className="btn btn-secondary btn-sm cursor-pointer"
            id="btndust"
            disabled={!this.state.btnEnabled}
            onClick={(event) => this.handleDustClick(event)}
          >
            Dust to BNB
          </button>
        </div>
      </div>
    );
  }

  handleDustClick() {
    const listBalance = () => {
      let result = [];
      if (this.props.currBalance) {
        this.props.currBalance.map((element) => {
          if (
            Number(element.USD) <= Number(this.state.minUsd) &&
            element.asset.toLowerCase() !== "total"
          )
            result.push(element.asset);
        });
      }
      return result;
    };
    let assets = listBalance();
    if (assets.length === 0)
      return alert("No tokens with enough value to convert");
    if (this.props.currBalance && this.props.currBalance.length > 0) {
      let email = this.props.user.email;
      let exchange = this.props.user.exchange;
      var _this = this;
      axios({
        method: "post",
        baseURL: sysconfig.EXECUTER_URI,
        url: "/balance/dust",
        data: { exchange, email, assets },
        headers: { "x-auth": this.props.token },
      })
        .then(function (response) {
          if (response.status === 200) {
            _this.state.msg = "Dust converted to BNB";
            _this.state.btnEnabled = false;
          }
        })
        .catch(function (error) {
          console.log("dust error", error);
          let msgErr = error.response
            ? `${error.response.status}: ${error.response.statusText} - Only one dust per day allowed`
            : "Err only one dust per day allowed";
          alert("Error: " + msgErr);
        });
    }
  }

  onInputChange(event) {
    this.setState({
      [event.target.id]: event.target.value.trim().replace(",", "."),
    });
  }

  render() {
    if (
      !this.props.currBalance ||
      this.props.currBalance.length === 0 ||
      this.props.market !== "spot"
    ) {
      return <div />;
    }
    let filter = this.filter();
    return (
      <div>
        {filter}
        <p className="text-info" align="center">
          {this.state.msg}
        </p>
      </div>
    );
  }
}

export default DustConvert;
