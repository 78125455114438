import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import validator from "validator";
import _ from "lodash";
import ReactTooltip from "react-tooltip";

import sysconfig from "../../config";

function BotStrategyDetail(props) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [symbol, setSymbol] = useState("");
  const [status, setStatus] = useState("ON");
  const [msg, setMsg] = useState("");
  const [strategy, setStrategy] = useState({ name, description, symbol, status: "ON" });
  const user = useSelector(state => state.activeUser);
  const token = useSelector(state => state.activeToken);

  //equivalent to didMount
  useEffect(() => {
    if (props.strategyId) getStrategy();
  }, []);

  const getStrategy = () => {
    axios({
      method: "get",
      baseURL: sysconfig.EXECUTER_URI,
      url: "/strategy/get",
      params: { id: props.strategyId, exchange: user.exchange }
    })
      .then(function(response) {
        if (response.data) {
          setStrategy(response.data);
          setName(response.data.name);
          setDescription(response.data.description);
          setSymbol(response.data.symbol);
          setStatus(response.data.status);
        }
      })
      .catch(function(error) {
        console.log("Err getStrategy:", error);
      });
  };

  const save = action => {
    setMsg("");
    let data = {
      exchange: user.exchange,
      username: user.username,
      symbol,
      name,
      description,
      status
    };
    axios({
      method: "post",
      baseURL: sysconfig.EXECUTER_URI,
      url: "/strategy/save",
      data,
      headers: { "x-auth": token }
    })
      .then(function(response) {
        if (response.status === 200) {
          setMsg("Strategy saved");
        }
      })
      .catch(function(error) {
        setMsg(`Error ${error.response.data.message}`);
      });
  };

  const handleSaveClick = action => {
    if (action === "save") save("OPEN");
  };

  const inputName = () => {
    return (
      <input
        className="form-control form-control-sm"
        value={name}
        placeHolder="Strategy Name"
        onChange={e => setName(e.target.value)}
      />
    );
  };

  const inputDescription = () => {
    return (
      <textarea
        className="form-control"
        id="description"
        rows="5"
        placeholder="Description"
        value={description}
        onChange={e => setDescription(e.target.value)}
      />
    );
  };

  const inputSymbol = () => {
    return (
      <input
        className="form-control form-control-sm"
        value={symbol}
        placeHolder="Symbol"
        onChange={e => setSymbol(e.target.value.toUpperCase().trim())}
      />
    );
  };

  const selectStatus = () => {
    return (
      <div>
        <div className="custom-control custom-radio">
          <input
            type="radio"
            id="statusON"
            name="onRadio"
            className="custom-control-input"
            checked={status === "ON"}
            onChange={() => setStatus("ON")}
          />
          <label className="custom-control-label" for="statusON">
            ON
          </label>
        </div>
        <div className="custom-control custom-radio">
          <input
            type="radio"
            id="statusOFF"
            name="OffRadio"
            className="custom-control-input"
            checked={status === "OFF"}
            onChange={() => setStatus("OFF")}
          />
          <label className="custom-control-label" for="statusOFF">
            OFF
          </label>
        </div>
        <div className="custom-control custom-radio">
          <input
            type="radio"
            id="statusCanceled"
            name="CancelRadio"
            className="custom-control-input"
            checked={status === "CANCELED"}
            onChange={() => setStatus("CANCELED")}
          />
          <label className="custom-control-label" for="statusCanceled">
            CANCELED
          </label>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-3">
          <h5> Edit Strategy </h5>
        </div>
        <div className="col-md-9" align="right">
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={event => props.sendData("hide")}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <br />{" "}
      <div className="row">
        <div className="col-md-3">Status: {status}</div>
      </div>
      <div className="row">
        <div className="col-md-3">{inputName()}</div>
        <div className="col-md-3">{inputSymbol()}</div>
        <div className="col-md-2">{selectStatus()}</div>
      </div>
      <div className="row">
        <div className="col-md-8">{inputDescription()}</div>
      </div>
      <br />
      <div className="row">
        <div className="col-md-2">
          <button
            id="save"
            className="btn btn-primary cursor-pointer"
            onClick={e => handleSaveClick(e.target.id)}
          >
            Save
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-2">{msg}</div>
      </div>
      <ReactTooltip place="top" type="dark" multiline="true" effect="float" />
    </div>
  );
}

export default BotStrategyDetail;
