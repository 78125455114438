import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import _ from "lodash";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  VictoryChart,
  VictoryLine,
  VictoryTooltip,
  VictoryVoronoiContainer,
  VictoryTheme,
} from "victory";

//import SignalGroupExcel from "./sigGroupExcel";
import sysconfig from "../config";

function SignalGroupDetail(props) {
  //const [excelRows, setExcelRows] = useState([]);
  const [chartGrossData, setChartGrossData] = useState([]);
  //equivalent to didMount
  useEffect(() => {
    //sigList();
  }, []);

  const terminateSignal = (id) => {
    axios({
      method: "post",
      baseURL: sysconfig.EXECUTER_URI,
      url: "/signal/terminate",
      data: {
        signalId: id,
      },
    })
      .then(function (response) {
        if (response.data) {
          alert(response.data);
        }
      })
      .catch(function (error) {
        console.log("Err terminate:", error);
        alert(error.response.data.message);
      });
  };

  const title = () => {
    return (
      <div>
        <div className="row">
          <div className="col-md-12" align="right">
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={(event) => props.sendData("hide")}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div className="row" style={{ fontSize: "small" }}>
          <div className="col-md-1">Product</div>
          <div className="col-md-1">Fund</div>
          <div className="col-md-1">Symbol</div>
          <div className="col-md-1">Buy</div>
          <div className="col-md-1">T1</div>
          <div className="col-md-1">T2</div>
          <div className="col-md-1">T3</div>
          <div className="col-md-1">SL</div>
          <div className="col-md-1">closedAt</div>
          <div className="col-md-1">
            <button
              type="button"
              className="btn btn-primary btn-sm cursor-pointer"
              id="chart"
              onClick={btnChartClick}
            >
              Chart
            </button>
          </div>
        </div>
      </div>
    );
  };

  const cancel = (signal) => {
    if (signal.product === "MANAGED" && signal.status === "OPEN")
      return (
        <div className="col-md-1" align="center">
          <img
            id="btncancel"
            src="/images/negative.gif"
            height="15"
            width="15"
            alt="cancel operation"
            className="cursor-pointer"
            data-tip="Terminate operation"
            onClick={() => terminateSignal(signal._id)}
          />
        </div>
      );
    else return <div />;
  };

  const list = () => {
    let result = [];
    //let resultExcel = [];
    for (let signal of props.signals) {
      let entry = signal.executed.entryTeleg ? "text-primary" : "text-dark";
      let t1 = signal.executed.profitTeleg[0] ? "text-primary" : "text-dark";
      let t2 = signal.executed.profitTeleg[1] ? "text-primary" : "text-dark";
      let t3 = signal.executed.profitTeleg[2] ? "text-primary" : "text-dark";
      // let sl = signal.executed.stopTeleg ? "text-primary" : "text-dark";
      let sl = "text-dark";
      if (signal.executed.stopTeleg)
        if (signal.position === "LONG")
          if (signal.stopStart.stopLoss >= signal.stopStart.entryEnd)
            sl = "text-primary";
          else sl = "text-danger";
        else if (signal.stopStart.stopLoss <= signal.stopStart.entryEnd)
          sl = "text-primary";
        else sl = "text-danger";

      result.push(
        <div className="row" style={{ fontSize: "small" }}>
          <div className="col-md-1">{signal.product}</div>
          <div className="col-md-1">{signal.fund}</div>
          <div className="col-md-1">{signal.symbol}</div>
          <div className="col-md-1">
            <font className={entry}>
              {signal.stopStart.entryEnd.toFixed(6)}
            </font>
          </div>
          <div className="col-md-1">
            <font className={t1}>
              {signal.stopStart.takeProfit[0].target.toFixed(6)}
            </font>
          </div>
          <div className="col-md-1">
            <font className={t2}>
              {signal.stopStart.takeProfit[1].target.toFixed(6)}
            </font>
          </div>
          <div className="col-md-1">
            <font className={t3}>
              {signal.stopStart.takeProfit[2].target.toFixed(6)}
            </font>
          </div>
          <div className="col-md-1">
            <font className={sl}>{signal.stopStart.stopLoss.toFixed(6)}</font>
          </div>
          <div className="col-md-1">
            {moment(signal.closedAt).format("YYYYMMDD:kkmmss")}
          </div>
          {cancel(signal)}
        </div>,
      );
      //resultExcel.push(excelRow(signal));
    }
    let lastLine = <div />;
    // (
    //   <div className="col-md-2">
    //     <br />
    //     <span className="btn-link cursor-pointer" onClick={event => handleExcelClick(resultExcel)}>
    //       Excel export
    //     </span>
    //   </div>
    // );
    return [result, lastLine];
  };

  const btnChartClick = () => {
    let grossData = [];
    //let resultExcel = [];
    for (let signal of props.signals) {
      grossData.push(prepareChart(signal));
      //resultExcel.push(excelRow(signal));
    }
    setChartGrossData(grossData);
    lineChart();
  };

  const lineChart = () => {
    if (chartGrossData.length > 0) var grossData = chartGrossData;
    else return <div />;
    let toChart = [];
    let initialAmt = 1000;
    let currAmt = initialAmt;
    let ind = 0;
    for (let signal of grossData) {
      let amountOper = (currAmt * signal.entryVolume) / 100;
      let volStop = 100;
      if (signal.reach1) {
        currAmt =
          currAmt +
          amountOper *
            (Number(signal.t1Volume) / 100) *
            (Number(signal.reach1) / 100);
        volStop -= Number(signal.t1Volume);
        console.log(
          `** T1 ${signal.entry} / ${amountOper} / ${signal.t1Volume} / ${signal.reach1}`,
        );
        console.log(` ${currAmt} / ${volStop} `);
      }
      if (signal.reach2) {
        currAmt =
          currAmt +
          amountOper *
            (Number(signal.t2Volume) / 100) *
            (Number(signal.reach2) / 100);
        volStop -= Number(signal.t2Volume);
      }
      if (signal.reach3) {
        currAmt =
          currAmt +
          amountOper *
            (Number(signal.t3Volume) / 100) *
            (Number(signal.reach3) / 100);
        volStop -= Number(signal.t3Volume);
      }
      if (signal.reachSL) {
        //if (Number(signal.stop) < Number(signal.entry))
        currAmt =
          currAmt +
          amountOper * (volStop / 100) * (Number(signal.reachSL) / 100);
        console.log(
          `** SL ${signal.entry} / ${amountOper} / ${signal.reachSL}`,
        );
        console.log(` ${currAmt} / ${volStop} `);
      }
      toChart.push({
        x: ind.toFixed(0),
        y: currAmt,
        label: signal.symbol + " " + signal.closeDay + " " + signal.closeHour,
      });
      ind++;
    }
    return (
      <VictoryChart
        width={700}
        height={500}
        theme={VictoryTheme.material}
        containerComponent={<VictoryVoronoiContainer responsive={false} />}
      >
        <VictoryLine
          labelComponent={<VictoryTooltip />}
          data={toChart}
          style={{
            data: { stroke: "#c43a31" },
            parent: { border: "1px solid #ccc" },
          }}
        />
      </VictoryChart>
    );
  };

  const calculate = (entry, target) => {
    if (Number(target) === 0) return 0;
    return _.round((100 * (Number(target) - Number(entry))) / Number(entry), 2);
  };

  const prepareChart = (signal) => {
    let tgt1Percent = calculate(
      signal.stopStart.entryEnd,
      signal.stopStart.takeProfit[0].target,
    );
    let tgt2Percent = calculate(
      signal.stopStart.entryEnd,
      signal.stopStart.takeProfit[1].target,
    );
    let tgt3Percent = calculate(
      signal.stopStart.entryEnd,
      signal.stopStart.takeProfit[2].target,
    );
    let tgtStop = calculate(
      signal.stopStart.entryEnd,
      signal.stopStart.stopLoss,
    );
    let result = {
      symbol: signal.symbol,
      entry: signal.stopStart.entryEnd,
      target1: signal.stopStart.takeProfit[0].target,
      reach1: signal.executed.profitTeleg[0] ? tgt1Percent : "",
      target2: signal.stopStart.takeProfit[1].target,
      reach2: signal.executed.profitTeleg[1] ? tgt2Percent : "",
      target3: signal.stopStart.takeProfit[2].target,
      reach3: signal.executed.profitTeleg[2] ? tgt3Percent : "",
      stop: signal.stopStart.stopLoss,
      reachSL: signal.executed.stopTeleg ? tgtStop : "",
      entryVolume: signal.stopStart.percentEntry,
      t1Volume: signal.stopStart.takeProfit[0].volume,
      t2Volume: signal.stopStart.takeProfit[1].volume,
      t3Volume: signal.stopStart.takeProfit[2].volume,
      closeDay: moment(signal.closedAt).format("DD/MM/YYYY"),
      closeHour: moment(signal.closedAt).format("kk:mm:ss"),
      createDay: moment(signal.createdAt).format("DD/MM/YYYY"),
      createHour: moment(signal.createdAt).format("kk:mm:ss"),
    };
    return result;
  };

  let _title = title();
  let [_list, _excelLine] = list();
  let _lineChart = <div />;
  if (chartGrossData.length > 0) _lineChart = lineChart();
  let excel = <div />;
  // if (excelRows && excelRows.length > 0) {
  //   excel = <SignalGroupExcel signals={excelRows} />;
  // }
  return (
    <div>
      {_excelLine} {_title} {_list} {excel} {_lineChart}
    </div>
  );
}

export default SignalGroupDetail;

// const excelRow = (signal) => {
//   let tgt1Percent = calculate(
//     signal.stopStart.entryEnd,
//     signal.stopStart.takeProfit[0].target,
//   );
//   let tgt2Percent = calculate(
//     signal.stopStart.entryEnd,
//     signal.stopStart.takeProfit[1].target,
//   );
//   let tgt3Percent = calculate(
//     signal.stopStart.entryEnd,
//     signal.stopStart.takeProfit[2].target,
//   );
//   let tgtStop = calculate(
//     signal.stopStart.entryEnd,
//     signal.stopStart.stopLoss,
//   );
//   let result = {
//     product: signal.product,
//     fund: signal.fund,
//     trader: signal.stopStart.issuer,
//     symbol: signal.symbol,
//     position: signal.position,
//     leverage: signal.leverage,
//     entry: signal.stopStart.entryEnd,
//     target1: signal.stopStart.takeProfit[0].target,
//     reach1: signal.executed.profitTeleg[0] ? tgt1Percent : "",
//     target2: signal.stopStart.takeProfit[1].target,
//     reach2: signal.executed.profitTeleg[1] ? tgt2Percent : "",
//     target3: signal.stopStart.takeProfit[2].target,
//     reach3: signal.executed.profitTeleg[2] ? tgt3Percent : "",
//     stop: signal.stopStart.stopLoss,
//     reachSL: signal.executed.stopTeleg ? tgtStop : "",
//     entryVolume: signal.stopStart.percentEntry,
//     t1Volume: signal.stopStart.takeProfit[0].volume,
//     t2Volume: signal.stopStart.takeProfit[1].volume,
//     t3Volume: signal.stopStart.takeProfit[2].volume,
//     closeDay: moment(signal.closedAt).format("DD/MM/YYYY"),
//     closeHour: moment(signal.closedAt).format("kk:mm:ss"),
//     createDay: moment(signal.createdAt).format("DD/MM/YYYY"),
//     createHour: moment(signal.createdAt).format("kk:mm:ss"),
//     raiseStop: signal.stopStart.raiseStop,
//     initSL: signal.stopStart.initStopLoss
//       ? signal.stopStart.initStopLoss
//       : signal.stopStart.stopLoss,
//   };
//   return result;
// };

// const handleExcelClick = (resultExcel) => {
//   setExcelRows(resultExcel);
// };
