import React, { Component } from "react";
import _ from "lodash";
import moment from "moment";
import axios from "axios";

import sysconfig from "../config";
import SignalTradesDetail from "../components/signalTradesDetail";

class SignalUserSum extends Component {
  constructor(props) {
    super(props);
    this.handleCloseClick = this.handleCloseClick.bind(this);
    this.getData = this.getData.bind(this);
    this.state = {
      msg: "",
      tradelist: "hide"
    };
  }

  getData(val) {
    //receive data from child component
    if (val === "hide") {
      this.setState({ tradelist: "hide" });
    }
  }

  handleCloseClick(changeEvent) {
    this.props.sendData("hide"); //send to parent
  }

  title() {
    return (
      <div className="font-weight-bold">
        <div className="row">
          <div className="col-md-6" align="left">
            {" "}
            <span className="ml-2">
              <strong>Users Performance Summary</strong>
            </span>
          </div>
          <div className="col-md-6" align="right">
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={event => this.handleCloseClick(event)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div className="row" style={{ fontSize: "small" }}>
          <div className="col-md-2"> Email</div>
          <div className="col-md-2" align="center">
            Gain USD
          </div>
          <div className="col-md-2" align="center">
            Gain BTC
          </div>
          <div className="col-md-1"> </div>
        </div>
      </div>
    );
  }

  List() {
    let listItems = [];
    let totBTC = 0;
    let totUSD = 0;
    for (let element of this.props.summary) {
      totBTC += element.gainBTC;
      totUSD += element.gainUSD;
      listItems.push(
        <div className="row" style={{ fontSize: "small" }}>
          <div className="col-md-2"> {element.email}</div>
          <div className="col-md-2" align="center">
            {_.round(element.gainUSD, 2)}
          </div>
          <div className="col-md-2" align="center">
            {" "}
            {_.round(element.gainBTC, 6)}
          </div>
          <div className="col-md-1">
            <span
              className="btn-link cursor-pointer"
              onClick={() => this.selectUser(element.email)}
            >
              ->
            </span>
          </div>
        </div>
      );
    }
    let totLine = (
      <div className="row" style={{ fontSize: "small" }}>
        <div className="col-md-2"> Total:</div>
        <div className="col-md-2" align="center">
          {_.round(totUSD, 2)}
        </div>
        <div className="col-md-2" align="center">
          {" "}
          {_.round(totBTC, 6)}
        </div>
      </div>
    );
    return [listItems, totLine];
  }

  selectUser(email) {
    this.setState({ email, tradelist: "show" });
    this.getUserDetail(email);
  }

  getUserDetail(email) {
    try {
      let _this = this;
      axios({
        method: "post",
        baseURL: sysconfig.EXECUTER_URI,
        url: "/signal/tradelist",
        data: {
          email,
          status: "FILLED",
          orderSource: "signal",
          symbol: this.props.filters.symbol,
          startDate: this.props.filters.startDate,
          endDate: this.props.filters.endDate,
          mode: this.props.filters.mode
        },
        headers: { "x-auth": this.props.token }
      })
        .then(function(response) {
          if (response.data) {
            _this.setState({ trades: response.data, btnEnabled: false, tradelist: "show" });
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    let title = this.title();
    let [userList, totLine] = this.List();
    let tradesDetail = <div />;
    if (this.state.trades && this.state.tradelist !== "hide") {
      tradesDetail = <SignalTradesDetail sendData={this.getData} trades={this.state.trades} />;
    }
    return (
      <div>
        <div className="presentation-div">
          {title} {userList} {tradesDetail} {totLine}
        </div>
      </div>
    );
  }
}

export default SignalUserSum;
