import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import ReactTooltip from "react-tooltip";
import validator from "validator";

import { selectUser, selectToken, selectPartner } from "../actions/root";
import ProductSelection from "./productSelection";
import { groupConstrainter } from "../utils/clientConstraint";
import sysconfig from "../config";

function General(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.activeUser);
  const token = useSelector((state) => state.activeToken);
  const [entry, setEntry] = useState(user.general.entryVolume);
  const [volumes, setVolumes] = useState(user.general.volumes);
  const [raiseStop, setRaiseStop] = useState(user.general.raiseStop);
  const [useLeaderDefault, setUseLeaderDefault] = useState(
    user.general.useLeaderDefault,
  );
  // const [acceptNewSymbolSignal, setAcceptNewSymbolSignal] = useState(
  //   user.general.acceptNewSymbolSignal
  // );
  const [lateEntry, setLateEntry] = useState(user.general.lateEntry);
  const [delegate, setDelegate] = useState(user.general.delegate);
  const [byMyself, setByMyself] = useState(
    user.general.delegate === "none" ? true : false,
  );
  const [status, setStatus] = useState(
    user.status === "activeOn" ? true : false,
  );
  const [userkey, setUserKey] = useState(user.userkey);
  const [target1, setTarget1] = useState(
    volumes && volumes.length > 0 ? volumes[0] : 40,
  );
  const [target2, setTarget2] = useState(
    volumes && volumes.length > 0 ? volumes[1] : 30,
  );
  const [target3, setTarget3] = useState(
    volumes && volumes.length > 0 ? volumes[2] : 30,
  );
  const [whiteList, setWhiteList] = useState(
    user.general.allowPairs.join(", "),
  );
  const [blackList, setBlackList] = useState(
    user.general.excludePairs.join(", "),
  );
  const [btnSubmit, setBtnSubmit] = useState("Save");
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [msg, setMsg] = useState("");

  const handleSaveClick = (event) => {
    try {
      let msg = validate();
      if (msg !== "OK") return alert("Error: " + msg);
      setIsBtnDisabled(true);
      setBtnSubmit("Wait...");
      let data = {
        email: user.email,
        delegate: delegate,
        useLeaderDefault: useLeaderDefault,
        volumes: [Number(target1), Number(target2), Number(target3)],
        entryVolume: Number(entry),
        raiseStop: raiseStop,
        lateEntry: lateEntry,
        status: status,
        whiteList,
        blackList,
      };

      axios({
        method: "patch",
        baseURL: sysconfig.EXECUTER_URI,
        url: "/user/general",
        headers: { "x-auth": token },
        data,
      })
        .then(function (response) {
          setBtnSubmit("Save");
          setIsBtnDisabled(false);
          setMsg("General account data saved!");
        })
        .catch(function (error) {
          alert("Error: " + error.response.data.message);
          setBtnSubmit("Save");
          setIsBtnDisabled(false);
          setMsg("");
        });
    } catch (e) {
      console.log(e);
      alert("Error: " + e.message);
    }
  };

  const handleApiClick = (event) => {
    try {
      let data = {
        email: user.email,
      };
      axios({
        method: "post",
        baseURL: sysconfig.EXECUTER_URI,
        url: "/user/createApiKey",
        headers: { "x-auth": token },
        data,
      })
        .then(function (response) {
          setUserKey(response.data);
          setMsg("New api key saved to your account!");
        })
        .catch(function (error) {
          alert("Error: " + error.response.data.message);
          setMsg("");
        });
    } catch (e) {
      console.log(e);
      alert("Error: " + e.message);
    }
  };

  const handleStatusChange = (event) => {
    setStatus(!status);
  };

  const handleProductChange = (event) => {
    setUseLeaderDefault(!useLeaderDefault);
  };

  const onInputChange = (event) => {
    switch (event.target.id) {
      case "entry":
        setEntry(event.target.value);
        break;
      case "target1":
        setTarget1(event.target.value);
        break;
      case "target2":
        setTarget2(event.target.value);
        break;
      case "target3":
        setTarget3(event.target.value);
        break;
      case "whitelist":
        setWhiteList(event.target.value.toUpperCase());
        break;
      case "blacklist":
        setBlackList(event.target.value.toUpperCase());
        break;
      default:
        setDelegate(event.target.value);
        break;
    }
  };

  const handleOptionChange = (event) => {
    if (event.target.id === "raiseStop") {
      setRaiseStop(event.target.value);
    }
    if (event.target.id === "lateEntry") {
      setLateEntry(!lateEntry);
    }
    // if (event.target.id === "acceptNewSymbolSignal") {
    //   setAcceptNewSymbolSignal(!acceptNewSymbolSignal);
    // }
    if (event.target.id === "byMyself") {
      if (!byMyself) {
        setDelegate("none");
        //setAcceptNewSymbolSignal(false);
      } else if (delegate === "none") setDelegate("");
      setByMyself(!byMyself);
    }
  };

  const panel = () => {
    // let tradeMode = (
    //   <div className="form-group">
    //     <input
    //       className="form-check-input"
    //       type="checkbox"
    //       value={byMyself}
    //       disabled={groupConstrainter(user)}
    //       id="byMyself"
    //       name="byMyself"
    //       checked={byMyself}
    //       onChange={handleOptionChange}
    //     />
    //     <small
    //       className="form-check-label"
    //       data-tip="Check if you want to trade by yourself.
    //         <br/>Uncheck to follow a signals leader"
    //     >
    //       I do not want to follow a leader
    //     </small>
    //   </div>
    // );
    let delegateLine = <span />;
    delegateLine = (
      <div className="form-group">
        <label>Leader to Follow</label>
        <input
          className="form-control"
          id="delegate"
          placeholder="Username to follow"
          disabled={user.general.delegate === "Criptomaniacos"}
          value={delegate}
          onChange={onInputChange}
        />
      </div>
    );
    let prdSelection = <span />;
    if (user && user.general)
      prdSelection = <ProductSelection email={user.email} />;
    let _status = (
      <div className="custom-control custom-switch">
        <input
          type="checkbox"
          className="custom-control-input"
          id="status"
          checked={status}
          onChange={handleStatusChange}
        />
        <label className="custom-control-label" for="status">
          <small>Turn Bot ON/OFF</small>
        </label>
      </div>
    );
    let requestKey;
    if (delegate === "none")
      requestKey = (
        <div className="form-group">
          <br />
          <span
            className="btn-link cursor-pointer"
            onClick={(event) => handleApiClick(event)}
          >
            Create API key for external app
          </span>
          <br />
          {userkey}
        </div>
      );

    let _useLeaderDefault = (
      <div className="custom-control custom-switch">
        <input
          type="checkbox"
          className="custom-control-input"
          id="useLeaderDefault"
          checked={useLeaderDefault}
          onChange={handleProductChange}
        />
        <label className="custom-control-label" for="useLeaderDefault">
          <small>Use leader volumes and stop definitions</small>
        </label>
      </div>
    );

    let _entryVolume = (
      <div className="form-group">
        <small data-tip="Default % for your entries">Entry Volume %</small>
        <input
          className="form-control form-control-sm w-25"
          id="entry"
          placeholder="entry %"
          value={entry}
          onChange={onInputChange}
        />
      </div>
    );

    let _exitVolumes = (
      <span>
        <small data-tip="Default % of your exits on targets. Must sum 100%">
          Exit volumes (%) for targets 1 .. 3
        </small>
        <div className="form-inline">
          <input
            className="form-control form-control-sm w-25"
            id="target1"
            placeholder="target 1"
            value={target1}
            onChange={onInputChange}
          />
          <input
            className="form-control form-control-sm w-25"
            id="target2"
            placeholder="target 2"
            value={target2}
            onChange={onInputChange}
          />
          <input
            className="form-control form-control-sm w-25"
            id="target3"
            placeholder="target 3"
            value={target3}
            onChange={onInputChange}
          />
        </div>
      </span>
    );

    let _lateEntry = <span />;
    // if (delegate !== "none" && delegate !== "")
    //   _lateEntry = (
    //     <div className="form-group">
    //       <input
    //         className="form-check-input"
    //         type="checkbox"
    //         value={lateEntry}
    //         id="lateEntry"
    //         name="lateEntry"
    //         checked={lateEntry}
    //         onChange={handleOptionChange}
    //       />
    //       <small
    //         className="form-check-label"
    //         data-tip="Allow riskier entries after slippage control .
    //             <br/>but try to not miss a signal."
    //       >
    //         Allow entry after slippage control
    //       </small>
    //     </div>
    //   );

    let _raiseStop = (
      <div class="form-group">
        <br />
        <small for="stopinput">Raise StopLoss</small>
        <select
          className="form-control form-control-sm w-75"
          id="raiseStop"
          value={raiseStop}
          onChange={handleOptionChange}
        >
          <option id="manual" value="manual" selected={raiseStop === "manual"}>
            Manually
          </option>
          <option id="phased" value="phased" selected={raiseStop === "phased"}>
            Phased
          </option>
          <option
            id="phased2"
            value="phased2"
            selected={raiseStop === "phased2"}
          >
            Phased II
          </option>
        </select>
      </div>
    );
    // <option
    //     id="trailing"
    //     value="trailing"
    //     selected={raiseStop === "trailing"}
    //   >
    //     Trailing
    //   </option>

    let selfConfigs = <div />;
    if (!useLeaderDefault) {
      selfConfigs = (
        <div className="bg-light text-dark">
          <br />
          <center>My Default Configs</center>
          {_entryVolume}
          {_exitVolumes}
          {_raiseStop}
        </div>
      );
    }

    let _whitelist = (
      <div className="form-group">
        <label>Whitelisted Symbols</label>
        <textarea
          className="form-control"
          id="whitelist"
          rows="5"
          placeholder="Type in comma separated symbols"
          value={whiteList}
          onChange={onInputChange}
        />
      </div>
    );
    let _blacklist = (
      <div className="form-group">
        <label>Blacklisted Symbols</label>
        <textarea
          className="form-control"
          id="blacklist"
          rows="5"
          placeholder="Type in comma separated symbols"
          value={blackList}
          onChange={onInputChange}
        />
      </div>
    );

    return (
      <div>
        <div className="w-50">
          <center>
            <h5>General Settings</h5>
          </center>
          {_status}
          <br />
          {delegateLine}
          {prdSelection}
          {requestKey}
          {_lateEntry}
          {_useLeaderDefault}
          {selfConfigs}
        </div>
        <br />
        <div className="form-group">
          <br />
          <button
            type="button"
            className="btn btn-primary"
            onClick={(event) => handleSaveClick()}
          >
            {btnSubmit}
          </button>
        </div>
        <div className="text-info mt-2">{msg}</div>
      </div>
    );
    // <div className="row">
    //       <div className="col-md-5">{_whitelist}</div>
    //       <div className="col-md-5">{_blacklist}</div>
    //     </div>
  };

  const validate = () => {
    let result = "OK";
    if (!entry) return result;
    if (
      !validator.isNumeric(entry.toString()) ||
      !validator.isNumeric(target1.toString()) ||
      !validator.isNumeric(target2.toString()) ||
      !validator.isNumeric(target3.toString())
    ) {
      return "Invalid Number field";
    }
    if (Number(entry) > 100) {
      return "Entry begin value must be smaller than 100%";
    }
    if (Number(target1) + Number(target2) + Number(target3) !== 100) {
      return "Targets must sum 100%";
    }

    return result;
  };

  let _panel = panel();
  return (
    <div className="row">
      <div className="col-md-2" />
      <div className="col-md-10">
        {_panel}
        <ReactTooltip place="top" type="info" multiline="true" effect="float" />
      </div>
    </div>
  );
}

export default General;

// if (delegate !== "none" && delegate !== "")
//   acceptSymbolLine = (
//     <div className="form-group">
//       <input
//         className="form-check-input"
//         type="checkbox"
//         value={acceptNewSymbolSignal}
//         id="acceptNewSymbolSignal"
//         name="acceptNewSymbolSignal"
//         checked={acceptNewSymbolSignal}
//         onChange={handleOptionChange}
//       />
//       <small
//         className="form-check-label"
//         data-tip="Add new pair when Leader emit a signal for a non-existing symbol in your account.
//           <br/>Uncheck if you want to add signals manually"
//       >
//         Accept all Leader signals
//       </small>
//     </div>
//   );
