import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import _ from "lodash";

import sysconfig from "../config";

function UserSignalOrders(props) {
  const [userDetail, setUserDetail] = useState("hide");
  const [trades, setTrades] = useState([]);

  //equivalent to didMount
  useEffect(() => {
    getOrders(props.userSigId);
  }, []);

  const getOrders = (userSigId) => {
    axios({
      method: "post",
      baseURL: sysconfig.EXECUTER_URI,
      url: "/usersignal/orders",
      data: {
        userSignalId: userSigId,
      },
      headers: { "x-auth": props.token },
    })
      .then(function (response) {
        if (response.data) {
          console.log("**getOrders Trades", response.data);
          setTrades(response.data);
        }
      })
      .catch(function (error) {
        console.log("Err sigList:", error);
      });
  };

  const title = () => {
    return (
      <div className="font-weight-bold">
        <div className="row">
          <div className="col-md-12" align="right">
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={(event) => props.sendData("hide")}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div className="row" align="right" style={{ fontSize: "small" }}>
          <div className="col-md-1"> Symbol</div>
          <div className="col-md-1" align="center">
            Side
          </div>
          <div className="col-md-1"> Status</div>
          <div className="col-md-1"> Price</div>
          <div className="col-md-1"> Quantity</div>
          <div className="col-md-1"> Market value</div>
          <div className="col-md-2" align="left">
            {" "}
            Date
          </div>
        </div>
      </div>
    );
  };

  const List = () => {
    let listItems = [];
    for (let element of trades) {
      let market = "USDT";
      let tokenAmount;
      let marketAmount;
      tokenAmount = element.quantity;
      marketAmount = element.quantity * element.price;
      listItems.push(
        <div align="right" style={{ fontSize: "small" }}>
          <div className="row">
            <div className="col-md-1"> {element.symbol}</div>
            <div className="col-md-1" align="center">
              {element.side}
            </div>
            <div className="col-md-1"> {element.status}</div>
            <div className="col-md-1"> {_.round(element.price, 8)}</div>
            <div className="col-md-1"> {_.round(tokenAmount, 8)}</div>
            <div className="col-md-1"> {_.round(marketAmount, 8)}</div>
            <div className="col-md-2" align="left">
              {moment(element.updatedAt).format("YY-MM-DD kk:mm")}
            </div>
          </div>
        </div>,
      );
    }

    return listItems;
  };

  let detail = List();
  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <hr style={{ color: "gray", backgroundColor: "gray", height: 3 }} />
          {title()} {detail}
          <hr style={{ color: "gray", backgroundColor: "gray", height: 3 }} />
        </div>
      </div>
    </div>
  );
}

export default UserSignalOrders;
