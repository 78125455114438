import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

import Menu from "../components/menu";
import Footer from "../components/footer";
import User from "./user";
import UserData from "../containers/userdata";
import SignalTrades from "../containers/signaltrades";
import SignalGroup from "../containers/sigGroup";
import SignalSummary from "../containers/signalsummary";
import FollowersBalance from "../containers/followersBalance";
import sysconfig from "../config";
import { groupConstrainter, roleConstrainter } from "../utils/clientConstraint";

function ReportsMenu(props) {
  const [userData, setUserData] = useState(false);
  const [sigGroup, setSigGroup] = useState(false);
  const [sumResult, setSumResult] = useState(false);
  const [sumBalance, setBalance] = useState(false);
  const user = useSelector((state) => state.activeUser);
  const token = useSelector((state) => state.activeToken);
  const partner = useSelector((state) => state.partner);

  const sideMenu = () => {
    let cpUserData = <div />;
    let cpSigGroup = <div />;
    let cpSumResult = <div />;
    let cpBalance = <div />;

    cpUserData = (
      <div className="row" style={{ fontSize: "small" }}>
        <div className="col-md-12 row-bordered">
          <span
            className="btn-link cursor-pointer text-secondary"
            onClick={() => {
              setUserData(true);
              setSigGroup(false);
              setSumResult(false);
              setBalance(false);
            }}
          >
            User Data
          </span>
        </div>
      </div>
    );

    cpSigGroup = (
      <div className="row" style={{ fontSize: "small" }}>
        <div className="col-md-12 row-bordered">
          <span
            className="btn-link cursor-pointer text-secondary"
            onClick={() => {
              setUserData(false);
              setSigGroup(true);
              setSumResult(false);
              setBalance(false);
            }}
          >
            Signals Map
          </span>
        </div>
      </div>
    );

    cpSumResult = (
      <div className="row" style={{ fontSize: "small" }}>
        <div className="col-md-12 row-bordered">
          <span
            className="btn-link cursor-pointer text-secondary"
            onClick={() => {
              setUserData(false);
              setSigGroup(false);
              setSumResult(true);
              setBalance(false);
            }}
          >
            Summary
          </span>
        </div>
      </div>
    );

    cpBalance = (
      <div className="row" style={{ fontSize: "small" }}>
        <div className="col-md-12 row-bordered">
          <span
            className="btn-link cursor-pointer text-secondary"
            onClick={() => {
              setUserData(false);
              setSigGroup(false);
              setSumResult(false);
              setBalance(true);
            }}
          >
            Balances
          </span>
        </div>
      </div>
    );

    let result = (
      <div className="col-md-1">
        <p className="mt-5">
          <font>REPORTS</font>
        </p>
        {cpUserData}
        {cpSigGroup}
        {cpSumResult}
        {cpBalance}
      </div>
    );
    return result;
  };

  const render = () => {
    let role = user ? user.role : false;
    let sidemenu = sideMenu();
    let showUserData = userData ? <UserData /> : <div />;
    let showSigGroup = sigGroup ? <SignalGroup /> : <div />;
    let showSumResult = sumResult ? <SignalSummary /> : <div />;
    let showBalances = sumBalance ? <FollowersBalance /> : <div />;
    let toRender = <div />;
    if (user && user.status !== "registered") {
      toRender = (
        <div className="row">
          {sidemenu}
          <div className="col-md-11">
            <br />
            {showUserData} {showSigGroup} {showSumResult} {showBalances}
          </div>
        </div>
      );
    }
    return (
      <span>
        <Menu logged={role} source={partner} user={user} />
        <div className="container-fluid">
          <User
            email={user.email}
            source=""
            showSelection={false}
            showOnOff={false}
            noShow={true}
          />
          {toRender}
        </div>
        <br />
        <br />
        <Footer />
      </span>
    );
  };
  if (!token) {
    window.location.replace(sysconfig.HOME_URL);
    return null;
  }
  return render();
}

//promote Login from a component to a container with added props activeToken
export default ReportsMenu;
