import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Menu from "../components/menu";
import Footer from "../components/footer";
import User from "./user";
import General from "./general";
import SignalGroup from "../containers/sigGroup";
import UserSignalsReport from "./userSignalsReport.js";
//import LeaderConfigs from "./leaderConfigs";
//import UserSymbol from "./usersymbol";
//import SignalTrades from "../containers/signaltrades";
import TradeOrder from "./tradeorder";
import SignalScreen from "./signalScreen";
import sysconfig from "../config";
import { groupConstrainter, roleConstrainter } from "../utils/clientConstraint";

function SignalsMenu(props) {
  const [symbol, setSymbol] = useState(false);
  const [general, setGeneral] = useState(false);
  const [usersig, setUsersig] = useState(false);
  const [newsig, setNewSig] = useState(false);
  const [terminal, setTerminal] = useState(false);
  const [intro, setIntro] = useState(false);
  const user = useSelector((state) => state.activeUser);
  const token = useSelector((state) => state.activeToken);
  const partner = useSelector((state) => state.partner);

  const sideMenu = () => {
    let cpNew = <div />;
    let cpSymbol = <div />;
    let cpGeneral = <div />;
    let cpUsersig = <div />;
    let cpTerminal = <div />;

    // cpSymbol = (
    //   <div className="row" style={{ fontSize: "small" }}>
    //     <div className="col-md-12 row-bordered">
    //       <span
    //         className="btn-link cursor-pointer text-secondary"
    //         onClick={() => {
    //           setIntro(false);
    //           setNewSig(false);
    //           setSymbol(true);
    //           setGeneral(false);
    //           setUsersig(false);
    //           setTerminal(false);
    //         }}
    //       >
    //         Terminal
    //       </span>
    //     </div>
    //   </div>
    // );

    if (roleConstrainter(user, "trader"))
      cpNew = (
        <div className="row" style={{ fontSize: "small" }}>
          <div className="col-md-12 row-bordered">
            <span
              className="btn-link cursor-pointer text-secondary"
              onClick={() => {
                setIntro(false);
                setNewSig(true);
                setSymbol(false);
                setGeneral(false);
                setUsersig(false);
                setTerminal(false);
              }}
            >
              Create
            </span>
          </div>
        </div>
      );

    cpUsersig = (
      <div className="row" style={{ fontSize: "small" }}>
        <div className="col-md-12 row-bordered">
          <span
            className="btn-link cursor-pointer text-secondary"
            onClick={() => {
              setIntro(false);
              setNewSig(false);
              setSymbol(false);
              setGeneral(false);
              setUsersig(true);
              setTerminal(false);
            }}
          >
            Operations
          </span>
        </div>
      </div>
    );

    cpTerminal = (
      <div className="row" style={{ fontSize: "small" }}>
        <div className="col-md-12 row-bordered">
          <span
            className="btn-link cursor-pointer text-secondary"
            onClick={() => {
              setIntro(false);
              setNewSig(false);
              setSymbol(false);
              setGeneral(false);
              setUsersig(false);
              setTerminal(true);
            }}
          >
            Terminal
          </span>
        </div>
      </div>
    );

    if (!roleConstrainter(user, "trader"))
      cpGeneral = (
        <div className="row" style={{ fontSize: "small" }}>
          <div className="col-md-12 row-bordered">
            <span
              className="btn-link cursor-pointer text-secondary"
              onClick={() => {
                setIntro(false);
                setNewSig(false);
                setSymbol(false);
                setGeneral(true);
                setUsersig(false);
                setTerminal(false);
              }}
            >
              Settings
            </span>
          </div>
        </div>
      );

    let result = (
      <div className="col-md-1">
        <p className="mt-5">
          <span
            className="btn-link cursor-pointer text-dark"
            onClick={() => {
              setSymbol(false);
              setNewSig(false);
              setGeneral(false);
              setUsersig(false);
              setTerminal(false);
              setIntro(true);
            }}
          >
            SPOT
          </span>
        </p>
        {cpNew}
        {cpUsersig}
        {cpTerminal}
        {cpGeneral}
      </div>
    );
    //not shwoing cpsymbol until Terminal orders are updated
    return result;
  };

  const introduction = () => {
    if (!intro) return <div />;
    let following =
      "You are not following a leader operations. Refer to Plans menu at https://aperium.io";
    if (user.general.delegate !== "none")
      following = `You are following ${user.general.delegate} operations`;
    return (
      <div className="row">
        <div className="col-md-1" />
        <div className="col-md-11">
          <p className="text-secondary font-italic">
            {following} <br />
            Aperium provides Managed strategies, with changing stop along the
            operation life cycle. <br />
            At this space you follow your trading leader calls. <br />
            Configure your preferences on Settings and monitor your reports.{" "}
            <br />
          </p>
        </div>
      </div>
    );
  };

  const render = () => {
    let role = user ? user.role : false;
    let groupAccount = "";
    if (user) {
      role = user.role;
      if (user.general.groupAccount) groupAccount = user.general.groupAccount;
    }
    let sidemenu = sideMenu();
    let showIntroduction = introduction();
    // let showSymbol = symbol ? <UserSymbol /> : <div />;
    let showTerminal = terminal ? <TradeOrder /> : <div />;
    let showUsersig = <div />;
    if (usersig) {
      if (roleConstrainter(user, "user") || roleConstrainter(user, "admin"))
        showUsersig = <UserSignalsReport />;
      if (roleConstrainter(user, "trader")) showUsersig = <SignalGroup />;
      if (roleConstrainter(user, "admin"))
        showUsersig = (
          <div>
            <SignalGroup />
            <UserSignalsReport />
          </div>
        );
    }
    let showSignalScreen = newsig ? <SignalScreen /> : <div />;
    let showGeneral = <div />;
    if (general && !groupAccount) {
      // if (roleConstrainter(user, "trader")) showGeneral = <LeaderConfigs />;
      // else
      showGeneral = <General />;
    }
    let toRender = <div />;
    if (user && user.status !== "registered") {
      toRender = (
        <div className="row">
          {sidemenu}
          <div className="col-md-11">
            <br />
            {showIntroduction} {showSignalScreen} {showUsersig} {showTerminal}{" "}
            {showGeneral}
          </div>
        </div>
      );
    }
    return (
      <span>
        <Menu logged={role} source={partner} user={user} />
        <div className="container-fluid">
          <User
            email={user.email}
            source=""
            fromMenu="SPOT"
            showSelection={false}
          />
          {toRender}
        </div>
        <br />
        <br />
        <Footer />
      </span>
    );
  };
  if (!token) {
    window.location.replace(sysconfig.HOME_URL);
    return null;
  }
  return render();
}

//promote Login from a component to a container with added props activeToken
export default SignalsMenu;
