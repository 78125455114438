import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import _ from "lodash";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//import SignalGroupExcel from "../sigGroupExcel";
import sysconfig from "../../config";

function RebalanceDetail(props) {
  const [excelRows, setExcelRows] = useState([]);
  //equivalent to didMount
  useEffect(() => {
    //sigList();
  }, []);

  const title = () => {
    return (
      <div>
        <div className="row">
          <div className="col-md-12" align="right">
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={event => props.sendData("hide")}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>
    );
  };

  const list = () => {
    let result = [];
    let resultExcel = [];
    for (let ptf of props.ptfs) {
      let titlerow = `${ptf.name} Portfolio: ${ptf.ptfInUSD.toFixed(4)}`;
      if (ptf.btcInUSD !== 0) titlerow += ` BTC: ${ptf.btcInUSD.toFixed(4)} `;
      titlerow += ` Data: ${moment(ptf.createdAt).format("YY-MM-DD hh:mm")}`;
      result.push(
        <div className="row" style={{ fontSize: "small" }}>
          <b>{titlerow}</b>
        </div>
      );
      for (let asset of ptf.assets) {
        if (asset.asset === ptf.assets[0].asset) {
          result.push(
            <div className="row" style={{ fontSize: "small" }}>
              <div className="col-md-2">Asset</div>
              <div className="col-md-2">Price</div>
              <div className="col-md-2">Volume</div>
              <div className="col-md-2">in USD</div>
            </div>
          );
        }
        result.push(
          <div className="row" style={{ fontSize: "small" }}>
            <div className="col-md-2">{asset.asset}</div>
            <div className="col-md-2">{asset.price.toFixed(4)}</div>
            <div className="col-md-2">{asset.currVolume.toFixed(4)}</div>
            <div className="col-md-2">{(asset.currVolume * asset.price).toFixed(4)}</div>
          </div>
        );
        resultExcel.push(excelRow(ptf.name, ptf.createdAt, asset));
      }
    }
    let lastLine = (
      <div className="col-md-2">
        <br />
        <span className="btn-link cursor-pointer" onClick={event => handleExcelClick(resultExcel)}>
          Excel export
        </span>
      </div>
    );
    return [result, lastLine];
  };

  const calculate = (entry, target) => {
    if (Number(target) === 0) return 0;
    return _.round(100 * (Number(target) - Number(entry)) / Number(entry), 2);
  };
  const excelRow = (name, createdAt, asset) => {
    let result = {
      name,
      createdAt,
      asset: asset.asset,
      price: asset.price,
      currVolume: asset.currVolume
    };
    return result;
  };

  const handleExcelClick = resultExcel => {
    setExcelRows(resultExcel);
  };

  let _title = title();
  let [_list, _excelLine] = list();
  let excel = <div />;
  // if (excelRows && excelRows.length > 0) {
  //   excel = <SignalGroupExcel signals={excelRows} />;
  // }
  return (
    <div>
      {_excelLine} {_title} {_list} {excel}
    </div>
  );
}

export default RebalanceDetail;
