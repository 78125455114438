import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

import Menu from "../components/menu";
import Footer from "../components/footer";
import User from "./user";
import BotStrategyList from "../containers/automation/botStrategyList";
import BotStrategyCliList from "../containers/automation/botStrategyCliList";
import UserStrategies from "../containers/automation/userStrategies";
import SignalTrades from "../containers/signaltrades";
import sysconfig from "../config";

function AutomationMenu(props) {
  const [publish, setPublish] = useState(false);
  const [select, setSelect] = useState(false);
  const [trades, setTrades] = useState(false);
  const [list, setList] = useState(false);
  const [intro, setIntro] = useState(false);
  const user = useSelector(state => state.activeUser);
  const token = useSelector(state => state.activeToken);
  const partner = useSelector(state => state.partner);

  const sideMenu = () => {
    let cpPublish = <div />;
    let cpSelect = <div />;
    let cpList = <div />;
    let cpTrades = <div />;
    if (user.role === "trader")
      cpPublish = (
        <div className="row" style={{ fontSize: "small" }}>
          <div className="col-md-12 row-bordered">
            <span
              className="btn-link cursor-pointer text-secondary"
              onClick={() => {
                setIntro(false);
                setPublish(true);
                setSelect(false);
                setTrades(false);
                setList(false);
              }}
            >
              Publish
            </span>
          </div>
        </div>
      );
    if (user.role !== "trader")
      cpSelect = (
        <div className="row" style={{ fontSize: "small" }}>
          <div className="col-md-12 row-bordered">
            <span
              className="btn-link cursor-pointer text-secondary"
              onClick={() => {
                setIntro(false);
                setPublish(false);
                setSelect(true);
                setTrades(false);
                setList(false);
              }}
            >
              Select
            </span>
          </div>
        </div>
      );

    if (user.role !== "trader")
      cpList = (
        <div className="row" style={{ fontSize: "small" }}>
          <div className="col-md-12 row-bordered">
            <span
              className="btn-link cursor-pointer text-secondary"
              onClick={() => {
                setIntro(false);
                setPublish(false);
                setSelect(false);
                setTrades(false);
                setList(true);
              }}
            >
              List
            </span>
          </div>
        </div>
      );

    cpTrades = (
      <div className="row" style={{ fontSize: "small" }}>
        <div className="col-md-12 row-bordered">
          <span
            className="btn-link cursor-pointer text-secondary"
            onClick={() => {
              setIntro(false);
              setPublish(false);
              setSelect(false);
              setTrades(true);
              setList(false);
            }}
          >
            Trades
          </span>
        </div>
      </div>
    );

    let result = (
      <div className="col-md-1">
        <p className="mt-5">
          <span
            className="btn-link cursor-pointer text-dark"
            onClick={() => {
              setIntro(true);
              setPublish(false);
              setSelect(false);
              setTrades(false);
              setList(false);
            }}
          >
            BOTS
          </span>
        </p>
        {cpPublish}
        {cpSelect}
        {cpList}
        {cpTrades}
      </div>
    );

    return result;
  };

  const introduction = () => {
    if (!intro) return <div />;
    return (
      <div className="row">
        <div className="col-md-1" />
        <div className="col-md-11">
          <br />
          <p className="text-secondary font-italic">
            At this space trading leaders publish their smart automated strategies. <br />
            Regular users may select those strategies from the menu. <br />
            And click Play to enable a continued execution. <br />
            At menu List you may check your following strategies and unfollow as desired.
            <br />
          </p>
        </div>
      </div>
    );
  };

  const render = () => {
    let role = user ? user.role : false;
    let showTrades = trades ? <SignalTrades product="indicators, terminal" /> : <div />;
    let showPublish = publish ? <BotStrategyList /> : <div />;
    let showSelect = select ? <BotStrategyCliList /> : <div />;
    let showList = list ? <UserStrategies /> : <div />;
    let showIntroduction = introduction();
    let sidemenu = sideMenu();
    let toRender = <div />;
    if (user && user.status !== "registered") {
      toRender = (
        <div className="row">
          {sidemenu}
          <div className="col-md-11">
            {showIntroduction} {showPublish} {showSelect} {showList}
            {showTrades}
          </div>
        </div>
      );
    }
    return (
      <span>
        <Menu logged={role} source={partner} user={user} />
        <div className="container-fluid">
          <User
            email={user.email}
            source=""
            showSelection={false}
            showOnOff={false}
            noShow={true}
          />
          {toRender}
        </div>
        <br />
        <br />
        <Footer />
      </span>
    );
  };
  if (!token) {
    window.location.replace(sysconfig.HOME_URL);
    return null;
  }
  return render();
}

//promote Login from a component to a container with added props activeToken
export default AutomationMenu;
