import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import axios from "axios";

import sysconfig from "../config";
import BalanceList from "../components/balancelist";
import BalanceShow from "../components/balanceshow";
import DustConvert from "../components/dustconvert";
import SelectKeys from "../components/selectKeys";
import { groupConstrainter } from "../utils/clientConstraint";

class BalanceReport extends Component {
  constructor(props) {
    super(props);
    this.getData = this.getData.bind(this);
    this.getKeys = this.getKeys.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleListClick = this.handleListClick.bind(this);
    this.getCurrBalance = this.getCurrBalance.bind(this);
    this.handleCheckChange = this.handleCheckChange.bind(this);
    this.state = {
      currBalance: "",
      balancelist: "hide",
      balanceshow: "hide",
      showReload: false,
      btnEnabled: false,
      market: "spot",
      msg: "",
    };
  }

  getKeys(val) {
    //receive data from child component
    this.setState({
      exchange: val.exchange,
      subaccount: val.subaccount,
    });
  }

  getCurrBalance() {
    this.setState({ showReload: true });
    let email = this.props.user.email;
    let exchange = this.state.exchange ? this.state.exchange : "binance";
    let subaccount = this.state.subaccount ? this.state.subaccount : "main";
    var _this = this;
    axios({
      method: "post",
      baseURL: sysconfig.EXECUTER_URI,
      url: "/balance/get",
      data: { email, exchange, subaccount, market: this.state.market },
      headers: { "x-auth": this.props.token },
    })
      .then(function (response) {
        if (response.status === 200) {
          _this.state.currBalance = response.data;
          _this.state.showReload = false;
          _this.state.btnEnabled = true;
          _this.state.balanceshow = "show";
        }
      })
      .catch(function (error) {
        console.log(error);
        alert("Error: " + error.response.data.message);
      });
  }

  btns() {
    let _selectkeys = groupConstrainter(this.props.user) ? (
      <div />
    ) : (
      <SelectKeys user={this.props.user} symbol="" sendData={this.getKeys} />
    );
    let btns = (
      <div>
        <div className="row">
          <div className="col-md-3">{_selectkeys}</div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <br />
            <button
              type="button"
              className="btn btn-secondary btn-sm cursor-pointer"
              id="get"
              onClick={(event) => this.getCurrBalance(event)}
            >
              Get Balance
            </button>
          </div>
          <div className="col-md-2">
            <br />
            <div className="form-group form-group-sm form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                id="checkSpot"
                value="spot"
                checked={this.state.market === "spot"}
                onChange={this.handleCheckChange}
              />
              <label className="form-check-label">Spot</label>
            </div>
            <div className="form-group form-group-sm form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                id="checkFutures"
                value="futures"
                checked={this.state.market === "futures"}
                onChange={this.handleCheckChange}
              />
              <label className="form-check-label">Futures</label>
            </div>
          </div>
          <div className="col-md-2">
            <br />
            <button
              type="button"
              className="btn btn-secondary btn-sm cursor-pointer"
              id="save"
              disabled={!this.state.btnEnabled}
              onClick={(event) => this.handleSaveClick(event)}
            >
              Save Snapshot
            </button>
            <p className="text-info"> {this.state.msg}</p>
          </div>
          <div className="col-md-2">
            <br />
            <button
              type="button"
              className="btn btn-secondary btn-sm cursor-pointer"
              id="list"
              onClick={(event) => this.handleListClick(event)}
            >
              List Snapshots
            </button>
          </div>
          <div className="col-md-4">
            <br />
            <DustConvert
              user={this.props.user}
              token={this.props.token}
              currBalance={this.state.currBalance}
              market={this.state.market}
            />
          </div>
        </div>
      </div>
    );
    return btns;
  }

  handleCheckChange(changeEvent) {
    this.setState({
      market: changeEvent.target.value,
    });
  }

  handleSaveClick(event) {
    try {
      let _this = this;
      let exchange = this.state.exchange ? this.state.exchange : "binance";
      let subaccount = this.state.subaccount ? this.state.subaccount : "main";
      axios({
        method: "post",
        baseURL: sysconfig.EXECUTER_URI,
        url: "/balance/add",
        data: {
          exchange,
          subaccount,
          email: this.props.user.email,
          market: this.state.market,
        },
        headers: { "x-auth": this.props.token },
      })
        .then(function (response) {
          if (response.status === 200) {
            _this.setState({
              msg: "Saved",
              btnEnabled: false,
              balancelist: "hide",
            });
          }
        })
        .catch(function (error) {
          alert("Error: " + error.response.data.message);
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  }

  handleListClick(event) {
    try {
      this.setState({ showReload: true });
      let _this = this;
      axios({
        method: "post",
        baseURL: sysconfig.EXECUTER_URI,
        url: "/balance/list",
        data: {
          email: this.props.user.email,
          market: this.state.market,
        },
        headers: { "x-auth": this.props.token },
      })
        .then(function (response) {
          if (response.status === 200) {
            _this.setState({
              balances: response.data,
              balancelist: "show",
              showReload: false,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
          alert("Error: " + error.response.data.message);
        });
    } catch (e) {
      console.log(e);
    }
  }

  showBalance() {
    let details = <div />;
    if (
      this.state.currBalance &&
      this.state.currBalance.length > 0 &&
      this.state.balanceshow === "show"
    ) {
      details = (
        <BalanceShow
          sendData={this.getData}
          currBalance={this.state.currBalance}
        />
      );
    }
    return details;
  }

  getData(val) {
    //receive data from child component
    if (val === "hide-list") {
      this.setState({ balancelist: "hide", btnEnabled: true });
    }
    if (val === "hide-balance") {
      this.setState({
        balanceshow: "hide",
        btnEnabled: false,
        currBalance: "",
      });
    }
  }

  render() {
    let panel = <div />;
    let btns = this.btns();
    let balance = this.showBalance();
    //panel = this.showBalance();
    if (this.state.balancelist !== "hide") {
      panel = (
        <BalanceList
          sendData={this.getData}
          token={this.props.token}
          balances={this.state.balances}
        />
      );
    }
    if (this.state.showReload) {
      panel = (
        <div align="center">
          <br />
          <br />
          <img
            id="load"
            src="/images/download.gif"
            height="100"
            width="100"
            alt="Loading"
          />
        </div>
      );
    }

    return (
      <div className="container">
        <div className="form-group">
          {btns} {balance} <br /> {panel}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  //whatever is returned will show as props inside this container
  return {
    user: state.activeUser,
    token: state.activeToken,
  };
}

//promote Login from a component to a container with added props activeToken
export default connect(mapStateToProps)(BalanceReport);
