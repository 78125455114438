const URI_LOCAL = "http://localhost";
const URI_AWS = "http://54.158.103.96";
const dev = {
  PORT: 3000,
  EXECUTER_URI: `${URI_LOCAL}:7000`,
  PARSER_URI: `${URI_LOCAL}:9000`,
  HOME_URL: `${URI_LOCAL}:3000`,
  PAYPAL_ENV: "sandbox" //sandbox
};
const prod = {
  PORT: 3000,
  EXECUTER_URI: "https://executer.aperium.io",
  PARSER_URI: "https://parser.aperium.io",
  EXECUTER_WS: "https://executerws.aperium.io",
  PAYPAL_ENV: "production",
  HOME_URL: "https://trader.aperium.io"
};
const PAYPAL_CLIENT = {
  sandbox: "AbfbDtFciZTPx7nQS4LX5NS-hRtoPZD-wJhMhmRQqx7YPlBQ79Wp1iQYk8XhH023Bnt7c8qkO5jFQ4P1",
  production: "Ae9Wm0f2oxCA06Gk9m7XV94fAz_FAs3VQgEp8hSoXGJ0cATgODGd7EtQHHU29gvGQqtllTSlLmkeDV27"
};
const CLIENT_KEY = "a8822fbc161db3422e6b718ed26af4fc6f5ae0d0befa99fca09a039d8453d434";

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === "prod" ? prod : dev;

const Market = ["USDT", "PAX", "USDC", "TUSD", "BTC"];
const TALK_CRIPTOMANIACOS = "5e6971c88d24fc2265871bc5";
const SOCKETIO_PORT = 3100;
const DEFAULT_INDIC_CONFIG = {
  configcalc: {
    sma: [7, 25, 99],
    ema: [10, 25, 50],
    rsi: [14],
    bbands: [20],
    macd: [12, 26, 9],
    cmf: [20],
    dmi: [14, 14],
    nvt: [90],
    klines: [1]
  }
};

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  CLIENT_KEY,
  PAYPAL_CLIENT,
  SOCKETIO_PORT,
  DEFAULT_INDIC_CONFIG,
  TALK_CRIPTOMANIACOS: TALK_CRIPTOMANIACOS,
  ...config
};
