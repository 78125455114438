import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import BotStrategyDetail from "./botStrategyDetail";
import sysconfig from "../../config";

function BotStrategyList(props) {
  const [showDetail, setShowDetail] = useState(false);
  const [strategies, setStrategies] = useState([]);
  const [strategyId, setStrategyId] = useState();
  const [strategyDetail, setStrategyDetail] = useState("hide");
  const user = useSelector(state => state.activeUser);
  const token = useSelector(state => state.activeToken);

  //equivalent to didMount
  useEffect(
    () => {
      getStrategies();
    },
    [strategies]
  );

  const getStrategies = () => {
    axios({
      method: "get",
      baseURL: sysconfig.EXECUTER_URI,
      url: "/strategy/list",
      params: { username: user.username }
    })
      .then(function(response) {
        if (response.data) {
          setStrategies(response.data);
        }
      })
      .catch(function(error) {
        console.log("Err getStrategies:", error);
      });
  };

  const selectStrategy = id => {
    setStrategyId(id);
    setStrategyDetail("show");
  };

  const showStrategyDetail = id => {
    let result = <div />;
    if (strategyDetail === "show" && strategyId === id) {
      result = (
        <div>
          <hr style={{ color: "gray", backgroundColor: "gray", height: 1 }} />
          <BotStrategyDetail sendData={getData} strategyId={strategyId} />
          <hr style={{ color: "gray", backgroundColor: "gray", height: 1 }} />
        </div>
      );
    }
    return result;
  };

  const showNewStrategy = () => {
    let result = <div />;
    if (strategyDetail === "show" && strategyId === 0) {
      result = (
        <div>
          <hr style={{ color: "gray", backgroundColor: "gray", height: 1 }} />
          <BotStrategyDetail sendData={getData} strategyId={strategyId} />
          <hr style={{ color: "gray", backgroundColor: "gray", height: 1 }} />
        </div>
      );
    }
    return result;
  };

  const getData = val => {
    //receive data from child component
    if (val === "hide") {
      setStrategyDetail("hide");
    }
  };

  const list = () => {
    let listItems = [];
    for (let strategy of strategies) {
      listItems.push(
        <div style={{ fontSize: "small" }}>
          <div className="row">
            <div className="col-md-1">{strategy.name}</div>
            <div className="col-md-3">{strategy.description}</div>
            <div className="col-md-1">{strategy.symbol}</div>
            <div className="col-md-1">{strategy.status}</div>
            <div className="col-md-1">
              <span
                className="btn-link cursor-pointer"
                onClick={() => selectStrategy(strategy._id)}
              >
                ->
              </span>
            </div>
          </div>
          {showStrategyDetail(strategy._id)}
        </div>
      );
    }
    return listItems;
  };

  const newBtn = () => {
    return (
      <button
        type="button"
        className="btn btn-outline-dark btn-sm cursor-pointer"
        id="new"
        onClick={() => selectStrategy(0)}
      >
        <img id="add" src="/images/plus.png" height="20" width="20" />
      </button>
    );
  };

  const title = () => {
    return (
      <div>
        <div className="row">
          <div className="col-md-3">
            <h5> My Strategies </h5>
          </div>
          <div className="col-md-1">{newBtn()}</div>
        </div>
        <div className="row">
          <div className="col-md-1">Name</div>
          <div className="col-md-3">Description</div>
          <div className="col-md-1">Symbol</div>
          <div className="col-md-1">Status</div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {showNewStrategy()}
      {title()} {list()}
    </div>
  );
}

export default BotStrategyList;
